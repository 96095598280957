import { Routes } from '@angular/router';
import { IndicadorDashboardComponent } from './components/indicador-dashboard/indicador-dashboard.component';
import { IndicadorPostoComponent } from './components/indicador-posto/indicador-posto.component';
import { HomeComponent } from './home/home.component';
import { IniciarComponent } from './components/login/iniciar/iniciar.component'
//import { CidadaoEsperaPostosComponent } from './components/postos/cidadao-espera-CidadaoEsperaPostosComponent/cidadao-espera-postos.component';
import { IndicadorOrgaoComponent } from './components/indicador-orgao/indicador-orgao.component';
import { IndicadorFilaComponent } from './components/indicador-fila/indicador-fila.component';
import { VagasDisponiveisRegiaoComponent } from './components/vagas-disponiveis-regiao/vagas-disponiveis-regiao.component';
import { VagasDisponiveisPostoComponent } from './components/vagas-disponiveis-posto/vagas-disponiveis-posto.component';
import { VagasDisponiveisServicoComponent } from './components/vagas-disponiveis-servico/vagas-disponiveis-servico.component';
import { PasCadastradosComponent } from './components/cadastros/pas-cadastrados/pas-cadastrados.component';
import { CadastroListagemPostoComponent } from './components/cadastros/posto/listagem/cadastro-listagem-posto.component';
import { CadastroListagemRegiaoComponent } from './components/cadastros/regiao/listagem/cadastro-listagem-regiao.component';
import { CadastroListagemServicoComponent } from './components/cadastros/servico/listagem/cadastro-listagem-servico.component';
import { CadastroListagemOrgaoComponent } from './components/cadastros/orgao/listagem/cadastro-listagem-orgao.component';
import { CadastroAdicionarOrgaoComponent } from './components/cadastros/orgao/adicionar/cadastro-adicionar-orgao.component';
import { CadastroAdicionarPostoComponent } from './components/cadastros/posto/adicionar/cadastro-adicionar-posto.component';
import { CadastroAdicionarRegiaoComponent } from './components/cadastros/regiao/adicionar/cadastro-adicionar-regiao.component';
import { CadastroAdicionarServicoComponent } from './components/cadastros/servico/adicionar/cadastro-adicionar-servico.component';
import { CadastroListagemIndicadorComponent } from './components/cadastros/indicador/listagem/cadastro-listagem-indicador.component';
import { CadastroAdicionarIndicadorComponent } from './components/cadastros/indicador/adicionar/cadastro-adicionar-indicador.component';
import { ControlePerfilUserComponent } from '../app/components/cadastros/cadastro-listagem-users/controle-perfil-user/controle-perfil-user.component';//controle-perfil-user/controle-perfil-user.component';
import { ControlePerfilUserModalComponent } from './components/cadastros/cadastro-listagem-users/controle-perfil-user-modal/controle-perfil-user-modal.component';
import { PasListagemComponent } from './components/cadastros/pas-cadastrados/listagem/pas-listagem/pas-listagem.component';
import { IndicadorEspecialComponent } from './components/MesaAtendimento/indicador-especial/indicador-especial.component';
import { VagasDisponiveisOrgaoComponent } from './components/vagas-disponiveis-orgao/vagas-disponiveis-orgao.component';
import { VagasDisponiveisRelatorioComponent } from './components/relatorios/vagas-disponiveis-relatorio/vagas-disponiveis-relatorio.component';
import { AgendamentoDiasEsperasComponent } from './components/relatorios/agendamento-dias-esperas/agendamento-dias-esperas.component';
import { canActivedGuard } from './auth/service/can-actived.guard';
import { LoadingComponent } from './loading/loading.component';
import { MudarPosicaoIndicadorComponent } from './components/cadastros/indicador/mudar-posicao/mudar-posicao-indicador.component';
import { ForcaLoginComponent } from './utilidades/forca-login/forca-login-component';
import { ListagemIncluisaoJustificativasComponent } from './components/cadastros/incluisao-justificativas/listagem/listagem-incluisao-justificativas.component';
import { CadastroIncluisaoJustificativasComponent } from './components/cadastros/incluisao-justificativas/adicionar/cadastro-incluisao-justificativas.component';
import { CidadaoEsperaCriticidadeRoxoComponent } from './components/relatorios/cidadao-espera-criti-roxo/cidadao-espera-criti-roxo.component';
import { CadastroAdicionarConfiguracaoComponent } from './components/cadastros/motor-configuracao/cadastro-adicionar-configuracao/cadastro-adicionar-configuracao.component';
import { CadastroListagemConfiguracaoComponent } from './components/cadastros/motor-configuracao/cadastro-listagem-configuracao/cadastro-listagem-configuracao.component';
import { MultipleSelectionSelectAllComponent } from './utilidades/multiple-selection-select-all/multiple-selection-select-all.component';

// import { HomeComponent } from './home/home.component';

export const routes: Routes = [

   { path: '', redirectTo: '/loading', pathMatch:'full' },
   //{ path: '', redirectTo: '/iniciar', pathMatch:'full' },
   { path: 'loading', component: LoadingComponent },

   { path: 'iniciar', component: IniciarComponent },
   { path: 'trocar-posicao-indicador', component: MudarPosicaoIndicadorComponent },
   { path: 'dashboard', component: IndicadorDashboardComponent },
   { path: 'forca-login', component: ForcaLoginComponent },
   {
     path: 'indicador-posto',
     component: IndicadorPostoComponent,
   },
   {
     path: 'indicador-orgao',
     component: IndicadorOrgaoComponent
   },
   {
    path: 'indicador-fila',
    component: IndicadorFilaComponent
  },
  {
    path: 'indicador-especial',
    component: IndicadorEspecialComponent
  },
  {
    path: 'indicador-detalhe',
    component: IndicadorEspecialComponent
  },
  {
    path: 'vagas-disponiveis-regiao',
    component: VagasDisponiveisRegiaoComponent
  },
  {
    path: 'MultipleSelectionSelectAllComponent',
    component: MultipleSelectionSelectAllComponent
  },
  {
    path: 'vagas-disponiveis-posto',
    component: VagasDisponiveisPostoComponent
  },
  {
    path: 'vagas-disponiveis-orgao',
    component: VagasDisponiveisOrgaoComponent
  },
  {
    path: 'vagas-disponiveis-servico',
    component: VagasDisponiveisServicoComponent
  },
  {
    path: 'pas-cadastrados',
    component: PasCadastradosComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia',
            'gestorregional'
        ]
    }
  },
  {
    path: 'pas-listagem',
    component: PasListagemComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia',
            'gestorregional'
        ]
    }
  },
  {
    path: 'posto-listagem',
    component: CadastroListagemPostoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: 'regiao-listagem',
    component: CadastroListagemRegiaoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: 'servico-listagem',
    component: CadastroListagemServicoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: 'orgao-listagem',
    component: CadastroListagemOrgaoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: 'indicador-listagem',
    component: CadastroListagemIndicadorComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser'
        ]
    }
  },
  {
    path: 'orgao-cadastro',
    component: CadastroAdicionarOrgaoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser'
        ]
    }
  },
  {
    path: 'posto-cadastro',
    component: CadastroAdicionarPostoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: 'regiao-cadastro',
    component: CadastroAdicionarRegiaoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: 'servico-cadastro',
    component: CadastroAdicionarServicoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser'
        ]
    }
  },
  {
    path: 'indicador-cadastro',
    component: CadastroAdicionarIndicadorComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser'
        ]
    }
  },
  {
    path: 'editar-motores',
    component: CadastroAdicionarConfiguracaoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser'
        ]
    }
  },
  {
    path: 'listar-motores',
    component: CadastroListagemConfiguracaoComponent,
    canActivate: [canActivedGuard],
    data: {
        roles:[
            'superuser'
        ]
    }
  },
  //  {
  //    path: 'cidadaos-atendidos',
  //    component: CidadaosAtendidosComponent
  //  },

  {
    path: "permissao-users",
    component: ControlePerfilUserComponent,
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: "editar-users",
    component: ControlePerfilUserModalComponent,
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: "relatorios/vagasDisponiveis",
    component: VagasDisponiveisRelatorioComponent,
  },
  {
    path: "relatorios/agendamentoDiasEspera",
    component: AgendamentoDiasEsperasComponent,
  },
  {
    path: "create-users",
    component: ControlePerfilUserModalComponent,
    data: {
        roles:[
            'superuser',
            'superintendencia'
        ]
    }
  },
  {
    path: "listagem-justificativa",
    component: ListagemIncluisaoJustificativasComponent,
  },
  {
    path: "cadastro-justificativa",
    component: CadastroIncluisaoJustificativasComponent,
  },
  {
    path: "relatorios/incidência",
    component: CidadaoEsperaCriticidadeRoxoComponent,
  },

  {path: '**', component: IniciarComponent }, // ESSE TEM QUE SER SEMPRE A ULTIMA ROTA DAQUI!!
];

import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { RegiaoService } from '../../../../service/regiao.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';


@Component({
  selector: 'app-cadastro-listagem-regiao',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-listagem-regiao.component.html',
  styleUrl: './cadastro-listagem-regiao.component.scss'
})

export class CadastroListagemRegiaoComponent implements OnInit {
  data: any[] = [];
  public lista: regiao[] = [];
  novoItem: regiao = new regiao();

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];
  OrdemOrdenacao: string = "crescente";
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  successMessage: string | null = null;
  errorMessage: string | null = null;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private regiaoService: RegiaoService,
    public dialog: MatDialog,
    private authService: AuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }

  ngOnInit() {
    this.Pesquisa();
  }

  Pesquisa() {
    this.lista = [];
    this.regiaoService.getRegPaginacao(this.pageIndex, this.pageSize, this.OrdemOrdenacao).pipe(
    ).subscribe((response) => {
      this.data = response["Regiao"];
      this.totalPages = response["totalPages"];
      let i = 0;
      //console.log(this.data);
      this.data.forEach(element => {
        i++;
        let p = new regiao(element['id'], element['codigo'], element['descricao']);
        this.lista.push(p)
      });
    });
  }
  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  MudaOrdencacao() {
    if (this.OrdemOrdenacao == "crescente")
      this.OrdemOrdenacao = "descrecente";
    else
      this.OrdemOrdenacao = "crescente";
    console.log(this.OrdemOrdenacao);
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  excluirItem(id: string | null): void {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmação', message: 'Tem certeza que deseja excluir?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id != undefined) {
          this.regiaoService.deleteItem(id?.toString()).subscribe(
            (response) => {
              //console.log('Item excluído com sucesso!');
              this.successMessage = 'Item excluído com sucesso!';
              this.errorMessage = null;

              this.authService.atualizarListaPostosERegiaoUsuario();
              this.Pesquisa();

              // Remove a mensagem após alguns segundos, se desejado
              setTimeout(() => {
                this.successMessage = null;
              }, 3000);
            },
            (error) => {
              //console.error('Erro ao excluir o item:', error);
               // Exibe a mensagem de erro retornada pelo backend e esconde a mensagem de sucesso, caso exista
               this.errorMessage = error.error?.resultado || 'Erro ao excluir o item.';
               this.successMessage = null;
 
               // Remove a mensagem de erro após alguns segundos, se desejado
               setTimeout(() => {
                 this.errorMessage = null;
               }, 5000);
            }
          );
        }
      }
    });

  }

  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.regiaoService.editarItem(id?.toString()).subscribe(
        (response) => {
          //console.log(response);
          let p = response["Regiao"];

          this.novoItem.id = p['id'];
          this.novoItem.codigo = p['codigo'];
          this.novoItem.descricao = p['descricao'];
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }

  validarRole(){
    let role = this.authService.role();
    if(role == 'superuser'){
        return true;
    }
    return false;
  }
}

export class AtualizacaoIndicador {
    public codigoIndicadores: string;
    public tempoAtualizacao: number;



    constructor(
        codigoIndicadores: string,
        tempoAtualizacao: number) {
        this.codigoIndicadores = codigoIndicadores;
        this.tempoAtualizacao = tempoAtualizacao;
    }
}
<div style="display: block; justify-content: center; align-items: center;text-align: center;position: relative;top: 40%;transform: translateY(-50%);">
<h5>Aguarde...</h5>
<div class="spinner">
	<div class="right-side">
		<div class="bar"></div>
	</div>
	<div class="left-side">
		<div class="bar"></div>
	</div>
</div>
</div>
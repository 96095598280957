import { Component, ElementRef, Inject, Input, OnChanges, Output, Renderer2, SimpleChanges,EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { CommonModule, DOCUMENT } from '@angular/common';
import { indicadorDTO } from '../../modal/dto/indicadorDTO';

@Component({
  selector: 'app-alerta-vagas',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    CommonModule,
    // * MATERIAL IMPORTS
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
  ],
  templateUrl: './alerta-vagas.component.html',
  styleUrl: './alerta-vagas.component.scss',
})


export class AlertaVagasComponent implements OnChanges {
  constructor(private el: ElementRef, private renderer: Renderer2,private router: Router
  ) { }
  chatVisible = false;
  @Input() AbrirAlertas: boolean = false;
  @Input() DadosAlerta: indicadorDTO[] = [];

  toggleChat(): void {
    this.chatVisible = !this.chatVisible;

    const chat = this.el.nativeElement.querySelector('.chat');

    if (chat.style.display === 'none') {
      this.renderer.setStyle(chat, 'display', 'block');
    } else {
      this.renderer.setStyle(chat, 'display', 'none');
    }
  }

  closeChat(): void {
    const livechat = this.el.nativeElement.querySelector('.live-chat');
    this.renderer.setStyle(livechat, 'display', 'none');
    this.setabrirAlerta();
  }
  abrirAlerta() {
    const livechat = this.el.nativeElement.querySelector('.live-chat');
      this.renderer.setStyle(livechat, 'display', 'block');
  }
  setdados(listaind: indicadorDTO[]) {
    this.DadosAlerta=listaind;
  }

  setabrirAlerta() {
    const tempoAbrirAlerta = setInterval(() => {
      clearInterval(tempoAbrirAlerta);
      this.abrirAlerta();
       this.chatVisible = true;
      const chat = this.el.nativeElement.querySelector('.chat');
      this.renderer.setStyle(chat, 'display', 'block');
     
    }, (3600*1000));
  }
  ngOnChanges(changes: SimpleChanges) {
  }
  VerDetalhe(postoCc: number) {
    this.router.navigate(['/relatorios/agendamentoDiasEspera'], {
        queryParams: {postoCc: postoCc }
    });
}
}
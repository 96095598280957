import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    Router,
    RouterLink,
    RouterLinkActive,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { HeaderComponent } from '../header/header.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-loading',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        RouterLink,
        RouterOutlet,
        RouterModule,
        RouterLinkActive,
        MatSidenavModule,
    ],
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss',
})
export class LoadingComponent  implements OnInit {

    public TentativasLogin = 0;
    constructor(private router: Router, private oauthService: OAuthService){}

    ngOnInit() {
        let token = this.oauthService.getAccessToken();
        
        let login = localStorage.getItem('login');
        let deslogar = localStorage.getItem('deslogar');
        console.log("deslogar", deslogar);
        if(deslogar == "true"){
            localStorage.setItem('deslogar', "false");
            this.router.navigate(['iniciar']);
        }
        console.log("LOADING",login)
        if (login != null && login != undefined) {
            this.router.navigate(['dashboard']);
        } else {
            this.setCheckLogin();
        }
    }

    setCheckLogin()
    {
        const intervalId = setInterval(() => {
            this.TentativasLogin++;
            let login = localStorage.getItem('login');
            console.log("LOADING",login)
            if (login != null && login != undefined) {
                clearInterval(intervalId)
                this.router.navigate(['dashboard']);
                
            }
            if(this.TentativasLogin>60)
            {
                clearInterval(intervalId)
                this.router.navigate(['/iniciar']);
            }
        }, (500));
    }
}

import { Inject, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../service/auth.service';

export const canActivedGuard: CanActivateFn = (route, state) => {
    const auth = inject(AuthService);
    var perfil = auth.role()
    for(var roles of route['data']['roles']){
        if(roles == perfil){
            return true;
        }
    }
    return false;
};

import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { ServicoService } from '../../../../service/servico.service';
import { servico } from '../../../../modal/regional/servico';
import { orgao } from '../../../../modal/regional/orgao';
import { OrgaoService } from '../../../../service/orgao.service';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';

@Component({
  selector: 'app-cadastro-adicionar-servico',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-adicionar-servico.component.html',
  styleUrl: './cadastro-adicionar-servico.component.scss'
})

export class CadastroAdicionarServicoComponent implements OnInit {
  data = [];
  public listaOrg: orgao[] = [];
  novoItem: servico = new servico();

  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private servicoService: ServicoService, 
    private orgaoService: OrgaoService,
    public dialog: MatDialog) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  submitForm() {

    if (this.novoItem.codigo == undefined || this.novoItem.descricao == "") {
      alert("Digite todos os campos obrigatorios!")
      return;
    }

   console.log('Novo item', this.novoItem);
    this.servicoService.adicionarItem(this.novoItem).subscribe(() => {
      //console.log('Novo item adicionado com sucesso', this.novoItem);
      this.novoItem = new servico();
      this.mySelect.nativeElement.value = '';
      this.router.navigate(['servico-listagem']);
    }, error => {
      console.error('Erro ao adicionar novo item:', error);
    });
  }

  Limpar()
  {
    this.novoItem.codigo = undefined;
    this.novoItem.id = null;
    this.novoItem.codigoCentralizado = "";
    this.novoItem.descricao = "";
    this.mySelect.nativeElement.value = '';
  }

  onOptionChange(newValue: string) {
    this.novoItem.orgao = this.listaOrg.find(x => x.id == newValue);
    //console.log('Novo item', this.novoItem, newValue, this.listaOrg);
  }


  ngOnInit() {
    this.orgaoService.getOrgao().pipe(
    ).subscribe((response) => {
      this.data = response["Orgao"];
      let i = 0;
      //console.log(response);
      this.data.forEach(element => {
        i++;
        this.listaOrg.push(new orgao(element['id'], element['codigo'], element['descricao'],element['codigoCentralizado']))
      });
      this.listaOrg = this.listaOrg.sort((a, b) => {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;
      });

      this.novoItem.orgao = this.listaOrg[0];

    this.route.queryParams.subscribe(params => {
      let idEditar = params['IdEditar'];
      if(idEditar!=undefined)
        this.AbrirEditarItem(idEditar);
    });
    });

    
  }

  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.servicoService.editarItem(id?.toString()).subscribe(
        (response) => {
          //console.log(response);
          let p = response["Servico"];

          this.novoItem.id = p['id'];
          this.novoItem.codigo = p['codigo'];
          this.novoItem.codigoCentralizado = p['codigoCentralizado'];
          this.novoItem.descricao = p['descricao'];
          this.novoItem.descricaoVagas = p['descricaoVagas'];
          if (p['orgao'] != undefined) {
            this.novoItem.orgao = new orgao(p['orgao']['id'], p['orgao']['codigo'], p['orgao']['descricao'], p['orgao']['codigoCentralizado'],)
            this.mySelect.nativeElement.value = '' + p['orgao']['id'];
          }
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }
}
import { cormodal } from "./cormodal";

    
    export const CORES: cormodal[] = [
      { status:"Vermelho",fundoCor: "#ff6c60", cabecalhoCor: "#ff3322",ordenacao: 1, numBorda:2},
      { status:"Amarelo",fundoCor: "#f8d347", cabecalhoCor: "#e8bb09",ordenacao: 3, numBorda:2},
      { status:"Roxo",fundoCor: "#944192", cabecalhoCor: "#7d0e7d",ordenacao: 0, numBorda:2},
      { status:"Verde",fundoCor: "#6ccac9", cabecalhoCor: "#43bcbc",ordenacao: 7, numBorda:2},
      { status:"Azul",fundoCor: "#7fccff", cabecalhoCor: "#54bbff",ordenacao: 9, numBorda:2},
      { status:"Laranja",fundoCor: "#f37606", cabecalhoCor: "#c35c00",ordenacao: 10, numBorda:2},
      { status:"Cinza",fundoCor: "#9e9e9e", cabecalhoCor: "#818181",ordenacao: 12, numBorda:2},
      
      
      { status:"SelecVermelho",fundoCor: "#ff6c60", cabecalhoCor: "#ff3322",ordenacao: 1, numBorda:6},
      { status:"SelecAmarelo",fundoCor: "#f8d347", cabecalhoCor: "#e8bb09",ordenacao: 3, numBorda:6},
      { status:"SelecRoxo",fundoCor: "#944192", cabecalhoCor: "#7d0e7d",ordenacao: 0, numBorda:6},
      { status:"SelecVerde",fundoCor: "#6ccac9", cabecalhoCor: "#43bcbc",ordenacao: 7, numBorda:6},
      { status:"SelecAzul",fundoCor: "#7fccff", cabecalhoCor: "#54bbff",ordenacao: 9, numBorda:6},  
      { status:"SelecLaranja",fundoCor: "#f37606", cabecalhoCor: "#c35c00",ordenacao: 10, numBorda:6},  
      { status:"SelecCinza",fundoCor: "#9e9e9e", cabecalhoCor: "#818181",ordenacao: 12, numBorda:6},      
    ];
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { navbarData } from './nav-data';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { WINDOW } from '@ng-web-apis/common';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../service/auth.service';
import { VersionamentoComponent } from '../utilidades/pop-up-versionamento/pop-up-versionamento.component';
import { MatDialog } from '@angular/material/dialog';
import { PostoService } from '../service/posto.service';
import { regiaoPostoFiltroDTO } from '../modal/dto/regiaoPostoFiltroDTO';
import { regiao } from '../modal/regional/regiao';
import { posto } from '../modal/regional/posto';
import { SharedDataService } from '../service/SharedDataService ';
import { RouteChangeService } from '../utilidades/route-change.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MotorService } from '../service/motor.service';

interface SideNavToggle {
  collapsed: boolean;
  screenWidth: number;
}

@Component({
  selector: 'app-sidenav',
  standalone: true,
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    // * MATERIAL IMPORTS
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
  ],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('350ms',
          style({ opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('350ms',
          style({ opacity: 0 })
        )
      ])
    ]),
    trigger('rotate', [
      transition(':enter', [
        animate('1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' })
          ])
        )
      ])
    ])
  ]
})

export class SidenavComponent implements OnInit {
  public listaRegiao: regiaoPostoFiltroDTO[] = [];
  public listaRegiaoTodos: regiaoPostoFiltroDTO[] = [];
  public urlAtual: string = "";
  constructor(
    @Inject(WINDOW) private window: Window, private motorService: MotorService,
    private authService: AuthService, private postoService: PostoService, public dialog: MatDialog,
    private sharedDataService: SharedDataService, private routeChangeService: RouteChangeService,
    private router: Router) { }

  roles: string = '';
  filterNavData: any = [];

  ngOnInit(): void {

   
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        const parsedUrl = this.router.parseUrl(event.urlAfterRedirects);  // Usa o URL final após redirecionamentos, se houver
        var url = parsedUrl.root.children['primary']?.segments.map(it => it.path).join('/') || '';
        // Se precisar adicionar uma barra inicial:
        this.urlAtual = '/' + url;
        
      });
    if (this.window) {
      this.screenWidth = this.window.innerWidth;
    }
    this.filterRoles();
  }

  @Input() isDarkTheme: boolean = false;
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed = false;
  pesquisaPosto = "";
  screenWidth = 0;
  navDataFilhos = this.filterNavData.filter((item: any) => item.tipo == "Filho");
  navData = this.filterNavData.filter((item: any) => item.tipo != "Filho");
  
  filtrarFilhos(idPai: number) {
    return this.navDataFilhos.filter((item: any) => item.id_pai === idPai);
  }
  toggleItem(id: number): void {
    let item = this.navData.filter((x: any) => x.id === id)[0];
    
    let itemFechar = this.navData.filter((x: any) => x.id != id);
    if (item.open === false) {
      this.navData.filter((x: any) => x.id === id)[0].open = true;
      itemFechar.forEach((element: { open: boolean; }) => {
        element.open = false;
      });
    }
    else if (item.open === true)
      this.navData.filter((x: any) => x.id === id)[0].open = false;
  }

  mostrarMais(id: string | null) {
    let item = this.listaRegiao.filter((x: any) => x.regiao.id === id)[0];
   
    item.mostrarTudo = true;
  }

  mostrarMenos(id: string | null) {
    let item = this.listaRegiao.filter((x: any) => x.regiao.id === id)[0];
    item.mostrarTudo = false;
  }

  toggleItemRegiao(id: string | null): void {

    let item = this.listaRegiao.filter((x: any) => x.regiao.id === id)[0];
    
    let itemFechar = this.listaRegiao.filter((x: any) => x.regiao.id != id);
    if (item.open === false || item.open == null) {
      itemFechar.forEach(element => {
        element.open = false;
        element.mostrarTudo = false;
      });
      item.open = true;
    }
    else if (item.open === true)
      item.open = false;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = this.window.innerWidth;
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
    }
  }

  toggleCollapsed() {
    let item = this.navData.filter((x: any) => x.EhFilhoEspecial);
    item.forEach((element: { open: boolean; }) => {
      element.open = false;
    });
    this.listaRegiao.forEach(element => {
      element.open = false;
      element.mostrarTudo = false;
    });

    
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }

  closeSidenav() {
    let item = this.navData.filter((x: any) => x.EhFilhoEspecial);
    item.forEach((element: { open: boolean; }) => {
      element.open = false;
    });
    this.listaRegiao.forEach(element => {
      element.open = false;
      element.mostrarTudo = false;
    });
    this.collapsed = false
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }
  closeSidenavEspecial() {
    if (!this.collapsed) {
      this.listaRegiao.forEach(element => {
        element.open = false;
        element.mostrarTudo = false;
      });
      this.collapsed = true
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
    }
  }


  filterRoles() {
    const intervalId = setInterval(() => {
      let login = localStorage.getItem('login');
      if (login != null && login != undefined) {

        this.roles = this.authService.role();
        
        navbarData.forEach((element: any) => {
          if (element.roles) {
            element.roles.forEach((filterRole: any) => {
              if (filterRole == this.roles) {
                this.filterNavData.push(element)
              }
            });
          } else {
            this.filterNavData.push(element)
          }
        });
        this.navDataFilhos = this.filterNavData.filter((item: any) => item.tipo == "Filho");        
        this.navData = this.filterNavData.filter((item: any) => item.tipo != "Filho");
       
        var FiltroRegiaoPosto = localStorage.getItem('FiltroRegiaoPosto');
       
        if (FiltroRegiaoPosto != null) {
          this.listaRegiao = JSON.parse(FiltroRegiaoPosto);
          this.listaRegiaoTodos = this.listaRegiao;
        }
        else {
          this.pegarFiltroPostoRegiao();
        }
        clearInterval(intervalId)
      }
    }, (500));
  }


  enviarFiltroPosto(ccPosto: string, descPosto: string) {
    this.sharedDataService.updateDescPostoSource(descPosto);
    this.sharedDataService.updateCcPosto(ccPosto);
  }

  onKeyUp(event: any) {
    if (event == null) {
      this.listaRegiao = this.listaRegiaoTodos;
    }
    else {
      const valorDigitado = event.target.value;
      this.pesquisaPosto = valorDigitado;
      if (valorDigitado == "") {
        this.listaRegiao = this.listaRegiaoTodos;
      }
      else {
        this.listaRegiao = []
        this.listaRegiaoTodos.forEach(elementRegiao => {
          var listaposto: posto[] = []
          if (elementRegiao.regiao != undefined) {
            elementRegiao.postos.forEach(elementPosto => {
              if (elementPosto.descricao.toLowerCase().trim().includes(valorDigitado.toLowerCase().trim())) {
                listaposto.push(elementPosto);
              }
            });
            if (listaposto.length != 0) {
              var reg = this.listaRegiao.find(x => x.regiao?.id == elementRegiao.regiao?.id);
              if (reg == null) {
                var RegPosFiltro: regiaoPostoFiltroDTO = new regiaoPostoFiltroDTO();
                RegPosFiltro.regiao = elementRegiao.regiao;
                RegPosFiltro.postos = listaposto;
                RegPosFiltro.open = true;
                this.listaRegiao.push(RegPosFiltro);
              }
              else {
                reg.postos = listaposto;
              }
            }
          }
        });
      }
    }
  }

  pegarFiltroPostoRegiao() {
    var usuario = localStorage.getItem('salaSituacao');
    this.postoService.getPostoUsuario(usuario).pipe().subscribe((json: any) => {
      json.Posto.forEach((postos: any) => {
        if (postos.regiao) {
          let p = new posto(postos.id, postos.codigo, postos.descricao.toUpperCase(), postos.codigoCentralizado);

          var reg = this.listaRegiao.find(x => x.regiao?.id == postos.regiao.id);
          
          if (reg == null) {
            var newReg = new regiao(postos.regiao.id, postos.regiao.codigo, postos.regiao.descricao);
            var RegPosFiltro: regiaoPostoFiltroDTO = new regiaoPostoFiltroDTO();
            RegPosFiltro.regiao = newReg;
            RegPosFiltro.postos = [];
            RegPosFiltro.postos.push(p);
            this.listaRegiao.push(RegPosFiltro);
          }
          else {
            reg.postos.push(p);
          }
        }
      });
      this.listaRegiao.sort((a, b) => {
        const descricaoA = a.regiao?.descricao?.toLowerCase() || ''; // Verifica se a regiao e a descricao existem
        const descricaoB = b.regiao?.descricao?.toLowerCase() || '';
        if (descricaoA < descricaoB) {
          return -1;
        } else if (descricaoA > descricaoB) {
          return 1;
        } else {
          return 0;
        }
      });
      this.listaRegiao.forEach(element => {
        element.postos.sort(function (a: any, b: any) {
          if (a.descricao > b.descricao) {
            return 1;
          }
          if (a.descricao < b.descricao) {
            return -1;
          }
          return 0;
        })
      });
      this.listaRegiaoTodos = this.listaRegiao;      
      localStorage.setItem('FiltroRegiaoPosto', JSON.stringify(this.listaRegiao));
    });
  }

  AbrirVersionamento(): void {
    var VERSAO_FRONT = "1.2.0 - 26/11/2024"
    this.authService.getVersionamento().subscribe(
      (response_back) => {
        this.motorService.getVersao().subscribe(
          
          (response_motor) => {
            console.log("MOTOR",response_motor)
            const dialogRef = this.dialog.open(VersionamentoComponent, {
              data: { title: 'Versionamento', versaoback: response_back['versao'], versaofront: VERSAO_FRONT,versaomotor:response_motor['versao']} //VERSAO AQUI
            });
          },
          (error_motor) => {
            console.log("Erro da api Motor",error_motor);
            const dialogRef = this.dialog.open(VersionamentoComponent, {
              data: { title: 'Versionamento', versaoback: response_back['versao'], versaofront: VERSAO_FRONT, versaomotor: "ERRO API: O motor está offline" } //VERSAO AQUI
            });
          });
      },
      (error_back) => {
        console.log("Erro da api Back End",error_back);
        this.motorService.getVersao().subscribe(
          (response_motor) => {
            const dialogRef = this.dialog.open(VersionamentoComponent, {
              data: { title: 'Versionamento', versaoback: "ERRO API: O back end está offline" , versaofront: VERSAO_FRONT, versaomotor: response_motor['versao']} //VERSAO AQUI
            });
          },
          (error_motor) => {
            console.log("Erro da api Motor",error_motor);
            const dialogRef = this.dialog.open(VersionamentoComponent, {
              data: { title: 'Versionamento', versaoback: "ERRO API: O back end está offline" , versaofront: VERSAO_FRONT, versaomotor: "ERRO API: O motor está offline" } //VERSAO AQUI
            });
          });

      });
  }
}

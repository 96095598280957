import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class IndicadorRegionalService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.regional";

  getRegional(codigo: number, sala: any, login: string,postos: number[] | undefined): Observable<any> {
    const url = this.apiUrl+this.map+'/getRegional?login='+login+'&id='+codigo;
    //const body = { usuario: sala, postos: postos };
    return this.http.post(url, sala);
  }
}

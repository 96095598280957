import { RelatorioService } from './../../../service/relatorio.service';
import { Component, Inject, Input, OnInit, inject, input, signal } from '@angular/core';
import { PostoService } from '../../../service/posto.service';
import { CommonModule } from '@angular/common';
import { CORES } from '../../../modal/mock-cor';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { RegiaoService } from '../../../service/regiao.service';
import { posto } from '../../../modal/regional/posto';
import { AuthService } from '../../../service/auth.service';
import { MultipleSelectionSelectAllComponent } from '../../../utilidades/multiple-selection-select-all/multiple-selection-select-all.component';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
    selector: 'app-agendamento-dias-esperas',
    standalone: true,
    imports: [MatSidenavModule,
        MatCardModule,
        FormsModule,
        MatMenuModule,
        CommonModule,
        RouterLink,
        RouterOutlet,
        RouterModule, MultipleSelectionSelectAllComponent],
    templateUrl: './agendamento-dias-esperas.component.html',
    styleUrls: ['./agendamento-dias-esperas.component.scss'],
})
export class AgendamentoDiasEsperasComponent implements OnInit {

    public quantidadePostos: any;
    public listaPostos: any = [];
    public listaRegiao: any = [];
    public tabela: any;
    public achouPosto = false;
    public achouServico = false;
    public parametro: any;
    public newFilter: any = [];
    public quantidadePostosNewFilter: any;
    public filterKeywordPosto: any = [];
    public filterKeywordRegiao: any = [];
    public columnLabels: any = [];
    cores = CORES;
    valorPesquisado: string | undefined = undefined;
    usuario: any;
    perfil: any;
    larguraTela: number = window.innerWidth;
    RegiaoSelecionada: any;
    PostosSelecionados: any[] = [];

    constructor(private route: ActivatedRoute,
        private router: Router,
        private postoService: PostoService,
        private relatorioService: RelatorioService,
        private regiaoService: RegiaoService,
        private authService: AuthService) {
        try {
            let login = localStorage.getItem('login');
            if (login == null || login == undefined)
                this.router.navigate(['/iniciar']);
        }
        catch (ex) {
            this.router.navigate(['/iniciar']);
        }
    }

    ngOnInit(): void {
        this.perfil = this.authService.role();
        this.relatorioService.findById(2).pipe().subscribe((resultado: any) => {
            var json = JSON.parse(resultado.parametro)
            this.parametro = json;
            console.log(this.parametro);
        });
        this.usuario = localStorage.getItem('salaSituacao');
        this.postoService.getPostoUsuario(this.usuario).pipe().subscribe((json: any) => {
            json.Posto.forEach((postos: any) => {
                if (postos.regiao) {
                    var regiao: any = { id: postos.regiao.id }
                    var posto: any = { descricao: 'POUPATEMPO ' + postos.descricao.toUpperCase(), codigoCentralizado: postos.codigoCentralizado, regiao: regiao, idRegiao: postos.regiao.id, regiaocodigo: postos.regiao.codigo }
                    this.listaPostos.push(posto);
                }
            });
            this.listaPostos.sort(function (a: any, b: any) {
                if (a.descricao > b.descricao) {
                    return 1;
                }
                if (a.descricao < b.descricao) {
                    return -1;
                }
                return 0;
            })
        })
        this.regiaoService.getRegiaoUsuario(this.usuario).pipe().subscribe((element: any) => {
            element.Regiao.forEach((regiao: any) => {
                this.listaRegiao.push(regiao);
            });
            this.listaRegiao.sort(function (a: any, b: any) {
                if (a.codigo > b.codigo) {
                    return 1;
                }
                if (a.codigo < b.codigo) {
                    return -1;
                }
                return 0;
            })
        })
        this.poupularTabela();
    }

    public poupularTabela() {
        var postos: any = [];
        this.postoService.getPostoUsuario(this.usuario).pipe().subscribe((jsonPosto: any) => {

            if (this.PostosSelecionados.length != 0) {
                const postosFiltradosArray  = (jsonPosto as any).Posto?.filter((posto: any) =>
                    this.PostosSelecionados.includes(posto.codigoCentralizado)
                ) || [];
                jsonPosto = { Posto: postosFiltradosArray };
            }
            this.relatorioService.findRelatorio(2, jsonPosto).pipe().subscribe((relatorio: any) => {
                console.log("RELATORIO", relatorio);
                this.columnLabels = relatorio.columnLabel;
                relatorio.agendamentoEspera.forEach((posto: any) => {
                    var servicos: any = [];
                    relatorio.columnLabel.forEach((column: any) => {
                        relatorio.postoLabel.forEach((postoLabel: any) => {
                            if (posto.id == postoLabel.id) {
                                var achouServico = false;
                                for (var servico of posto.servicos) {
                                    if (servico.id == column.id) {
                                        if (servico.diasEspera || servico.diasEspera === 0) {
                                            servicos.push({ id: column.id, desc: column.label, diasEspera: servico.diasEspera, NumTotalVagas: servico.NumTotalVagas });
                                        } else {
                                            servicos.push({ id: column.id, desc: column.label, diasEspera: servico.diasEspera, NumTotalVagas: servico.NumTotalVagas });
                                        }
                                        achouServico = true;
                                        break;
                                    }
                                }
                                if (achouServico == false) {
                                    servicos.push({ id: column.id, desc: column.label, diasEspera: -1, NumTotalVagas: servico.NumTotalVagas });
                                }
                            }
                        });
                    });
                    //console.log(posto);
                    if (posto.desc != undefined) {
                        var nomePosto = "POUPATEMPO " + posto.desc.toUpperCase();
                        postos.push({ desc: nomePosto, id: posto.id, servicos: servicos })
                        postos.sort(function (a: any, b: any) {
                            if (a.desc > b.desc) {
                                return 1;
                            }
                            if (a.desc < b.desc) {
                                return -1;
                            }
                            return 0;
                        })
                    }
                });
                var resultado = { posto: postos, agendamentoEspera: relatorio.agendamentoEspera, columnLabel: relatorio.columnLabel, postoLabel: relatorio.postoLabel }
                this.tabela = resultado
                console.log("TABELA", this.tabela);
                this.quantidadePostos = relatorio.postoLabel.length;

                this.route.queryParamMap.subscribe(p => {
                    let postoCc = p.get('postoCc');
                    if (postoCc != null) {
                        var posto: any = { descricao: '', codigoCentralizado: postoCc }
                    }
                });
            })
        })

    }

    public validarPosto(situacao: boolean) {
        return this.achouPosto == situacao;
    }

    public validarServico(situacao: boolean) {
        return this.achouServico == situacao;
    }

    public paint(valor: any, valorTotalVagas: any) {
        if (valor === null) {
            return 'gray';
        } else if (!isNaN(valor)) {
            if (valor === 0) {
                return this.cores.find(x => x.status == "Verde")?.fundoCor;
            } else if (valor >= this.parametro.faixa_b && valor < this.parametro.faixa_c || valor < this.parametro.faixa_b) {
                return this.cores.find(x => x.status == "Verde")?.fundoCor;
            } else if (valor >= this.parametro.faixa_c) {
                return this.cores.find(x => x.status == "Vermelho")?.fundoCor;
            }
        }
        return this.cores.find(x => x.status == "Amarelo")?.fundoCor;
    }

    public paintBorda(valor: any) {
        if (valor === null) {
            return 'gray';
        } else if (!isNaN(valor)) {
            if (valor === 0) {
                return '1px solid ' + this.cores.find(x => x.status == "Verde")?.cabecalhoCor;
            } else if (valor >= this.parametro.faixa_b && valor < this.parametro.faixa_c || valor < this.parametro.faixa_b) {
                return '1px solid ' + this.cores.find(x => x.status == "Verde")?.cabecalhoCor;
            } else if (valor >= this.parametro.faixa_c) {
                return '1px solid ' + this.cores.find(x => x.status == "Vermelho")?.cabecalhoCor;
            }
        }
        return '1px solid ' + this.cores.find(x => x.status == "Amarelo")?.cabecalhoCor;
    }

    filtrarPesquisa() {
        this.poupularTabela();
    }

    onSelecionar(valor: string) {
        this.RegiaoSelecionada = valor; // Atualiza o valor quando o select muda
    }

    processarInfo(info: any) {
        this.PostosSelecionados = info;
    }
}

<section>
  <section style="height: auto;">
    <nav>
      <ol class="cd-breadcrumb custom-separator">
        <table>
          <tbody>
            <tr>
              <td class="nomeTela">
                Cadastro de Indicadores
              </td>
            </tr>
          </tbody>
        </table>
      </ol>
    </nav>
  </section>
  <main>
    <section class="main-section">
      <form class="form-class" data-toggle="collapse">
        <input type="text" class="form-control" id="id" name="id" [(ngModel)]="novoItem.id" required
          style="display: none;">
        <div class="form-div p-3">
          <label for="etapa" class="form-label">Etapa <span class="required">*</span></label>
          <fieldset class="fieldInput">
            <input type="number" class="form-control form-input" [readonly]="TravaEtapa"
              [ngClass]="{'readonly': TravaEtapa, 'editable': !TravaEtapa}" (input)="handleInputChange($event)"
              id="etapa" name="etapa" [(ngModel)]="novoItem.etapa" required>
            <button type="submit" (click)="alterarTravaEtapa(!TravaEtapa)" class="form-submit"><i *ngIf="TravaEtapa"
                class="fas fa-lock"></i><i *ngIf="!TravaEtapa" class="fas fa-lock-open"></i></button>
          </fieldset>
        </div>
        <div class="form-div p-3">
          <label for="proximaEtapa" class="form-label">Proxima Etapa</label>
          <input type="number" class="form-control" id="proximaEtapa" name="proximaEtapa"
            [(ngModel)]="novoItem.proximaEtapa" required>
        </div>
        <div class="form-div form-check form-switch">
          <input class="form-check-input" type="checkbox" [(ngModel)]="novoItem.ehGraficoIndicador"
            name="ehGraficoIndicador" id="ehGraficoIndicador">
          <label class="form-check-label" for="flexCheckDefault">
            é um tipo de indicador especial?
          </label>
        </div>
        <div class="form-div p-3" *ngIf="novoItem.ehGraficoIndicador">
          <label for="tipoGraficoIndicador" class="form-label">Tipo do Gráfico<span class="required">*</span></label>
          <select class="form-select" [(ngModel)]="novoItem.tipoGraficoIndicador" name="tipoGraficoIndicador">
            <option *ngFor="let tipoGI of optionsTipoGraficoIndicador" [value]="tipoGI.value">{{ tipoGI.label }}
            </option>
          </select>
        </div>
        <div class="form-div p-3" [style.display]="EhEtapa1 ? 'none' : 'block'">
          <label for="indicador" class="form-label">Indicador</label>
          <select #mySelectIndicador name="select" class="form-select"
            (change)="onOptionChange(mySelectIndicador.value)">
            <option value="">Escolha o Indicador</option>
            <option *ngFor="let objeto of listaIndEtapa1" value={{objeto.codigo}}>{{objeto.nome}}</option>
          </select>
        </div>

        @if(EhEtapa1)
        {
        <div class="form-div p-3">
          <label for="codigo" class="form-label">Codigo <span class="required">*</span></label>
          <input type="number" class="form-control" id="codigo" name="codigo" [(ngModel)]="novoItem.codigo" required>
        </div>
        <div class="form-div p-3">
          <label for="nome" class="form-label">Nome <span class="required">*</span></label>
          <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="novoItem.nome" required>
        </div>
        <div class="form-div p-3">
          <label for="descricao" class="form-label">Descricao <span class="required">*</span></label>
          <input type="text" class="form-control" id="descricao" name="descricao" [(ngModel)]="novoItem.descricao"
            required>
        </div>
        <div class="form-div p-3">
          <label for="tempoAtualizacao" class="form-label">Tempo Atualizacao</label>
          <input type="number" class="form-control" id="tempoAtualizacao" name="tempoAtualizacao"
            [(ngModel)]="novoItem.tempoAtualizacao" required>
        </div>
        <div class="form-div p-3">
          <label for="icone" class="form-label">Icone</label>
          <input type="text" class="form-control" id="icone" name="icone" [(ngModel)]="novoItem.icone" required>
        </div>
        <div class="form-div p-3">
          <label for="abreviatura" class="form-label">Abreviatura</label>
          <input type="text" class="form-control" id="abreviatura" name="abreviatura" [(ngModel)]="novoItem.abreviatura"
            required>
        </div>
        <div class="form-div p-3">
          <label for="ordemDashboard" class="form-label">Ordem no Dashboard</label>
          <input type="number" class="form-control" id="ordemDashboard" name="ordemDashboard"
            [(ngModel)]="novoItem.ordemDashboard" required readonly>
        </div>

        <div class="form-div p-3">
          <label for="editorHtml" class="form-label">Editar Texto Ajuda</label>
          <quill-editor [modules]="editorConfig" class="form-control" id="textoAjudaHTML" name="textoAjuda"
            [(ngModel)]="novoItem.textoAjudaHTML"></quill-editor>
        </div>
        }
        @else if(novoItem.tipoGraficoIndicador == 4)
        {
          <div class="form-div p-3">
            <label for="tempoAtualizacao" class="form-label">Tempo Atualizacao</label>
            <input type="number" class="form-control" id="tempoAtualizacao" name="tempoAtualizacao"
              [(ngModel)]="novoItem.tempoAtualizacao" required>
          </div>
        }



        <div class="form-div p-3">
          <label for="nomeTipoTela" class="form-label">Nome do Tipo da Tela</label>
          <input type="text" class="form-control" id="nomeTipoTela" name="nomeTipoTela"
            [(ngModel)]="novoItem.nomeTipoTela" required>
        </div>
        <div class="form-div p-3">
          <label for="linkRedirect" class="form-label">Link para Redirect Padrao</label>
          <input type="text" class="form-control" id="linkRedirect" name="linkRedirect"
            [(ngModel)]="novoItem.linkRedirect" required>
        </div>
        <div class="form-div p-3">
          <label for="visualizacaoDisplay" class="form-label">Visualização Display</label>
          <input type="text" class="form-control" id="visualizacaoDisplay" name="visualizacaoDisplay"
            [(ngModel)]="novoItem.visualizacaoDisplay" required>
        </div>
        <button (click)="mudarMultiConsultaValor(true)" *ngIf="!EhMultiConsultaValor" class="btn btn-secondary"
          style="margin-left: 15px;margin-right: 150px;position: absolute; right: 45px;">Abrir Multiplas
          Consultas</button>
        <button (click)="mudarRegistroComCampos(true)" *ngIf="!EhRegistroComCampos" class="btn btn-secondary"
          style="margin-left: 15px;margin-right: 15px;position: absolute; right: 45px;">Abrir Campos</button>

        <ng-container *ngIf="!EhMultiConsultaValor">
          <button (click)="validateAndFormat(0, 'Valor')" class="btn btn-secondary"
            style="margin-left: 15px;margin-right: 364px;position: absolute; right: 45px;">Validar e Formatar
            JSON</button>



          <div class="form-div p-3">
            <label for="consulta" class="form-label">Consulta</label>
            <textarea type="text" class="form-control" id="consulta" name="consulta" [(ngModel)]="novoItem.consulta"
              required></textarea>
            <div id="consultaHelpBlock" class="form-text">
              Utilize esses paramentros para indicar data: #sc:inicio_dia#; #sc:fim_dia#; #sc:data_atual#; ou para
              filtrar por id: #sc:id#;<br />
              A variavel #sc:data_atual# pode ser implementada como #sc:data_atual-Xh#, #sc:data_atual+Xmin#,
              #sc:data_atual-Xs# altereando o X para um numero necessario.
            </div>
          </div>
        </ng-container>
        <div *ngIf="EhMultiConsultaValor">
          <hr>
          <label for="calculoCores" class="form-label">Calculo de Valor:</label>
          <div *ngFor="let consulta of consultasValor; let i = index">
            <label for="consulta" class="form-label">Consulta {{i+1}}</label>
            <div class="form-div p-3">
              <label for="nome" class="form-label">Nome:</label>
              <input type="text" class="form-control" id="v_nome_{{i}}" name="v_nome_{{i}}" [(ngModel)]="consulta.nome"
                required>
            </div>
            <div class="form-div p-3">
              <label for="nome" class="form-label">Índice:</label>
              <input type="text" class="form-control" id="v_indice_{{i}}" name="v_indice_{{i}}"
                [(ngModel)]="consulta.indice" required>
            </div>
            <button (click)="validateAndFormat(i, 'MultiValor')" class="btn btn-secondary"
              style="position: absolute; right: 45px;">Validar e Formatar JSON</button>
            <div class="form-div p-3">
              <label for="consulta" class="form-label">Consulta:</label>
              <textarea class="form-control" id="v_consulta_{{i}}" name="v_consulta_{{i}}"
                [(ngModel)]="consulta.consulta" required></textarea>
              <div id="consultaHelpBlock1" class="form-text">
                Utilize esses paramentros para indicar data: #sc:inicio_dia#; #sc:fim_dia#; #sc:data_atual#; ou para
                filtrar por id: #sc:id#;<br />
                A variavel #sc:data_atual# pode ser implementada como #sc:data_atual-Xh#, #sc:data_atual+Xmin#,
                #sc:data_atual-Xs# altereando o X para um numero necessario.
              </div>
            </div>
            <div class="form-div p-3">
              <label for="tipoResultado" class="form-label">Tipo Resultado</label>
              <select class="form-select" [(ngModel)]="consulta.tipoResultado" name="v_tipoResultado_{{i}}">
                <option *ngFor="let tipoResultado of options" [value]="tipoResultado.value">{{ tipoResultado.label }}
                </option>
              </select>
            </div>
            <button (click)="removerConsultaValor(i)" class="btn btn-secondary"
              style="margin-left: 15px;margin-right: 15px;">Remover Consulta</button>
            <hr>
          </div>
          <div class="form-div p-3">
            <label for="formulaValor" class="form-label">Formula para o Calculo de Valor:</label>
            <input type="text" class="form-control" id="formulaValor" name="formulaValor"
              [(ngModel)]="novoItem.formulaValor" required>
          </div>
          <button (click)="adicionarConsultaValor()" class="btn btn-info" style="margin-left: 15px;">Adicionar
            Consulta</button>
          <button (click)="mudarMultiConsultaValor(false)" *ngIf="EhMultiConsultaValor" class="btn btn-danger"
            style="margin-left: 15px;margin-right: 15px;">Cancelar Multiplas Consultas</button>

        </div>
        <div *ngIf="EhRegistroComCampos">
          <hr>
          <label for="calculoCores" class="form-label">Campos da Consulta:</label>
          <div *ngFor="let consulta of campos; let i = index">
            <label for="consulta" class="form-label">Campo {{i+1}}</label>
            <div class="form-div p-3">
              <label for="nome" class="form-label">Nome:</label>
              <input type="text" class="form-control" id="cp_nome_{{i}}" name="cp_nome_{{i}}"
                [(ngModel)]="consulta.nome" required>
            </div>
            <ng-container *ngIf="EhMultiConsultaValor">
              <div class="form-div p-3">
                <label for="nomeConsultaRefentente" class="form-label">Consulta Referente:</label>
                <select class="form-select" [(ngModel)]="consulta.nomeConsultaRefentente"
                  name="cp_nomeConsultaRefentente_{{i}}">
                  <option *ngFor="let tgc of consultasValor" [value]="tgc.nome">{{ tgc.nome }}
                  </option>
                  <option value="MultiConsulta">Ambas Consultas</option>
                </select>
              </div>
            </ng-container>
            <div class="form-div p-3" style=" padding-left: 56px !important;  padding-bottom: 2.5em; display: flex;">
              <div class="form-div form-check form-switch">
                <input class="form-check-input" type="checkbox" [(ngModel)]="consulta.naoMostraCampo"
                  name="cp_naomostraCampo_{{i}}" id="cp_naomostraCampo_{{i}}">
                <label class="form-check-label" for="flexCheckDefault">
                  Não mostrar o Campo na tela
                </label>
              </div>
              <div class="form-div form-check form-switch" style="margin-left: 10px;">
                <input class="form-check-input" type="checkbox" [(ngModel)]="consulta.naoMostraTitulo"
                  name="cp_naomostratitulo_{{i}}" id="cp_naomostratitulo_{{i}}">
                <label class="form-check-label" for="flexCheckDefault">
                  Não mostrar o Titulo na tela
                </label>
              </div>
              <div class="form-div form-check form-switch" style="margin-left: 10px;">
                <input class="form-check-input" type="checkbox" [(ngModel)]="consulta.ehComponenteGrafico"
                  name="cp_ehcomponentegrafico_{{i}}" id="cp_ehcomponentegrafico_{{i}}">
                <label class="form-check-label" for="flexCheckDefault">
                  É um componente Grafico
                </label>
              </div>
              <div class="form-div form-check form-switch" style="margin-left: 10px;">
                <input class="form-check-input" type="checkbox" [(ngModel)]="consulta.ehLink" name="cp_ehlink_{{i}}"
                  id="cp_ehlink_{{i}}">
                <label class="form-check-label" for="flexCheckDefault">
                  É um Link
                </label>
              </div>
              <div class="form-div form-check form-switch" style="margin-left: 10px;">
                <input class="form-check-input" type="checkbox" [(ngModel)]="consulta.ehCampoCabecalho"
                  name="cp_ehCampoCabecalho_{{i}}" id="cp_ehCampoCabecalho_{{i}}">
                <label class="form-check-label" for="flexCheckDefault">
                  É o cabeçalho
                </label>
              </div>
              <div *ngIf="consulta.tipoValorGrafico == 5" class="form-div form-check form-switch" style="margin-left: 10px;">
                <input class="form-check-input" type="checkbox" [(ngModel)]="consulta.ehOcultoPadrao"
                  name="cp_ehOcultoPadrao_{{i}}" id="cp_ehOcultoPadrao_{{i}}">
                <label class="form-check-label" for="flexCheckDefault">
                  Oculto por padrão?
                </label>
              </div>
              <div class="form-div form-check form-switch" style="margin-left: 10px;">
                <input class="form-check-input" type="checkbox" [(ngModel)]="consulta.ehCampoOrdenacao"
                  name="cp_ehCampoOrdenacao_{{i}}" id="cp_ehCampoOrdenacao_{{i}}">
                <label class="form-check-label" for="flexCheckDefault">
                  É o Campo de Ordenação?
                </label>
              </div>
            </div>
            <ng-container *ngIf="consulta.ehComponenteGrafico">
              <button (click)="validateAndFormat(i, 'CAMPO')" class="btn btn-secondary"
                style="position: absolute; right: 45px;">Validar e Formatar JSON</button>
              <div class="form-div p-3">
                <label for="componenteGraficoHtml" class="form-label">Html do Componente Grafico:</label>
                <textarea type="text" class="form-control" id="cp_componenteGraficoHtml_{{i}}"
                  name="cp_componenteGraficoHtml_{{i}}" [(ngModel)]="consulta.componenteGraficoHtml"
                  required></textarea>
                <div id="consultaHelpBlock1" class="form-text">Exemplo: {{HTMLPadrao}}</div>
              </div>
              <div class="form-div p-3">
                <label for="componenteGraficoCss" class="form-label">Css do Componente Grafico:</label>
                <textarea type="text" class="form-control" id="cp_componenteGraficoCss_{{i}}"
                  name="cp_componenteGraficoCss_{{i}}" [(ngModel)]="consulta.componenteGraficoCss" required></textarea>
              </div>
              <div class="form-div p-3">
                <label for="componenteGraficoPossicao" class="form-label">Possição do Componente Grafico:</label>
                <input type="text" class="form-control" id="cp_componenteGraficoPossicao_{{i}}"
                  name="cp_componenteGraficoPossicao_{{i}}" [(ngModel)]="consulta.componenteGraficoPossicao" required>
                <div id="consultaHelpBlock1" class="form-text">Exemplo: {{PossicaoPadrao}}</div>
              </div>
            </ng-container>
            <div class="form-div p-3">
              <label for="tipoValorGrafico" class="form-label">Tipo Identificação Gráfico</label>
              <select class="form-select" [(ngModel)]="consulta.tipoValorGrafico" name="cp_tipoValorGrafico_{{i}}">
                <option *ngFor="let tgc of optionsTipoGraficoCampo" [value]="tgc.value">{{ tgc.label }}
                </option>
              </select>
            </div>
            <ng-container *ngIf="consulta.ehLink">
              <div class="form-div p-3">
                <label for="linkRedirect" class="form-label">Link de Redirect:</label>
                <input type="text" class="form-control" id="cp_linkRedirect_{{i}}" name="cp_linkRedirect_{{i}}"
                  [(ngModel)]="consulta.linkRedirect" required>
              </div>
              <button (click)="validateAndFormat(i, 'CAMPO_LINK')" class="btn btn-secondary"
                style="position: absolute; right: 45px;">Validar e Formatar JSON</button>
              <div class="form-div p-3">
                <label for="linkVariaveis" class="form-label">Variaveis para enviar ao Link:</label>
                <textarea type="text" class="form-control" id="cp_linkVariaveis_{{i}}" name="cp_linkVariaveis_{{i}}"
                  [(ngModel)]="consulta.linkVariaveis" required></textarea>
                <div id="consultaHelpBlock1" class="form-text">Exemplo: {{PossicaoPadrao}}</div>
              </div>
            </ng-container>
            <div class="form-div p-3">
              <label for="icone" class="form-label">Icone:</label>
              <input type="text" class="form-control" id="cp_icone_{{i}}" name="cp_icone_{{i}}"
                [(ngModel)]="consulta.icone" required>
            </div>
            <div class="form-div p-3">
              <label for="campoCalculo" class="form-label">Calculo Do Campo:</label>
              <input type="text" class="form-control" id="cp_campoCalculo_{{i}}" name="cp_campoCalculo_{{i}}"
                [(ngModel)]="consulta.campoCalculo" required>
            </div>
            <div class="form-div p-3">
              <label for="ordem" class="form-label">Ordem Do Campo:</label>
              <input type="number" class="form-control" id="cp_ordem_{{i}}" name="cp_ordem_{{i}}"
                [(ngModel)]="consulta.ordem" required>
            </div>

            <div class="form-div p-3">
              <label for="loopingPai" class="form-label">Está a baixo Do Looping:</label>
              <input type="text" class="form-control" id="cp_loopingPai_{{i}}" name="cp_loopingPai_{{i}}"
                [(ngModel)]="consulta.loopingPai" required>
            </div>
            <div class="form-div p-3">
              <label for="tipoResultado" class="form-label">Tipo Resultado</label>
              <select class="form-select" [(ngModel)]="consulta.tipoResultado" name="cp_tipoResultado_{{i}}">
                <option *ngFor="let tipoResultado of optionsCampos" [value]="tipoResultado.value">{{ tipoResultado.label
                  }}</option>
              </select>
            </div>
            <div class="form-div p-3">
              <label for="orientacaoLoop" class="form-label">Orientação do Looping<span class="required"
                  *ngIf="consulta.tipoResultado == 20">*</span></label>
              <select class="form-select" [(ngModel)]="consulta.orientacaoLoop" name="cp_orientacaoLoop_{{i}}">
                <option value=""></option>
                <option value="VERTICAL">Vertical (em pé)</option>
                <option value="HORIZONTAL">Horizontal (deitado)</option>
              </select>
            </div>
            <button (click)="removerCampo(i)" class="btn btn-secondary"
              style="margin-left: 15px;margin-right: 15px;">Remover Campo</button>
            <hr>
          </div>
          <button (click)="adicionarCampo()" class="btn btn-info" style="margin-left: 15px;">Adicionar Campo</button>
          <button (click)="AbrirCopia()" class="btn btn-info" style="margin-left: 15px;">Copiar Campos de outro
            Indicador</button>
          <div class="form-div p-3" [style.display]="EhCopiaCampo ? 'block' : 'none'">
            <label for="indicador" class="form-label">De qual Indicador vai copiar?</label>
            <select #mySelectIndicadorCopia name="select" class="form-select"
              (change)="onOptionChangeCopiaCampo(mySelectIndicadorCopia.value)">
              <option value="">Escolha o Indicador</option>
              <option *ngFor="let objeto of listaIndAllEtapas" value={{objeto.id}}>{{objeto.etapa}} - {{objeto.nome}}
              </option>

            </select>
            <button (click)="CopiarCampo()" class="btn btn-info">Copiar Campos</button>
          </div>
          <button (click)="mudarRegistroComCampos(false)" *ngIf="EhRegistroComCampos" class="btn btn-danger"
            style="margin-left: 15px;margin-right: 15px;">Cancelar Registro de Campos</button>


        </div>

        <div class="form-div p-3">
          <label for="parametro" class="form-label">Parametro</label>
          <input type="text" class="form-control" id="parametro" name="parametro" [(ngModel)]="novoItem.parametro"
            required>
        </div>
        <div class="form-div p-3">
          <label for="colecao" class="form-label">Colecao</label>
          <input type="text" class="form-control" id="colecao" name="colecao" [(ngModel)]="novoItem.colecao" required>
        </div>
        <div class="form-div p-3">
          <label for="versaoIndice" class="form-label">Versao Indice</label>
          <input type="text" class="form-control" id="versaoIndice" name="versaoIndice"
            [(ngModel)]="novoItem.versaoIndice" required>
        </div>
        <div class="form-div p-3">
          <label for="ordenacaoOrientacao" class="form-label">Orientação da Ordenação<span
              class="required">*</span></label>
          <select #mySelectOrdem class="form-select" [(ngModel)]="novoItem.ordenacaoOrientacao"
            name="ordenacaoOrientacao">
            <option value="DESC">DECRESCENTE (maior para menor)</option>
            <option value="CRES">CRESCENTE (menor para maior)</option>
          </select>
        </div>
        <div class="form-div p-3">
          <label for="tipoResultado" class="form-label">Tipo Resultado</label>
          <select class="form-select" (change)="mostrarCampoValorNocabecalho()" [(ngModel)]="novoItem.tipoResultado"
            name="tipoResultado">
            <option *ngFor="let tipoResultado of options" [value]="tipoResultado.value">{{ tipoResultado.label }}
            </option>
          </select>
        </div>
        <div *ngIf="this.EhPorcentagem" class="form-div form-check form-switch"
          style=" padding-left: 56px !important;  padding-bottom: 2.5em;">
          <input class="form-check-input" type="checkbox" [(ngModel)]="novoItem.valoresPorcentagemCabecalho"
            name="valoresPorcentagemCabecalho" id="valoresPorcentagemCabecalho">
          <label class="form-check-label" for="flexCheckDefault">
            Adicionar o nominador e o denominador no cabeçalho
          </label>
        </div>
        <label for="calculoCores" class="form-label">Calculo de Cores:</label>
        <div *ngFor="let consulta of consultasCor; let i = index">
          <label for="consulta" class="form-label">Consulta {{i+1}}</label>
          <div class="form-div p-3">
            <label for="nome" class="form-label">Nome:</label>
            <input type="text" class="form-control" id="c_nome_{{i}}" name="c_nome_{{i}}" [(ngModel)]="consulta.nome"
              [readonly]="consulta.nome == 'Regra de Valor' || consulta.nome == 'Regra de Retorno da Formula'" required>
          </div>
          <div class="form-div p-3" *ngIf="!consulta.nome.includes('Regra')">
            <label for="nome" class="form-label">Índice:</label>
            <input type="text" class="form-control" id="c_indice_{{i}}" name="c_indice_{{i}}"
              [(ngModel)]="consulta.indice" required>
          </div>
          <button (click)="validateAndFormat(i, 'CORES')" class="btn btn-secondary"
            style="position: absolute; right: 45px;">Validar e Formatar JSON</button>
          <div class="form-div p-3">
            <label for="consulta" class="form-label">Consulta:</label>
            <textarea class="form-control" id="c_consulta_{{i}}" name="c_consulta_{{i}}" [(ngModel)]="consulta.consulta"
              required></textarea>
            <div id="consultaHelpBlock1" class="form-text">
              Utilize esses paramentros para indicar data: #sc:inicio_dia#; #sc:fim_dia#; #sc:data_atual#; ou para
              filtrar por id: #sc:id#;<br />
              A variavel #sc:data_atual# pode ser implementada como #sc:id#, #sc:data_atual-Xh#, #sc:data_atual+Xmin#,
              #sc:data_atual-Xs# altereando o X para um numero necessario.
            </div>
            <div id="consultaHelpBlock2" *ngIf="EhCalculoCorPadrao !== 0 && consulta.nome.includes('Regra')"
              class="form-text">
              Altere os valores equivalentes do periodo para cada cor no lugar de NumeroMaior e NumeroMenor (se for um
              numero quebrado utilizar ponto - ex: 9,99 será 9.99)
            </div>
          </div>
          <div class="form-div p-3" *ngIf="!consulta.nome.includes('Regra')">
            <label for="tipoResultado" class="form-label">Tipo Resultado</label>
            <select class="form-select" [(ngModel)]="consulta.tipoResultado" name="c_tipoResultado_{{i}}">
              <option *ngFor="let tipoResultado of options" [value]="tipoResultado.value">{{ tipoResultado.label }}
              </option>
            </select>
          </div>


          <div class="dropdown" (click)="toggleDropdown()" style="position: absolute; right: 30px;">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Setar Regra Padrão
            </button>
            <div class="dropdown-menu" [class.show]="isDropdownOpen" (click)="handleDropdownItemClick($event)"
              aria-labelledby="dropdownMenu2">
              <button class="dropdown-item" type="button" (click)="SetarRegraPadrao(consulta, true)">Pelo Valor do
                Indicador</button>
              <button class="dropdown-item" type="button" (click)="SetarRegraDoValorDasFormula(consulta, true)">Pelo Valor da
                Formula</button>
              <button class="dropdown-item" type="button" (click)="SetarRegraCampos(consulta)">Pelo Valor para
                Campos</button>
            </div>
          </div>

          <button (click)="CancelarRegraPadrao(consulta)"
            *ngIf="EhCalculoCorPadrao!==0 && consulta.nome.includes('Regra')" class="btn btn-danger"
            style="margin-left: 15px;margin-right: 15px;">Cancelar Regra Padrao</button>
          <button (click)="getCalculoCorIndicadorEtapa1()" *ngIf="!EhEtapa1" class="btn btn-secondary"
            style="margin-left: 15px;margin-right: 15px;">Copiar Regra do Indicador da Etapa 1</button>
          <button (click)="removerConsultaCor(i,consulta)" class="btn btn-secondary"
            style="margin-left: 15px;margin-right: 15px;">Remover Consulta</button>
          <hr>
        </div>
        <div class="form-div p-3" *ngIf="EhCalculoCorPadrao == 2">
          <label for="formula" class="form-label">Formula para o Calculo de Cor:</label>
          <input type="text" class="form-control" id="formula" name="formula" [(ngModel)]="novoItem.formula" required>
        </div>
        <div class="form-div form-check form-switch">
          <input class="form-check-input" type="checkbox" [(ngModel)]="novoItem.temRegraAberturaFechamento"
            name="temRegraAberturaFechamento" id="temRegraAberturaFechamento">
          <label class="form-check-label" for="flexCheckDefault">
            tem a regra de cor de fechamento e abertura de posto?
          </label>
        </div>
        <button (click)="adicionarConsultaCor()" *ngIf="EhCalculoCorPadrao !== 1" class="btn btn-info"
          style="margin-left: 15px;">Adicionar Consulta</button>
        <div class="form-div p-3">
          <label for="corDefault" class="form-label">Cor Default(Caso vir sem Dados): <span
              class="required">*</span></label>
          <input type="text" class="form-control" id="corDefault" name="corDefault" [(ngModel)]="novoItem.corDefault"
            required>
        </div>
        <div class="form-div p-3" *ngIf="EhPorcentagem && !EhMultiConsultaValor">
          <label for="corDefaultDenominador" class="form-label">Cor Default Denominador(Caso Denominador !=0 e
            Nominador=0):</label>
          <input type="text" class="form-control" id="corDefaultDenominador" name="corDefaultDenominador"
            [(ngModel)]="novoItem.corDefaultDenominador">
        </div>
        <!--<div class="form-div p-3" *ngIf="EhPorcentagem && !EhMultiConsultaValor">
          <label for="corDefaultNominador" class="form-label">Cor Default Nominador(Caso Denominador =0 e Nominador!=0):</label>
          <input type="text" class="form-control" id="corDefaultNominador" name="corDefaultNominador" [(ngModel)]="novoItem.corDefaultNominador">
        </div>-->
        <div class="form-div p-3" *ngIf="EhPorcentagem && !EhMultiConsultaValor">
          <label for="corDefaultZero" class="form-label">Cor Default Tudo 0(Caso Denominador =0 e Nominador=0):</label>
          <input type="text" class="form-control" id="corDefaultZero" name="corDefaultZero"
            [(ngModel)]="novoItem.corDefaultZero">
        </div>

        <hr>
        <label for="calculoCores" class="form-label">Calculo do Farol:</label>
        <div class="form-div p-3" style=" padding-left: 56px !important;  padding-bottom: 2.5em; display: flex;">
          <div class="form-div form-check form-switch">
            <input class="form-check-input" type="checkbox" [(ngModel)]="novoItem.desabilitarFarol"
              name="desabilitarFarol" id="desabilitarFarol">
            <label class="form-check-label" for="flexCheckDefault">
              Desabilitar Farol?
            </label>
          </div>
        </div>
        <button (click)="AbrirCopiaFarol()" class="btn btn-info" style="margin-left: 15px;">Copiar Farol de outro
          Indicador</button>
        <div class="form-div p-3" [style.display]="EhFarolCopia ? 'block' : 'none'">
          <label for="indicador" class="form-label">De qual Indicador vai copiar?</label>
          <select #mySelectIndicadorCopiaFarol name="select" class="form-select"
            (change)="onOptionChangeCopiaFarol(mySelectIndicadorCopiaFarol.value)">
            <option value="">Escolha o Indicador</option>
            <option *ngFor="let objeto of listaIndAllEtapasComFarol" value={{objeto.id}}>{{objeto.etapa}} - {{objeto.nome}}
            </option>

          </select>
          <button (click)="CopiarFarol()" class="btn btn-info">Copiar Farol</button>
        </div>
        <div *ngFor="let consulta of consultasFarol; let i = index">
          <label for="consulta" class="form-label">Consulta {{i+1}}</label>
          <div class="form-div p-3">
            <label for="nome" class="form-label">Nome:</label>
            <input type="text" class="form-control" id="f_nome_{{i}}" name="f_nome_{{i}}" [(ngModel)]="consulta.nome"
              [readonly]="consulta.nome == 'Regra de Valor' || consulta.nome == 'Regra de Retorno da Formula'" required>
          </div>
          <div class="form-div p-3" *ngIf="!consulta.nome.includes('Regra')">
            <label for="nome" class="form-label">Índice:</label>
            <input type="text" class="form-control" id="f_indice_{{i}}" name="f_indice_{{i}}"
              [(ngModel)]="consulta.indice" required>
          </div>
          <button (click)="validateAndFormat(i, 'FAROL')" class="btn btn-secondary"
            style="position: absolute; right: 45px;">Validar e Formatar JSON</button>
          <div class="form-div p-3">
            <label for="consulta" class="form-label">Consulta:</label>
            <textarea class="form-control" id="f_consulta_{{i}}" name="f_consulta_{{i}}" [(ngModel)]="consulta.consulta"
              required></textarea>
            <div id="consultaHelpBlock1" class="form-text">
              Utilize esses paramentros para indicar data: #sc:inicio_dia#; #sc:fim_dia#; #sc:data_atual#; ou para
              filtrar por id: #sc:id#;<br />
              A variavel #sc:data_atual# pode ser implementada como #sc:id#, #sc:data_atual-Xh#, #sc:data_atual+Xmin#,
              #sc:data_atual-Xs# altereando o X para um numero necessario.
            </div>
            <div id="consultaHelpBlock2" *ngIf="EhCalculoCorPadrao !== 0 && consulta.nome.includes('Regra')"
              class="form-text">
              Altere os valores equivalentes do periodo para cada cor no lugar de NumeroMaior e NumeroMenor (se for um
              numero quebrado utilizar ponto - ex: 9,99 será 9.99)
            </div>
          </div>
          <div class="form-div p-3" *ngIf="!consulta.nome.includes('Regra')">
            <label for="tipoResultado" class="form-label">Tipo Resultado</label>
            <select class="form-select" [(ngModel)]="consulta.tipoResultado" name="f_tipoResultado_{{i}}">
              <option *ngFor="let tipoResultado of options" [value]="tipoResultado.value">{{ tipoResultado.label }}
              </option>
            </select>
          </div>


          <div class="dropdown" (click)="toggleDropdown()" style="position: absolute; right: 30px;">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Setar Regra Padrão
            </button>
            <div class="dropdown-menu" [class.show]="isDropdownOpen" (click)="handleDropdownItemClick($event)"
              aria-labelledby="dropdownMenu2">
              <button class="dropdown-item" type="button" (click)="SetarRegraPadrao(consulta, false)">Pelo Valor do
                Indicador</button>
              <button class="dropdown-item" type="button" (click)="SetarRegraDoValorDasFormula(consulta, false)">Pelo Valor da
                Formula</button>
            </div>
          </div>

          <button (click)="CancelarRegraPadrao(consulta)"
            *ngIf="consulta.nome.includes('Regra')" class="btn btn-danger"
            style="margin-left: 15px;margin-right: 15px;">Cancelar Regra Padrao</button>
          <button (click)="getCalculoCorIndicadorEtapa1()" *ngIf="!EhEtapa1" class="btn btn-secondary"
            style="margin-left: 15px;margin-right: 15px;">Copiar Regra do Indicador da Etapa 1</button>
          <button (click)="removerConsultaFarol(i,consulta)" class="btn btn-secondary"
            style="margin-left: 15px;margin-right: 15px;">Remover Consulta</button>
          <hr>
        </div>
        <button (click)="adicionarConsultaFarol()" class="btn btn-info"
        style="margin-left: 15px;">Adicionar Consulta</button>
        <div class="form-div p-3">
          <label for="formulaCalculoFarol" class="form-label">Formula para o Calculo do Farol:</label>
          <input type="text" class="form-control" id="formulaCalculoFarol" name="formulaCalculoFarol" [(ngModel)]="novoItem.formulaCalculoFarol" required>
        </div>
        <div class="form-div p-3">
          <label for="tempoAtualizacaoCalculoFarol" class="form-label">Tempo mínimo de Atualização do Farol:</label>
          <input type="text" class="form-control" id="tempoAtualizacaoCalculoFarol" name="tempoAtualizacaoCalculoFarol" [(ngModel)]="novoItem.tempoAtualizacaoCalculoFarol" required>
        </div>
        <div class="form-div p-3">
          <label for="tipoFinal" class="form-label">Tipo final do Farol:</label>
          <select class="form-select" [(ngModel)]="novoItem.tipoFinal" name="tipoFinal">
            <option value="posto">Posto</option>
            <option value="orgao">Orgao</option>
            <option value="servico">Serviço</option>
            <option value="fila">Fila</option>
          </select>
        </div>

        <div class="div-button mb-3 p-3">
          <button *ngIf="novoItem.id != undefined && novoItem.inativo != true" id="class-button"
            style="position: fixed; bottom: 58px; right: 171px;border-radius: 50%;width: 45px;height: 45px;"
            (click)="InativarItem(novoItem.id)" class="btn btn-danger"><i class="fa-solid fa-trash-can"></i></button>
          <button *ngIf="novoItem.id != undefined && novoItem.inativo == true" id="class-button"
            style="position: fixed; bottom: 58px; right: 171px;border-radius: 50%;width: 45px;height: 45px;"
            (click)="AtivarItem(novoItem.id)" class="btn btn-success"><i class="fas fa-power-off"></i></button>
          <a style="margin-left: 80%;  margin-right: 10px;position: fixed; bottom: 58px; right: 111px;"
            [routerLink]="['/indicador-listagem']"><button id="class-button" class="btn btn-secondary"
              style="border-radius: 50%;width: 45px;height: 45px;"><i class="fa-solid fa-rotate-left"></i></button></a>
          <button id="class-button"
            style="margin-right: 10px;position: fixed; bottom: 58px; right: 61px;border-radius: 50%;width: 45px;height: 45px;"
            (click)="Limpar()" class="btn btn-secondary"><i class="fa-solid fa-broom"></i></button>
          <button id="class-button"
            style="position: fixed; bottom: 58px; right: 22px;border-radius: 50%;width: 45px;height: 45px;"
            (click)="submitForm()" class="btn btn-primary"><i class="fa-solid fa-floppy-disk"></i></button>
        </div>
      </form>
    </section>
  </main>
</section>
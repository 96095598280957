export class CalculoCores {
    public nome: string;
    public consulta: string;
    public indice: string;
    tipoResultado: number | undefined = undefined;

    constructor(
        nome: string = "",
        consulta: string = "",
        indice: string = "",
        tipoResultado: number | undefined = undefined) {
      this.nome = nome;
      this.consulta = consulta;
      this.indice = indice;
      this.tipoResultado = tipoResultado;
    }
}
import { Component, ElementRef, Inject, Input, OnChanges, Output, Renderer2, SimpleChanges, EventEmitter, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { CommonModule, DOCUMENT } from '@angular/common';
import { indicadorDTO } from '../../modal/dto/indicadorDTO';
import { PostoService } from '../../service/posto.service';
import { RegiaoService } from '../../service/regiao.service';

@Component({
    selector: 'app-forca-login',
    standalone: true,
    imports: [
        RouterLink,
        RouterOutlet,
        CommonModule,
        // * MATERIAL IMPORTS
        MatSidenavModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
    ],
    template: ``
})


export class ForcaLoginComponent implements OnInit {
    constructor(private route: ActivatedRoute, public router: Router, private postoService: PostoService, private regiaoService: RegiaoService) { }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            localStorage.setItem('login', "Força_Login_" + this.generateRandomNumber(1, 10000));
            let token = params.get('token');
            if (token != null)
                localStorage.setItem('token', token);
            let jsonString = '{"funcionalidades":[],"postos":[],"regiao":[],"perfil":"superuser"}';
            let jsonObject = JSON.parse(jsonString);
            localStorage.setItem('deslogar', "false");
            localStorage.setItem('forca-login', "true");

            this.regiaoService.getRegiao().pipe().subscribe((result: any) => {
                result.Regiao.forEach((reg: any) => {
                    jsonObject.regiao.push(Number.parseInt(reg.codigo));
                });
                this.postoService.getPosto().pipe().subscribe((result: any) => {
                    result.Posto.forEach((posto: any) => {
                        if(posto.codigoCentralizado != "")
                            jsonObject.postos.push(Number.parseInt(posto.codigoCentralizado));
                    });
                    localStorage.setItem('salaSituacao', JSON.stringify(jsonObject));
                    this.router.navigate(['/dashboard']);
                });

            });

        });
    }

    generateRandomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
import { ClasseMeusDados } from "./ControlePermissaoUsers_MeusDados";

export class ClasseControlePermissaoUsers{
    id:string|null ;
    nome: string | null;
    cpf: string | null;
    senha: string | null;
    email: string | null;
    meusDados:ClasseMeusDados | undefined;

    constructor(
        id: string|null=null,
        nome: string|null=null,
        cpf: string|null=null,
        senha: string|null=null,
        email: string|null=null,
        meusDados: ClasseMeusDados | undefined = undefined
            ){
        this.id = id;
        this.nome = nome;
        this.cpf = cpf;
        this.senha = senha;
        this.email = email;
        this.meusDados = meusDados;
    }
}


import { indicadorDTO } from "./dto/indicadorDTO";

export class VagasDisponiveisModel{
  public id: number;
  public nomeOrgao: string;
  public dataVagasDisponPeriodo: string;
  public backGroundColorCSS: string;
  public nomeCor: string;
  public pTotalVagas: number;
  public pTotalVagasAgendadas: number;
  public pVagasUtilizadas: string;
  public pVagasDoLivresDia: number;
  public codigoIndicador: number;
  public chartType: string;
  public CodigoCentralizado: string;
  public linkRedirect: string;
  public chartDatasets: any
  public indicador: indicadorDTO | undefined;
  public ListainformacoesGraficoGeral: string[]=[];
  public valorOrdenacao: number = 0;
  constructor(
    id: number, nomeOrgao: string, dataVagasDisponPeriodo: string,
    backGroundColorCSS: string, nomeCor: string, pTotalVagas: number,pTotalVagasAgendadas: number,
    pVagasUtilizadas: string, pVagasDoLivresDia: number, chartType: string, chartDatasets: any, codigoIndicador: number, 
    CodigoCentralizado: string, linkRedirect: string, indicador: indicadorDTO | undefined
    ){
    this.id = id;
    this.nomeOrgao = nomeOrgao;
    this.dataVagasDisponPeriodo = dataVagasDisponPeriodo;
    this.backGroundColorCSS = backGroundColorCSS; // Cor do backgroud do card
    this.nomeCor = nomeCor; // nome da cor do indicador
    this.pTotalVagas = pTotalVagas;
    this.pTotalVagasAgendadas = pTotalVagasAgendadas
    this.pVagasUtilizadas = pVagasUtilizadas;
    this.pVagasDoLivresDia = pVagasDoLivresDia;
    this.chartType = chartType; // Tipo de chart se tipo: barra, pizza, progressão
    this.chartDatasets = chartDatasets; // É a data do grafico
    this.codigoIndicador = codigoIndicador; // É a data do grafico
    this.linkRedirect = linkRedirect; // É a data do grafico
    this.CodigoCentralizado = CodigoCentralizado; // É a data do grafico
    this.indicador = indicador;
    
  }


}


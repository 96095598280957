<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Inclusão de Justificativa
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        <section class="pesq-section">
            <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaNomePosto" class="form-label">Nome do Posto:</label>
                                    <input type="text" class="form-control" placeholder="Nome do Posto"
                                        id="pesquisaNomePosto" name="pesquisaNomePosto" [(ngModel)]="pesquisaNomePosto"
                                        required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaFilaDesc" class="form-label">Nome da Fila:</label>
                                    <input type="text" class="form-control" placeholder="Nome da Fila"
                                        id="pesquisaFilaDesc" name="pesquisaFilaDesc" [(ngModel)]="pesquisaFilaDesc"
                                        required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaDataInit" class="form-label">Data de Inicio:</label>
                                    <input type="date" class="form-control" placeholder="Data de Inicio"
                                        id="pesquisaDataInit" name="pesquisaDataInit" [(ngModel)]="pesquisaDataInit"
                                        required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaDataFim" class="form-label">Data Fim:</label>
                                    <input type="date" class="form-control" placeholder="Data Fim" id="pesquisaDataFim"
                                        name="pesquisaDataFim" [(ngModel)]="pesquisaDataFim" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="regiao" class="form-label">Status:</label>
                                    <select #mySelectFiltroStatus name="select" aria-placeholder="Status"
                                        class="form-select" id="pesquisaStatus" name="pesquisaStatus"
                                        [(ngModel)]="pesquisaStatus">
                                        <option value=""></option>
                                        <option value="{{StatusIndicadorAlerta.AO_INCLUIR}}">Ao incluir</option>
                                        <option value="{{StatusIndicadorAlerta.PENDENTE_VALIDACAO}}">Realizado</option>
                                        <option value="{{StatusIndicadorAlerta.PEDENTE_POSTO}}">Pendende Posto</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                                    <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>

        </section>
        <!--Tabela -->
        <section class="main-section-table">
            <!-- <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator> -->
            <table class="table">
                <!--toJsonBody-->
                <thead>

                    <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col" >Posto</th>
                        <th scope="col" >Fila</th>
                        <th scope="col" (click)="sortTable('dataInicio')" style="cursor: pointer">Data de Início / Hora
                        </th>
                        <th scope="col" (click)="sortTable('data_fim')" style="cursor: pointer">Data Final / Hora</th>
                        <th scope="col" (click)="sortTable('qtdCidadaoEmEspera')" style="cursor: pointer">Cidadãos em
                            espera</th>
                        <th scope="col" (click)="sortTable('qtdPontosAbertos')" style="cursor: pointer">Pontos abertos
                        </th>
                        <th scope="col" (click)="sortTable('tempo')" style="cursor: pointer">Tempo total</th>
                        <th scope="col" (click)="sortTable('status')" style="cursor: pointer">Status</th>
                        <!--<th scope="col">Serviços do posto</th>
            <th scope="col">Órgãos do posto</th>-->
                        @if(validarRole() == true){
                        <th scope="col">Funções</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (p of listaInd; track $index) {
                    <tr>
                        <!-- <td style="font-weight: bold;">{{$index+1}}</td> -->
                        <td style="font-weight: bold;">{{p.posto?.descricao}}</td>
                        <td>{{p.descFila}}</td>
                        <td>{{dateTimeFormatPipe.transform(p.dataInicio)}}</td>
                        <td>{{dateTimeFormatPipe.transform(p.data_fim)}}</td>
                        <td>{{p.qtdCidadaoEmEspera}}</td>
                        <td>{{p.qtdPontosAbertos}}</td>
                        <td>{{ timeFormatPipe.transform(p.tempo) }}</td>
                        <td *ngIf="p.status === StatusIndicadorAlerta.AO_INCLUIR"> Ao Incluir</td>
                        <td *ngIf="p.status === StatusIndicadorAlerta.PENDENTE_VALIDACAO">Pendente Validação</td>
                        <td *ngIf="p.status === StatusIndicadorAlerta.PEDENTE_POSTO">Pendente Posto</td>

                        <!--<td></td>
            <td></td>-->
                        <td *ngIf="p.status === StatusIndicadorAlerta.AO_INCLUIR">
                            <a class="btn btn-success" [routerLink]="['/cadastro-justificativa']"
                                [queryParams]="{IdEditar: p.id}"><i class="fa-solid fa-plus"></i></a>
                        </td>
                        @if(validarRole())
                        {
                        <td *ngIf="p.status === StatusIndicadorAlerta.PENDENTE_VALIDACAO">

                            <a class="btn btn-warning" [routerLink]="['/cadastro-justificativa']"
                                [queryParams]="{IdEditar: p.id}"><i class="fa-solid fa-check-double"></i></a>
                        </td>
                        }
                        @else if(validarRolePosto())
                        {
                        <td *ngIf="p.status === StatusIndicadorAlerta.PENDENTE_VALIDACAO">

                            <a class="btn btn-secondary" [routerLink]="['/cadastro-justificativa']"
                                [queryParams]="{IdEditar: p.id}"><i class="fa-solid fa-check-double"></i></a>
                        </td>
                        }
                        @if(validarRole())
                        {
                        <td *ngIf="p.status === StatusIndicadorAlerta.PEDENTE_POSTO">
                            <a class="btn btn-secondary" [routerLink]="['/cadastro-justificativa']"
                                [queryParams]="{IdEditar: p.id}"><i class="fas fa-hourglass"></i></a>
                        </td>
                        }
                        @else if(validarRolePosto())
                        {
                        <td *ngIf="p.status === StatusIndicadorAlerta.PEDENTE_POSTO">
                            <a class="btn btn-danger" [routerLink]="['/cadastro-justificativa']"
                                [queryParams]="{IdEditar: p.id}"><i class="fas fa-hourglass"></i></a>
                        </td>
                        }


                    </tr>
                    }
                </tbody>
            </table>

            <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator>

        </section>


    </main>
</section>
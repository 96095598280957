import { orgao } from "./orgao";
import { regiao } from "./regiao";
import { servico } from "./servico";

export class posto {
  public id: string | null;
  public codigo: string | undefined;
  public descricao: string;
  public inativo: boolean | undefined;
  public horarioInicioSS: string | undefined;
  public horarioInicioSab: string | undefined;
  public horarioFimSS: string | undefined;
  public horarioFimSab: string | undefined;
  public regiao: regiao | undefined;
  public orgaos: orgao[];
  public servicos: servico[];
  public codigoCentralizado: string;

  constructor(id: string | null = null,
    codigo: string | undefined = undefined,
    descricao: string = "",
    codigoCentralizado: string = "",
    regiao: regiao | undefined = undefined, 
    orgaos: orgao[] = [],
    servicos: servico[] = [], 
    inativo : boolean | undefined = undefined,
    horarioInicioSS: string | undefined = undefined,
    horarioInicioSab: string | undefined = undefined,
    horarioFimSS: string | undefined = undefined,
    horarioFimSab: string | undefined = undefined) {
    this.id = id;
    this.codigo = codigo;
    this.descricao = descricao;
    this.codigoCentralizado = codigoCentralizado;
    this.regiao = regiao;
    this.orgaos = orgaos;
    this.servicos = servicos;
    this.inativo = inativo;
    this.horarioInicioSS = horarioInicioSS;
    this.horarioInicioSab = horarioInicioSab;
    this.horarioFimSS = horarioFimSS;
    this.horarioFimSab = horarioFimSab;
  }
}
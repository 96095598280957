@if(perfil != 'posto'){
    <section style="height: auto;">
        <nav>
          <ol class="divbusca">
            <table>
              <tbody>
                <tr>
                  <td class="tdTitilo">
                    <label for="regiao" class="form-label">Região:</label>
                  </td>
                  <td class="tdCampo">
                    <select (change)="onSelecionar(mySelectFiltroRegiao.value)" #mySelectFiltroRegiao name="select"
                      aria-placeholder="Regiao" class="form-select">
                      <option value=""></option>
                      <option *ngFor="let objeto of listaRegiao" value={{objeto.id}}>{{objeto.descricao}}</option>
      
                    </select>
                  </td>
                  <td class="tdTitilo">
                    <label for="posto" class="form-label">Posto:</label>
                  </td>
                  <td class="tdCampo">
                    <app-multiple-selection-select-all [lista]="listaPostos" [width]="larguraTela/2.5" [regiaoSelecionada]="RegiaoSelecionada"
                      (infoClicada)="processarInfo($event)"></app-multiple-selection-select-all>
                  </td>
                  <td class="tdTitilo">
                    <button class="btn btn-primary" (click)="filtrarPesquisa()" id="class-button"
                      type="submit">Pesquisar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </ol>
        </nav>
      </section>
}
<div class="container">
    @defer(on viewport){
    <div class="gh-table">
        <div class="gh-row">
            <div class="gh-cell" style="background-color: #eeeeee; position: sticky; top: 0; margin-top: 10px; margin-bottom: 10px; border-right: 1px solid #cccccc; border-radius: 10px 0 0 0;">
                @if(newFilter.length > 0){
                    {{ quantidadePostosNewFilter }} POSTOS
                }
                @else{
                    {{ quantidadePostos }} POSTOS
                }
            </div>
            @for(column of columnLabels; track column;  let index = $index){
                @if(7 == index){
                    <div class="gh-cell" style="background-color: #eeeeee; position: sticky; top: 0; border-right: 1px solid #cccccc;   border-top-right-radius: 10px;">
                        <span class="gh-value">{{ column.label }}</span>
                    </div>
                }@else{
                    <div class="gh-cell" style="background-color: #eeeeee; position: sticky; top: 0; border-right: 1px solid #cccccc;">
                        <span class="gh-value">{{ column.label }}</span>
                    </div>
                }

            }
        </div>
        @if(tabela != undefined){
        @for(postoLabel of tabela.posto; track postoLabel;  let indexLine = $index){
            @if(newFilter.length > 0){
                @for(filter of newFilter; track filter){
                    @if(filter.codigoCentralizado == postoLabel.id){
                        <div class="gh-row">
                            @for(column of tabela.columnLabel; track column;  let indexCol = $index){
                                @if(indexCol == 0){
                                    <div class="gh-cell rowLabel" style="background-color: #eeeeee; min-width: 153px; width: 600px; ">
                                        <div class="gh-cell2 rowLabel" style="left: 0; background-color: #eeeeee; max-height: 20px;">
                                            <span class="gh-value">
                                                {{ postoLabel.desc }}
                                            </span>
                                        </div>
                                    </div>
                                }
                                @for(servico of postoLabel.servicos; track servico; let indexServico = $index){
                                    @if(column.id == servico.id){
                                        @if(servico.diasEspera || servico.diasEspera == 0){
                                            @if(servico.diasEspera >= 0){
                                                <div class="gh-cell2"  [style.background]="paint(servico.diasEspera,servico.NumTotalVagas)" [style.border-bottom]="paintBorda(servico.diasEspera)" [style.border-top]="paintBorda(servico.diasEspera)">
                                                    <span class="gh-value">
                                                        {{ servico.diasEspera }}
                                                    </span>
                                                </div>
                                            }@else {
                                                <div class="gh-cell" style="background-color: gray; border-top: 1px solid gray; border-bottom: 1px solid gray;" >
                                                    <span class="gh-value">
                                                    </span>
                                                </div>
                                            }
                                        }@else {
                                            <div class="gh-cell"  style="background-color: gray; border-top: 1px solid gray; border-bottom: 1px solid gray;">
                                            </div>
                                        }
                                    }
                                }
                            }
                        </div>
                    }
                }
            }@else {
                <div class="gh-row">
                    @for(column of tabela.columnLabel; track column;  let indexCol = $index){
                        @if(indexCol == 0){
                            <div class="gh-cell rowLabel" style="background-color: #eeeeee; min-width: 153px; width: 600px; ">
                                <div class="gh-cell2 rowLabel" style="left: 0; background-color: #eeeeee; max-height: 20px;">
                                    <span class="gh-value">
                                        {{ postoLabel.desc }}
                                    </span>
                                </div>
                            </div>
                        }
                        @for(servico of postoLabel.servicos; track servico; let indexServico = $index){
                            @if(column.id == servico.id){
                                @if(servico.diasEspera || servico.diasEspera == 0){
                                    @if(servico.diasEspera >= 0){
                                        <div class="gh-cell2"  [style.background]="paint(servico.diasEspera,servico.NumTotalVagas)" [style.border-bottom]="paintBorda(servico.diasEspera)" [style.border-top]="paintBorda(servico.diasEspera)">
                                            <span class="gh-value">
                                                {{ servico.diasEspera }}
                                            </span>
                                        </div>
                                    }@else {
                                        <div class="gh-cell" style="background-color: gray; border-top: 1px solid gray; border-bottom: 1px solid gray;" >
                                            <span class="gh-value">
                                            </span>
                                        </div>
                                    }
                                }@else {
                                    @if(servico.NumTotalVagas != 0){
                                        <div class="gh-cell" style="background-color: #f8d347; border-top: 1px solid #f8d347; border-bottom: 1px solid #f8d347;" >
                                            <span class="gh-value">
                                            </span>
                                        </div>
                                    }
                                    @else{
                                        <div class="gh-cell"  style="background-color: gray; border-top: 1px solid gray; border-bottom: 1px solid gray;">
                                        </div>
                                    }
                                }
                            }
                        }
                    }
                </div>
            }
        }
        }
    </div>
    }@placeholder {
        <div class="aviso">
            <h1>Carregando Dados . . .</h1>
        </div>
    }
</div>

<section>
  <section style="height: auto;">
    <nav>
        <ol class="cd-breadcrumb custom-separator">
            <table>
                <tbody>
                    <tr>
                        <td class="nomeTela">
                          Cadastro de Região
                        </td>
                    </tr>
                </tbody>
            </table>
        </ol>
    </nav>
</section>
  <main>
    <section class="main-section">
      <form class="form-class" data-toggle="collapse">
        <input type="text" class="form-control" id="id" name="id" [(ngModel)]="novoItem.id" required style="display: none;">
        <div class="form-div p-3">
          <input type="number" class="form-control" id="codigo" name="codigo" [(ngModel)]="novoItem.codigo" required style="display: none;">
        </div>
        <div class="form-div p-3">
          <label for="descricao" class="form-label">Descrição</label>
          <input type="text" class="form-control" id="descricao" name="descricao" [(ngModel)]="novoItem.descricao"
            required>
          <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
        </div>
        <div class="div-button mb-3 p-3">
          <a style="margin-left: 80%; margin-right: 10px;" [routerLink]="['/regiao-listagem']"><button id="class-button" class="btn btn-secondary">Voltar</button></a>
          <button id="class-button" style="margin-right: 10px;" (click)="Limpar()" class="btn btn-secondary">Limpar</button>
          <button id="class-button" (click)="submitForm()" class="btn btn-primary">Salvar</button>
        </div>
      </form>
    </section>
  </main>
</section>
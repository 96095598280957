import { isNullOrUndefined } from "util";

export class regiao {
  public id: string | null;
  public codigo: string | undefined;
  public descricao: string;

  constructor(id: string | null = null,
    codigo: string | undefined = undefined,
    descricao: string = "",) {
    this.id = id;
    this.codigo = codigo;
    this.descricao = descricao;
  }
}
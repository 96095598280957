import { PermissaoUsersCargoTipa } from "./ControlePermissaoUsers_Cargo";

export class ClasseMeusDados {
    dataCriacao: string | null;
    perfil: string | null;
    roles: PermissaoUsersCargoTipa|null;

    constructor(dataCriacao: string|null=null, perfil: string|null=null, roles: PermissaoUsersCargoTipa|null=null) {
        this.dataCriacao = dataCriacao;
        this.perfil = perfil;
        this.roles = roles;
    }
}

import { CanMatchFn } from '@angular/router';

export const navbarData = [
  {
    id: 1,
    routeLink: 'dashboard',
    icon: 'fas fa-tachometer-alt',
    label: 'Indicador Dashboard',
    tipo: 'Unico'
    // link: [
    //   {
    //     routerLink:"dashboard/exemplo",
    //     label: "nome da label"
    //   }
    // ]
  },
  {
    id: 17,
    routeLink: '',
    icon: 'fas fa-university',
    label: 'Dashboard por Posto',
    tipo: 'Pai',
    open: false,
    EhFilhoEspecial: true,
    SoAparecerEm: 'dashboard',
    TipoFilhoEspecial: 'FiltroRegiaoPosto',
    roles:[
        'superuser',
        'superintendencia',
        'gestorregional'
    ]
  },
  {
    id: 2,
    routeLink: '',
    icon: 'fas fa-university',
    label: 'Cadastros',
    tipo: 'Pai',
    open: false
  },
  {
    id: 3,
    routeLink: 'pas-listagem',
    icon: 'fas fa-money-check-alt',
    label: 'PAs contratados',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser',
        'superintendencia',
        'gestorregional'
    ]
  },
  {
    id: 4,
    routeLink: 'posto-listagem',
    icon: 'fas fa-store',
    label: 'Posto',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser',
        'superintendencia'
    ]
  },
  {
    id: 5,
    routeLink: 'regiao-listagem',
    icon: 'fas fa-map',
    label: 'Região',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser'
    ]
  },
  {
    id: 6,
    routeLink: 'servico-listagem',
    icon: 'fas fa-cogs',
    label: 'Serviço',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser',
        'superintendencia'
    ]
  },
  {
    id: 7,
    routeLink: 'orgao-listagem',
    icon: 'fas fa-square',
    label: 'Orgão',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser',
        'superintendencia'
    ]
  },
  {
    id: 9,
    routeLink: 'permissao-users',
    icon: 'fas fa-user',
    label: 'Usuários',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser',
        'superintendencia'
    ]
  },
  {
    id: 10,
    routeLink: '',
    icon: 'fal fa-university',
    label: 'Relatórios',
    tipo: 'Pai',
    open: false
  },
  {
    id: 11,
    routeLink: 'relatorios/vagasDisponiveis',
    icon: 'fas fa-calendar',
    label: 'Vagas Disponiveis',
    tipo: 'Filho',
    id_pai:10
  },
  {
    id: 12,
    routeLink: 'relatorios/agendamentoDiasEspera',
    icon: 'fas fa-calendar',
    label: 'Agendamento Dias Espera',
    tipo: 'Filho',
    id_pai:10
  },
  {
    id: 13,
    routeLink: 'relatorios/incidência',
    icon: 'fas fa-calendar',
    label: 'Incidência',
    tipo: 'Filho',
    id_pai:10
  },
  {
    id: 14,
    routeLink: 'indicador-listagem',
    icon: 'fas fa-tachometer-alt',
    label: 'Indicadores',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser'
    ]
  },
  {
    id: 15,
    routeLink: 'listagem-justificativa',
    icon: 'fas fa-tasks',
    label: 'Inclusão de Justificativas',
    tipo: 'Filho',
    id_pai:2
  },
  {
    id: 16,
    routeLink: 'listar-motores',
    icon: 'fas fa-cogs',
    label: 'Motor config',
    tipo: 'Filho',
    id_pai:2,
    roles:[
        'superuser'
    ]
  },
];

import { Component, EventEmitter, Inject, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { IndicadorFilaComponent } from './components/indicador-fila/indicador-fila.component';
import { IndicadorOrgaoComponent } from './components/indicador-orgao/indicador-orgao.component';
import Chart from 'chart.js';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext/inputtext';
import { RouteChangeService } from './utilidades/route-change.service';
import { Title } from '@angular/platform-browser';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './auth/idp.sp/auth.code.flow.config';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from '../environments/environments';
import { MatDialog } from '@angular/material/dialog';
import { AjudaComponent } from './components/ajuda/ajuda.component';
import { LoadingIndicadoresComponent } from './loading-indicadores/loading-indicadores.component';

interface SideNavToggle{
  collapsed: boolean;
  screenWidth: number;
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    //BrowserModule,
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    HeaderComponent,
    HomeComponent,
    SidenavComponent,
    IndicadorOrgaoComponent,
    IndicadorFilaComponent,
    LoadingIndicadoresComponent,
    ButtonModule,
    //InputTextModule

  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  //providers: [provideClientHydration()],
})


export class AppComponent {
  title = 'Sala de Situação - Sistema de Atendimento';
  hideDiv: boolean = false;
  @Output() themeToggle = new EventEmitter<string>();
  public themeClass: string = "";
  isDarkTheme: boolean = false;
  constructor(private titleService: Title, private routeChangeService: RouteChangeService, @Inject(DOCUMENT) private document: Document, private oauthService: OAuthService,
  public dialog: MatDialog) {
    this.routeChangeService.getRouteChange().subscribe(url => {
      this.checkURL(url);
      this.titleService.setTitle(this.title);
    });
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      var NewVisu = localStorage.getItem('MODOTELA');
      this.isDarkTheme = NewVisu == null ? false: NewVisu =="LIGHT"? false : true;
    }
    this.configure();
  }

  checkURL(url: string): void {
    this.hideDiv = (url === '/iniciar' || url === '/loading');
  }


  isSieNavCollapsed = false;
  screenWihdth = 0;
  onToggleSideNav(data: SideNavToggle) {
    this.screenWihdth = data.screenWidth;
    this.isSieNavCollapsed = data.collapsed;
  }

  public toggleTheme(theme: string) {
    console.log("APP", theme);
    if(theme=="LIGHT")
    {
        this.isDarkTheme = false;
        this.themeClass = '';
    }
    else if(theme == "DARK")
    {
        this.isDarkTheme = true;
        this.themeClass = 'dark-theme';
    }
    this.themeToggle.emit(theme);
}
  private configure() {
    this.oauthService.configure(authCodeFlowConfig);
    //this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.loadDiscoveryDocument(environment.well_known);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tryLogin();
  }

  openDialog() {
    this.dialog.open(AjudaComponent, {
        width: '80%',
        height: '80%'
    })
  }

}
function provideClientHydration(): import("@angular/core").Provider {
  throw new Error('Function not implemented.');
}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class IndicadorEspecialService {
  constructor(private http: HttpClient) { }
  private apiUrl = environment.API_URL;
  private map = "ss.especial";

  getindicadores(codigo: number, codigoCentralPosto: string, codigoCentralOrgao: string, variaveis: string, login: string): Observable<any> {
    console.log(variaveis);
    if (variaveis == undefined || variaveis == "") {
      const url = this.apiUrl + this.map + '/getEspecialIndicador?id=' + codigo + '&ccPosto=' + codigoCentralPosto + '&ccOrgao=' + codigoCentralOrgao +'&login='+login+ '&variaveis=';
      return this.http.get(url);
    }
    else {
      const url = this.apiUrl + this.map + '/getEspecialIndicador?id=' + codigo + '&ccPosto=' + codigoCentralPosto + '&ccOrgao=' + codigoCentralOrgao +'&login='+login+ '&variaveis=' + encodeURIComponent(JSON.stringify(variaveis));
      return this.http.get(url);
    }
  }
}

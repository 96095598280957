<section>
  <section style="height: auto;">
    <nav>
      <ol class="cd-breadcrumb custom-separator">
        <table>
          <tbody>
            <tr>
              <td class="nomeTela">
                Inclusão de Justificativa
              </td>
            </tr>
          </tbody>
        </table>
      </ol>
    </nav>
  </section>
  <main>
    <section class="main-section" style="margin: 25px;">
      <form class="form-class" data-toggle="collapse">
        @if(ModalIndicadorAlerta!= null){
        <table class="table">
          <thead>
            <tr>
              <th scope="col" style="cursor: pointer">Posto</th>
              <th scope="col" style="cursor: pointer">Fila</th>
              <th scope="col" style="cursor: pointer">Data de Início / Hora
              </th>
              <th scope="col" style="cursor: pointer">Data Final / Hora</th>
              <th scope="col" style="cursor: pointer">Cidadãos em
                espera</th>
              <th scope="col" style="cursor: pointer">Pontos abertos
              </th>
              <th scope="col" style="cursor: pointer">Tempo total</th>
            </tr>
          </thead>

          <tbody>

            <tr>
              <!-- <td style="font-weight: bold;">{{$index+1}}</td> -->
              <td style="font-weight: bold;">{{ModalIndicadorAlerta.posto?.descricao}}</td>
              <td>{{ModalIndicadorAlerta.descFila}}</td>
              <td>{{dateTimeFormatPipe.transform(ModalIndicadorAlerta.dataInicio)}}</td>
              <td>{{dateTimeFormatPipe.transform(ModalIndicadorAlerta.data_fim)}}</td>
              <td>{{ModalIndicadorAlerta.qtdCidadaoEmEspera}}</td>
              <td>{{ModalIndicadorAlerta.qtdPontosAbertos}}</td>
              <td>{{ timeFormatPipe.transform(ModalIndicadorAlerta.tempo) }}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="MensagemAlert!=''" class="alert alert-warning" role="alert">
          {{MensagemAlert}}
        </div>
        @for (m of ModalIndicadorAlerta.mensagens; track $index) {
        @if(m.tipoMensagem == 1){
        <p>Justificativa - {{m.loginCadastro}} - {{dateTimeFormatPipe.transform(m.dataCadastro)}}</p>
        <textarea class="form-control" style="width: 40%;background-color: #e3e3e3;" readonly>{{m.mensagem}}</textarea>
        }
        @else if(m.tipoMensagem == 2)
        {
        <div style="margin-left: 500px;">
          <p>Solicitação Superintendência - {{m.loginCadastro}} - {{dateTimeFormatPipe.transform(m.dataCadastro)}}</p>
          <textarea class="form-control" style="width: 65%;background-color: #e3e3e3;"
            readonly>{{m.mensagem}}</textarea>
        </div>
        }
        }

        }
        @if(tipomensagem == 1){
        <div [ngClass]="ModoLeituraTipoMen1() ? 'displayNone': '' ">
          <p>Justificativa:</p>
          <textarea class="form-control" style="width: 40%;" id="id" name="id"
            [(ngModel)]="novaMensagem.mensagem"></textarea>
        </div>
        }
        @else if(tipomensagem == 2)
        {
        <div style="margin-left: 500px;">
          <p>Solicitação Superintendência:</p>
          <textarea class="form-control" style="width: 65%;" id="id" name="id"
            [(ngModel)]="novaMensagem.mensagem"></textarea>
        </div>
        }

        <div class="div-button mb-3 p-3">
          <button id="class-button" class="btn btn-secondary"><a
              [routerLink]="['/listagem-justificativa']">Voltar</a></button>

          @if(validarRole() == true){
          <button [ngClass]="ModoLeituraTipoMen2() ? 'displayNone': 'btn btn-success' " *ngIf="tipomensagem == 1" id="class-button" style="margin-right: 10px;"
            (click)="MudarTipoMensagem(TipoMensagemIndicadorAlerta.COBRANCA)">Solicitar
            nova justificativa</button>
          <button *ngIf="tipomensagem == 2" id="class-button" style="margin-right: 10px;"
            (click)="MudarTipoMensagem(TipoMensagemIndicadorAlerta.JUSTIFICATIVA)" class="btn btn-danger">Cancelar
            Solicitação</button>
          <button [ngClass]="ModoLeituraTipoMen2() ? 'displayNone': 'btn btn-primary' "
            id="class-button" style="margin-right: 10px;" (click)="Finalizar()">Finalizar
            argumentos</button>
          }
          <button id="class-button" [ngClass]="ModoLeituraBotaoSalvarTipoMen1() ? 'displayNone': 'btn btn-primary' " (click)="submitForm()">Salvar</button>
        </div>
      </form>
    </section>
  </main>
</section>
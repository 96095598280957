import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    standalone: true,
    template: `
    <h1 mat-dialog-title style="margin: 32px;justify-content: center;    display: flex;">{{ data.title }}</h1>
    <div mat-dialog-content  style="margin: 10px;max-width:400px">
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions style="    display: flex;    justify-content: space-around;">
      <button mat-button class="btncancel btn btn-primary" (click)="onNoClick()">Cancelar</button>
      <button mat-button class="btnconfirm btn btn-danger" (click)="dialogRef.close(true)" cdkFocusInitial>Confirmar</button>
    </div>
  `,
  styleUrl: './confirmdialog.component.scss'
})

export class ConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
    ) { }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

}
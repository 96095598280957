import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-iniciar',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        RouterLink,
        RouterOutlet,
        RouterModule,
        RouterLinkActive,
        MatSidenavModule,
    ],
    templateUrl: './iniciar.component.html',
    styleUrl: './iniciar.component.scss',
})
export class IniciarComponent  implements OnInit  {

    constructor(private router: Router, private oauthService: OAuthService){}

    ngOnInit()
    {
        localStorage.setItem('deslogar', "false");
    }
}

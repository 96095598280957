import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { PostoService } from '../../../../service/posto.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { RegiaoService } from '../../../../service/regiao.service';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-cadastro-listagem-posto',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-listagem-posto.component.html',
  styleUrl: './cadastro-listagem-posto.component.scss'
})

export class CadastroListagemPostoComponent implements OnInit {
  data = [];
  public listaPos: posto[] = [];
  public listaReg: regiao[] = [];
  novoItem: posto = new posto();
  pesquisaDesc: string  = "";
  pesquisaReg: string  = "";
  pesquisaStatus: string  = "";
  pesquisaCodigo: string  = "";
  pesquisaCCentralizado: string  = "";

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  postoEditado:any;
  postoSalvo:any;
  postoExcluido: any;


  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;
  @ViewChild('mySelectFiltroStatus') mySelectFiltroStatus: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private postoService: PostoService,
    private regiaoService: RegiaoService,
    public dialog: MatDialog,
    private authService: AuthService,
    private oauthService: OAuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }

    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
    edicao: boolean
    };
    if(state != null){
    this.postoEditado = state.edicao;
    if(this.postoEditado != null){
        setInterval(() => {
            this.postoEditado = null;
        }, 6000);
    }
    }

    const stateSalvo = navigation?.extras.state as {
    salvar: boolean
    };
    if(stateSalvo != null){
    this.postoSalvo = stateSalvo.salvar;
    if(this.postoSalvo != null){
        setInterval(() => {
            this.postoSalvo = null;
        }, 6000);
    }
    }
  }

  onOptionChange(newValue: string) {
    this.novoItem.regiao = this.listaReg.find(x => x.id == newValue);
    console.log('Novo item', this.novoItem, newValue, this.listaReg);
  }

  onOptionChangeFiltro(newValue: string) {
    this.pesquisaReg = newValue;
  }

  onOptionChangeFiltroStatus(newValue: string) {
    this.pesquisaStatus = newValue;
  }

  ngOnInit() {
    this.regiaoService.getRegiao().pipe(
    ).subscribe((response) => {

      this.data = response["Regiao"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        this.listaReg.push(new regiao(element['id'], element['codigo'], element['descricao']))
      });
      this.listaReg = this.listaReg.sort((a, b) => {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;
      });
    });

    this.novoItem.regiao = this.listaReg[0];

    this.Pesquisa();

  }

  MudaFiltro()
  {
    this.pageIndex =0;
    this.Pesquisa()
  }

  Pesquisa() {
    console.log("STATUSSS",this.pesquisaStatus);
    this.listaPos = [];
    this.postoService.getPosPaginacao(this.pageIndex, this.pageSize,this.pesquisaDesc, this.pesquisaReg, this.pesquisaCodigo, this.pesquisaCCentralizado, this.pesquisaStatus).pipe(
    ).subscribe((response) => {
      this.listaPos = []
      this.data = response["Posto"];
      this.totalPages = response["totalPages"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        let p = new posto(element['id'], element['codigo'], element['descricao'], element['codigoCentralizado']);
        if (element['regiao'] != undefined)
        p.regiao = new regiao(element['regiao']['id'], element['regiao']['codigo'], element['regiao']['descricao']);
        p.inativo = element['inativo'];
        this.listaPos.push(p)

      });
    });
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  excluirItem(id: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmação', message: 'Tem certeza que deseja excluir?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id != undefined) {
          this.postoService.deleteItem(id?.toString()).subscribe(
            (response) => {
              this.authService.atualizarListaPostosERegiaoUsuario();
              console.log('Item excluído com sucesso!');
              this.postoExcluido = true;
              this.Pesquisa();
            },
            (error) => {
              console.error('Erro ao excluir o item:', error);
              this.postoExcluido = false;
            }
          );
        }
      }
    });

    if(this.postoExcluido = true){
        setInterval(() => {
            this.postoSalvo = null;
        }, 6000);
    }

  }

  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.postoService.editarItem(id?.toString()).subscribe(
        (response) => {
          //console.log(response);
          let p = response["Posto"];

          this.novoItem.id = p['id'];
          this.novoItem.codigo = p['codigo'];
          this.novoItem.codigoCentralizado = p['codigoCentralizado'];
          this.novoItem.descricao = p['descricao'];
          if (p['regiao'] != undefined) {
            this.novoItem.regiao = new regiao(p['regiao']['id'], p['regiao']['codigo'], p['regiao']['descricao'])
            this.mySelect.nativeElement.value = '' + p['regiao']['id'];
          }
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }

  isAsc: boolean = false;
  sortTable(column: keyof any){
    var orderBy: string = "";
    var descOrderBy: string = "";

    if(this.isAsc == true){
        orderBy = "asc"
    }else{
        orderBy = "desc"
    }

    descOrderBy = column.toString();
    this.postoService.getPaginacaoOrderBy(this.pageIndex, this.pageSize, orderBy, descOrderBy).pipe(
        ).subscribe((response) => {
          this.listaPos = []
          this.data = response["Posto"];
          this.totalPages = response["totalPages"];
          let i = 0;
          console.log(response);
          this.data.forEach(element => {
            i++;
            let p = new posto(element['id'], element['codigo'], element['descricao'], element['codigoCentralizado']);
            if (element['regiao'] != undefined)
            p.regiao = new regiao(element['regiao']['id'], element['regiao']['codigo'], element['regiao']['descricao']);
            p.inativo = element['inativo'];
            this.listaPos.push(p)

          });
        });
      this.isAsc = !this.isAsc;
  }

  validarRoleEditar(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia' || role == 'gestorregional'){
        return true;
    }
    return false;
  }

  validarRole(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia'){
        return true;
    }
    return false;
  }

}

export class cormodal {
    public status: string;
    public ordenacao: number;
    public fundoCor: string;
    public cabecalhoCor: string;
    public numBorda: number | undefined;

    constructor(status: string, fundoCor: string, cabecalhoCor: string= '', ordenacao: number, numBorda: number | undefined = undefined) {
        this.status = status;
        this.fundoCor = fundoCor;
        this.cabecalhoCor = cabecalhoCor;
        this.numBorda = numBorda;
        this.ordenacao = ordenacao;
      }   
}
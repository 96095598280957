<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Listagem PA Contratados
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        
        <!-- _ Pesquisa _ -->
        <section class="pesq-section">
            <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaNomePonto" class="form-label">Nome Pontos Contratados:</label>
                                    <input type="text" class="form-control"
                                        placeholder="Pesquisa Nome Pontos Contratados" id="pesquisaNomePonto"
                                        name="pesquisaNomePonto" [(ngModel)]="pesquisaNomePonto" required>
                                </div>
                            </td>

                            <td>
                                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                                    <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
                                </div>
                            </td>

                            <td style="position: fixed; bottom: 13px; right: 22px;">
                                <a [routerLink]="['/pas-cadastrados']"><button id="class-button"
                                        style="border-radius: 50%;width: 45px;height: 45px;" class="btn btn-success"><i
                                            class="fas fa-plus"></i></button></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>

        <!--Tabela -->
        <section class="main-section-table">
            <table class="table">
                <thead>
                    <br>
                    <tr>
                        <!-- <th scope="col"> Id  </th> -->
                        <th scope="col" (click)="sortTable('idPosto')" style="cursor: pointer">Código Centralizado</th>
                        <th scope="col" (click)="sortTable('nomePosto')" style="cursor: pointer">Nome dos Postos</th>
                        <th scope="col" (click)="sortTable('quantidadePontos')" style="cursor: pointer">Qtd.Pontos
                            Contratados</th>
                        <th scope="col">Funções</th>
                        <!-- <th scope="col">Exclusão</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of body; let i = index">
                        <!-- <td>{{item.id}}</td> -->
                        <td class="idPosto">{{item.idPosto}}</td>
                        <td>{{item.nomePosto}}</td>
                        <td>{{item.quantidadePontos}}</td>
                        <td>
                            <button class="btn btn-primary" [routerLink]="['/pas-cadastrados']"
                                [queryParams]="{id: item.id}">Editar</button>
                            <!--<button class="btn btn-danger"
                                (click)="DeletePASContratadoItemPorID(item.id)">Excluir</button>-->
                        </td>
                    </tr>
                </tbody>
            </table>
            <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator>
        </section>
    </main>

</section>
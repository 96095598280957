<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                {{NomeTela}}
                            </td>
                            <td>
                                <div *ngIf="atualizando" class="loader loader--style1" title="0">
                                    <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px"
                                        height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40"
                                        xml:space="preserve">
                                        <path opacity="0.2" fill="#000"
                                            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                                    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                                    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
                                        <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                                    C22.32,8.481,24.301,9.057,26.013,10.047z">
                                            <animateTransform attributeType="xml" attributeName="transform"
                                                type="rotate" from="0 20 20" to="360 20 20" dur="0.5s"
                                                repeatCount="indefinite" />
                                        </path>
                                    </svg>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li *ngFor="let bc of listabreadcrumb">
                                    @if (bc.url != "") {
                                    <button (click)="navigateWithStateBC(bc.url)">{{bc.label}}</button>
                                    }
                                    @else {
                                    <em>{{bc.label}}</em>
                                    }
                                </li>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <section class="sectionInd">
        <div class="cards-containers">

            <div *ngFor="let ind of listaSer">
                @if(linkRedirect!= "" && linkRedirect != null){
                <button class="cards-layout-CidEspera" (click)="navigateWithState(linkRedirect,ind.id,ind.nome)">
                    <div>
                        <mat-card class="mat-card" [style.height.px]="maxHeight"
                            [ngStyle]="{'background-color': ind.cor.fundoCor,'width.px': maiorTamanhoTitulo * 8}">
                            <div class="title-card" id="titulo_{{ind.id}}"
                                [ngStyle]="{'background-color': ind.cor.cabecalhoCor}">{{ind.nome}}</div>
                            <div style="display: flex;" id="content_{{ind.id}}">
                                <div class="Ind_icone">
                                    <i [classList]="ind.icone"
                                        style="color: #ffffff;;transform: scale(1.7,1.7); --fa-secondary-opacity:0.7;"></i>
                                </div>
                                <div class="card-content">
                                    <h2>{{ind.valor}}</h2>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </button>
                }
                @else
                {
                <a class="cards-layout-CidEspera">
                    <div>
                        <mat-card class="mat-card" [style.height.px]="maxHeight"
                            [ngStyle]="{'background-color': ind.cor.fundoCor,'width.px': maiorTamanhoTitulo * 8}">
                            <div class="title-card" id="titulo_{{ind.id}}"
                                [ngStyle]="{'background-color': ind.cor.cabecalhoCor}">{{ind.nome}}</div>
                            <div style="display: flex;" id="content_{{ind.id}}">
                                <div class="Ind_icone">
                                    <i [classList]="ind.icone"
                                        style="color: #ffffff;;transform: scale(1.7,1.7); --fa-secondary-opacity:0.7;"></i>
                                </div>
                                <div class="card-content">
                                    <h2>{{ind.valor}}</h2>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </a>
                }
            </div>
        </div>
    </section>

    @if(SemDados)
    {
    <h2 class="class-h2">Não exite dados para serem mostrados</h2>
    }
</section>

<section>
    <div class="container-cards-vagas-disponiveis">
        <div class="card-container-vagasdisponivel">
            @for (item of GraficosUnicosArray; track item.id) {
            <div class="a-link">
                <div class="card" [ngStyle]="{'background-color': item.backGroundColorCSS}">

                    <div class="div-in-card-1">
                        <p style="color: #ffffff;">{{ item.nomeOrgao }}</p>
                        <div *ngIf="atualizando" class="loader loader--style8" title="7">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px"
                                viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s"
                                        dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s"
                                        dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s"
                                        dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2"
                                        begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10"
                                        begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s"
                                        dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2"
                                        begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s"
                                        dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s"
                                        dur="0.6s" repeatCount="indefinite" />
                                </rect>
                            </svg>
                        </div>

                        <p>Vagas disponíveis</p>
                    </div>

                    <p-chart type="bar" [data]="item.chartDatasets" [options]="options"
                        style="padding: 10px;"></p-chart>
                    <p class="p-in-card-3"> {{ item.dataVagasDisponPeriodo }}</p>


                </div>
            </div>
            }
            @for (item of GraficosLoopDiaArray; track item.id) {
            <div class="a-link">
                <div class="card" [ngStyle]="{'background-color': item.backGroundColorCSS}">

                    <div class="div-in-card-1">
                        <p style="color: #ffffff;">{{ item.nomeOrgao }}</p>
                        <div *ngIf="atualizandoGraficos" class="loader loader--style8" title="7">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px"
                                viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s"
                                        dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s"
                                        dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s"
                                        dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2"
                                        begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10"
                                        begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s"
                                        dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2"
                                        begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s"
                                        dur="0.6s" repeatCount="indefinite" />
                                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s"
                                        dur="0.6s" repeatCount="indefinite" />
                                </rect>
                            </svg>
                        </div>

                        <p>{{NomeGrafico}}</p>


                    </div>

                    <p-chart type="bar" [data]="item.chartDatasets" [options]="options"
                        style="padding: 10px;"></p-chart>
                    <p class="p-in-card-3"> {{ item.dataVagasDisponPeriodo }}</p>
                    <div class="div-in-card-2">
                        <p *ngFor="let infos of item.ListainformacoesGraficoGeral" style="color: #fff;">{{infos}} </p>
                    </div>

                </div>
            </div>
            }
        </div>
    </div>

</section>
import { indicadorAlertaPermanencia } from "./indicadorAlertaPermanencia";

export class posto_simple {
    public id: number;
    public descricao: string;
    public indicadorAlertaPermanencia: indicadorAlertaPermanencia[] | undefined;
    constructor(id: number,
      descricao: string = "") {
      this.id = id;
      this.descricao = descricao;
    }
  }
import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
    RouterLink,
    RouterOutlet,
    RouterModule,
    RouterLinkActive,
    ActivatedRoute,
    Router,
} from '@angular/router';
import { ClasseControlePermissaoUsers } from '../../../../modal/ControlePermissaoUsers';
import { HttpClientModule } from '@angular/common/http';
import { ControlePerfilUsersService } from '../../../../service/controle-perfil-users.service';
import { ClasseMeusDados } from '../../../../modal/ControlePermissaoUsers_MeusDados'

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AuthService } from '../../../../service/auth.service';

@Component({
    selector: 'app-controle-perfil-user',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatCardModule,
        MatMenuModule,

        CommonModule,

        RouterLinkActive,
        RouterLink,
        RouterOutlet,
        RouterModule,

        FormsModule,
        ReactiveFormsModule,

        MatPaginatorModule,
        HttpClientModule,
        ButtonModule,
        InputTextModule,
        DropdownModule,
        CalendarModule,
        CheckboxModule,

        MatPaginatorModule,
        NgxMaskDirective,
        TableModule,
        HttpClientModule,
        NgxMaskPipe
    ],
    providers: [provideNgxMask({ /* opções de cfg */ })],
    templateUrl: './controle-perfil-user.component.html',
    styleUrl: './controle-perfil-user.component.scss',
})
export class ControlePerfilUserComponent {

    @ViewChild('mySelect') mySelect: any;
    @ViewChild('mySelectFiltro') mySelectFiltro: any;

    userPerfilControlLista: ClasseControlePermissaoUsers[] = [];
    userPerfilControlListaSelect: ClasseControlePermissaoUsers[] = [];
    novoPerfilUsuario: ClasseControlePermissaoUsers = new ClasseControlePermissaoUsers();
    public data=[];
    public dataSelect=[];

    pageIndex = 0;
    totalPages = 1;
    pageSize = 10;
    pageSizeOptions = [5, 10, 25];
    hidePageSize = false;
    showPageSizeOptions = true;
    showFirstLastButtons = true;
    disabled = false;
    perfilEditado:any;
    perfilSalvo:any;

    public pesquisaNome: string = "";
    public pesquisaCPF: string = "";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        public userPerfilService: ControlePerfilUsersService,
        private authService: AuthService
    ) {
        try {
            let login = localStorage.getItem('login');
            if (login == null || login == undefined)
              this.router.navigate(['/iniciar']);
          }
          catch (ex) {
            this.router.navigate(['/iniciar']);
          }
          const navigation = this.router.getCurrentNavigation();
          const state = navigation?.extras.state as {
            edicao: boolean
          };
          if(state != null){
            this.perfilEditado = state.edicao;
            if(this.perfilEditado != null){
                setInterval(() => {
                    this.perfilEditado = null;
                }, 10000);
            }
          }

          const stateSalvo = navigation?.extras.state as {
            salvar: boolean
          };
          if(stateSalvo != null){
            this.perfilSalvo = stateSalvo.salvar;
            if(this.perfilSalvo != null){
                setInterval(() => {
                    this.perfilSalvo = null;
                }, 10000);
            }
          }
    }

    onOptionChange(newValue: string) {
        this.novoPerfilUsuario.meusDados = this.data.find(x => x == newValue);
        //console.log("Novo Item", this.novoPerfilUsuario, newValue, this.userPerfilControlLista)
    }

    onOptionChangeFiltro(newValue: string) {
        if( newValue != null && newValue != undefined ){
            var cpf = this.userPerfilControlListaSelect.find(x => x.id == newValue)?.cpf;
            if( cpf != undefined  )
                this.pesquisaCPF = cpf;
        }

    }

    ngOnInit() {
        this.userPerfilService.getPerfilUsuariosPAGE().subscribe((response) => {
            this.dataSelect = response["Usuario"]
            //console.log(this.dataSelect)
            if ( this.dataSelect && this.dataSelect !== null && this.dataSelect !== undefined ){
                let i = 0
                this.dataSelect.forEach(element => {
                    i++
                    this.userPerfilControlListaSelect.push(new ClasseControlePermissaoUsers(
                        element["id"],
                        element["nome"],
                        element["cpf"],
                        element["senha"],
                        element["meusDados"] ? element["meusDados"]["perfil"] : undefined
                    ));
                });
            }
        });
        //this.userPerfilControlListaSelect = this.data[0];
        //this.novoPerfilUsuario = this.userPerfilControlLista[0]
        this.Pesquisa();
    }

    MudaFiltro(){
        this.pageIndex =0;
        //console.log("Filtros antes da pesquisa:",  this.pesquisaCPF, this.pesquisaNome);
        this.Pesquisa()
      }

    Pesquisa(){
        this.userPerfilControlLista = [];
        this.userPerfilService.findPaginacaoUsuarios(this.pageIndex, this.pageSize, this.pesquisaNome, this.pesquisaCPF).subscribe(
            (response) => {
                this.data = response["Usuario"];
                this.totalPages = response["totalPages"];

                if( this.data && this.totalPages && this.data != null && this.totalPages != null && this.data != undefined && this.totalPages != undefined ){

                    let i = 0;
                    //console.log(response);
                    this.data.forEach(element => {
                        i++;
                        let p = new ClasseControlePermissaoUsers(
                            element["id"],
                            element["nome"],
                            element["cpf"],
                            element["senha"],
                            element["email"],
                            element["meusDados"] ? new ClasseMeusDados(
                                element['meusDados']['dataCriacao'],
                                element['meusDados']['perfil'],
                                element['meusDados']['roles']
                            ) : undefined
                        );
                        console.table(element['meusDados']['roles']);
                        this.userPerfilControlLista.push(p)
                    });
                }
            }, (error) => {
                console.error("Erro no response da paginação!!! ", error)
            }
        )
    }

    pageEvent: PageEvent | undefined;
    handlePageEvent(e: PageEvent) {
      this.pageEvent = e;
      this.totalPages = e.length;
      this.pageSize = e.pageSize;
      this.pageIndex = e.pageIndex;
      this.Pesquisa();
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
          this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
        }
      }

    abrirEditarItemDeUsuarios( id: string|null ): void{
        if( id != undefined ){
            this.userPerfilService.usuarioFindId(id?.toString()).subscribe(
                (response) => {
                    let p = response["Usuario"]

                    this.novoPerfilUsuario.id = p["id"];
                    this.novoPerfilUsuario.nome = p["nome"];
                    this.novoPerfilUsuario.cpf = p["cpf"];
                    this.novoPerfilUsuario.senha = p["senha"];
                    this.novoPerfilUsuario.meusDados = p["meusDados"]["perfil"];

                    if( p["meusDados"] != undefined){
                        console.table(p["meusDados"]["roles"]);
                        this.novoPerfilUsuario.meusDados = new ClasseMeusDados(p["meusDados"]["dataCriacao"], p["meusDados"]["perfil"], p["meusDados"]["roles"])
                        this.mySelect.nativeElement.value = '' + p["meusDados"]["cpf"];
                    }
                }, (error) => {
                    console.error( "Erro ao excluir o item: ", error )
                }
            )
        }
    }


    validarRole(){
        let role = this.authService.role();
        if(role == 'superuser'){
            return true;
        }
        return false;
      }


}

import { valor } from "../valor";

export class indicadorDTO {
    codigo: number | null ;
    cor: string;
    etapa: number;
    icone: string;
    corFarol: string;
    nome: string;
    ordenacaoOrientacao: string;
    proximaEtapa: number | null;
    valor: valor;
    valores?: valor[];
    listaComponentesGraficos?: valor[];
    linkRedirect: string;
    constructor(codigo: number | null = null, 
        nome: string,
        cor: string, 
        etapa: number, 
        icone: string, 
        corFarol: string, 
        ordenacaoOrientacao: string,
        proximaEtapa: number | null,
        valor: valor,
        valores: valor[] = [],
        listaComponentesGraficos: valor[] = [],
        linkRedirect: string) {
        this.codigo = codigo;
        this.nome = nome;
        this.cor = cor;
        this.icone = icone;
        this.etapa = etapa;
        this.corFarol = corFarol;
        this.ordenacaoOrientacao = ordenacaoOrientacao;
        this.proximaEtapa = proximaEtapa;
        this.valores = valores;
        this.valor = valor;
        this.listaComponentesGraficos = listaComponentesGraficos;
        this.linkRedirect = linkRedirect;
      }
}
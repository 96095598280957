// route-change.service.ts

import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteChangeService {
  private routeChangeSubject = new Subject<string>();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routeChangeSubject.next(event.url);
      }
    });
  }

  getRouteChange() {
    return this.routeChangeSubject.asObservable();
  }

}

export class ICONE {
    public getIcone(IdTela : number = 0) : string
    {
        switch(IdTela)
       {
        case 1: //Cidadão na espera x
          return "fad fa-user-clock";
          break;
        case 2: //Cidadãos atendidos x
            return "fad fa-users";
          break;
          case 3: //PA's ativos(%) 1782/2038 x
            return "fal fa-tv";
            break;
          case 4: //% espera <15 min x 
            return "fal fa-clock";
            break;
          case 5: //"% espera <15 min (acumulado) x
            return "fal fa-alarm-plus";
            break;
          case 6: //Tempo Médio de atend(min) x
            return "fas fa-hourglass-half";
            break;
          case 7: //"Preenchimento da grade x
            return "fal fa-th";
            break;
          case 8: //Indice de não comparecimento x
            return "fad fa-user-slash"; 
            break;
          case 9: //Previsão de espera(min) x
            return "fad fa-stopwatch";
            break;
          case 10: //"Avaliação mesa (ótimo + bom) x
            return "fal fa-clipboard-user";
            break;
          case 11: //% Pontos Ativos Média 
            return "fal fa-balance-scale";
            break;
          case 12: //% Hora Produtiva
            return "fal fa-business-time";
            break;
          case 13: //Atendentes Logados x
            return "fad fa-poll-people";
            break;
          case 14: //Contigência x
            return "fal fa-bell-plus"; 
            break;
       }    
       return "";
    }
}

export interface IPAsContratados{
    id: string;
    idPosto: number;
    nomePosto: string;
    quantidadePontos: number;
    dataCriacao: string;
}

export class PAsContratados{
    public id: string | null;
    public idPosto: number | undefined;
    public nomePosto: string;
    public quantidadePontos: number | undefined;
    public dataCriacao: string;

    constructor(
        id: string | null = null,
        idPosto: number | undefined = 0,
        nomePosto: string = "",
        quantidadePontos: number | undefined = 0,
        dataCriacao: string = "" ){

        this.id = id;
        this.idPosto = idPosto;
        this.nomePosto = nomePosto;
        this.quantidadePontos = quantidadePontos;
        this.dataCriacao = dataCriacao;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { indicador } from '../modal/Indicador';

@Injectable({
  providedIn: 'root',
})
export class AlarmeService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = this.apiUrl+"ss.alarme";
  getAlertas(postos: number[] | undefined, login: string): Observable<any> {
    const headers = new HttpHeaders({'Content-Type':'application/json;'});
    return this.http.post(this.map+'/getAlertas?login='+login, {params: postos}, {withCredentials: true});
  }
}

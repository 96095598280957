import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { IndicadorPostoComponent } from '../indicador-posto/indicador-posto.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { boxInfo } from '../../modal/boxInfo';
import { VagasDisponiveisComponent } from '../vagas-disponiveis/vagas-disponiveis.component';
import { IndicadorService } from '../../service/Indicador.service';
import { delay, retry } from 'rxjs';
import { CORES } from '../../modal/mock-cor';
import { ICONE } from '../../modal/icone';
import { farolmodal } from '../../modal/farolmodal';
import { TamanhoTituloService } from '../../cache/tamanho-indicador';
import { ClasseSalaSituacao } from '../../modal/ControlePermissaoUsers_SalaSituacao';
import { AuthService } from '../../service/auth.service';
import { breadcrumb } from '../../modal/breadcrumb';
import { AtualizacaoIndicador } from '../../modal/AtualizacaoIndicador';
import { SharedDataService } from '../../service/SharedDataService ';
import { LoadingIndicadoresService } from '../../loading-indicadores/loading-indicadores.service';

@Component({
    selector: 'app-indicador-dashboard',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatCardModule,
        MatMenuModule,
        CommonModule,
        RouterLink,
        RouterOutlet,
        RouterModule,
        IndicadorPostoComponent,
        VagasDisponiveisComponent
    ],
    providers: [ICONE],
    templateUrl: './indicador-dashboard.component.html',
    styleUrl: './indicador-dashboard.component.scss'
})
export class IndicadorDashboardComponent implements OnInit, OnDestroy {
    public listaInd: boxInfo[] = [];
    public listaAtualizacaoIndicador: AtualizacaoIndicador[] = [];
    data = [];
    intervalIds: any[] = [];
    cores = CORES;
    maiorTamanhoTitulo: number = 0;
    perfil: any;
    listabreadcrumb: breadcrumb[] = [];
    private postosUsuario: number[] | undefined;
    receivedCcPosto!: string;
    receivedDescPosto!: string;
    public CarregandoTodosIndicadores = false;
    isFixed: boolean = false;
    @ViewChild('menu', { static: true }) menu!: ElementRef;
    initialTopPosition: number = 0;

    constructor(private indicadorService: IndicadorService,
        private icon: ICONE,
        private router: Router,
        private tamanhoTituloService: TamanhoTituloService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private sharedDataService: SharedDataService,
        private loadingService: LoadingIndicadoresService,
        private el: ElementRef) {
        try {
            (async () => {
                await new Promise(f => setTimeout(f, 1000));
                let login = localStorage.getItem('login');
                if (login == null || login == undefined) {
                    this.router.navigate(['/iniciar']);
                }
            })();
        }
        catch (ex) {
            console.log('IndicadorDashboardComponent.constructor catch');
            console.log(ex);
        }
        this.listabreadcrumb = [
            new breadcrumb("Dashboard", "/dashboard")
        ];
    }

    ngAfterViewInit() {
        this.initialTopPosition = this.menu.nativeElement.getBoundingClientRect().top + window.scrollY;
      }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const currentScrollPosition = window.scrollY;
    
        if (currentScrollPosition >= this.initialTopPosition) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      }

    tirarFiltroPosto() {
        this.sharedDataService.updateDescPostoSource("");
        this.sharedDataService.updateCcPosto("");
    }

    ngOnInit() {
        this.sharedDataService.descPosto$.subscribe(string => {
            localStorage.setItem('receivedDescPosto', string);
            this.receivedDescPosto = string;
        });
        this.sharedDataService.ccPosto$.subscribe(string => {
            if (string != this.receivedCcPosto) {
                this.loadingService.show();
                localStorage.setItem('receivedCcPosto', string);
                this.listaInd = [];
                this.intervalIds.forEach(intervalId => clearInterval(intervalId));
                this.receivedCcPosto = string;
                this.GetIndicadores();

            }
        });
        var _receivedDescPosto = localStorage.getItem('receivedDescPosto');
        if (_receivedDescPosto != null)
            this.receivedDescPosto = _receivedDescPosto;
        var _receivedCcPosto = localStorage.getItem('receivedCcPosto');
        if (_receivedCcPosto != null)
            this.receivedCcPosto = _receivedCcPosto;

        this.CarregandoTodosIndicadores = true;
        (async () => {
            await new Promise(f => setTimeout(f, 500));
            this.GetIndicadores();
        })();
    }

    public GetIndicadores() {
        let login = localStorage.getItem('login');
        this.perfil = this.authService.role();

        console.log("LOAD DASHBOARD")
        var retrievedObject = localStorage.getItem('salaSituacao');
        if (retrievedObject != null) {
            var sala = JSON.parse(retrievedObject);
            console.log(retrievedObject);
            //console.table(sala['postos']);
            this.postosUsuario = sala['postos'];
            this.indicadorService.findIndicadores(sala['postos'], login == undefined ? "" : login, this.receivedCcPosto).pipe(
            ).subscribe((response) => {
                this.data = response["indicadores"];
                let i = 0;
                console.log(this.data);
                this.listaAtualizacaoIndicador = [];
                this.data.forEach(element => {
                    i++;
                    var A = this.listaAtualizacaoIndicador.find(x => x.tempoAtualizacao == element['tempoAtualizacao']);
                    if (A == undefined) {
                        this.listaAtualizacaoIndicador.push(new AtualizacaoIndicador("" + element['codigo'], element['tempoAtualizacao']))
                    }
                    else {
                        //console.log(A);
                        A.codigoIndicadores = A.codigoIndicadores + ", " + element['codigo'];
                    }
                    this.listaInd.push(new boxInfo(element['codigo'], element['nome'], element['linkRedirect'], this.cores.find(x => x.status == element['cor']),
                        element['valor']['valorFormatado'], undefined, element['icone'], this.cores.find(x => x.status == element['corFarol'])))
                });
                //this.listaInd.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
                this.maiorTamanhoTitulo = Math.max(... this.listaInd.map(i => i.nome.length));
                this.tamanhoTituloService.setMaiorTamanhoTitulo(this.maiorTamanhoTitulo);
                this.CarregandoTodosIndicadores = false;
                this.loadingService.hide("CarregaInd");
                this.listaAtualizacaoIndicador.forEach(element => {
                    this.setAtualizacaoindicadores(element.tempoAtualizacao, element.codigoIndicadores, element.codigoIndicadores.split(','), login == undefined ? "" : login);
                });

                this.listaInd.forEach(element => {
                    this.setFarol(element.id, login == undefined ? "" : login);
                });
            });
        }

    }

    ngOnDestroy() {
        this.intervalIds.forEach(intervalId => clearInterval(intervalId));
    }

    setFarol(CodigoIndicador: string, login: string) {
        console.log("CAHAMDA setFarol: " + CodigoIndicador)
        var oldind = this.listaInd.find(x => x.id == CodigoIndicador);
        if (oldind != undefined) {
            oldind.atualizando = true;
            this.indicadorService.GetFarol(this.postosUsuario, CodigoIndicador, login, this.receivedCcPosto).pipe(
            ).subscribe((response) => {
                if (oldind != undefined) {
                    oldind.corfarol = this.cores.find(x => x.status == response["indicador_cor_farol"]);
                    oldind.atualizando = false;
                }
            });

        }
    }

    setAtualizacaoindicadores(timeInterval: number, CodigoIndicador: string, CodigosIndicador: string[], login: string) {

        const intervalId = setInterval(() => {
            CodigosIndicador.forEach(element => {
                var i = this.listaInd.find(x => x.id == element.trim())
                if (i != undefined)
                    i.atualizando = true;
            });
            this.indicadorService.AtualizaIndicador(this.postosUsuario, CodigoIndicador, login, this.receivedCcPosto).pipe(
            ).subscribe((response) => {
                this.data = response["indicadores"];
                let i = 0;
                this.data.forEach(element => {
                    var newind = new boxInfo(element['codigo'], element['nome'], element['linkRedirect'], this.cores.find(x => x.status == element['cor']),
                        element['valor']['valorFormatado'], undefined, element['icone'], this.cores.find(x => x.status == element['corFarol']));
                    var oldind = this.listaInd.find(x => x.id == element['codigo']);
                    if (oldind != undefined) {
                        oldind.valor = newind.valor;
                        oldind.cor = newind.cor;
                        oldind.nome = newind.nome;
                        oldind.icone = newind.icone;
                        oldind.corfarol = newind.corfarol;
                    }
                });

                CodigosIndicador.forEach(element => {
                    var i = this.listaInd.find(x => x.id == element.trim())
                    if (i != undefined)
                        i.atualizando = false;
                });
            });
        }, (timeInterval * 1000));
        this.intervalIds.push(intervalId);
    }

    navigateWithState(Link: string | null, IdTela: string, valor: string) {
        if (this.receivedCcPosto != "") {
            this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
            var textInd = this.extractMatches(valor);
            window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
            this.router.navigate(['' + Link], {
                queryParams: { tipoTela: IdTela, CcPosto: this.receivedCcPosto, textInd: textInd, textPos: textInd }
            });
        }
        else {
            var textInd = this.extractMatches(valor);
            this.router.navigate(['' + Link], {
                queryParams: { tipoTela: IdTela, textInd: textInd }
            });
        }
    }

    navigateWithStateOrgao(Link: string | null, IdTela: string, valor: string) {
        var textInd = this.extractMatches(valor);
        var CcPosto: string;
        var sala: any = localStorage.getItem('salaSituacao');
        sala = JSON.parse(sala);
        var posto = sala.postos;
        CcPosto = posto.toString();
        this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url;
        var textPos = this.extractMatches(valor);
        this.router.navigate(['' + Link], {
            queryParams: { tipoTela: IdTela, CcPosto: CcPosto, textInd: textInd, textPos: textPos }
        });
    }

    extractMatches(valor: string): string {
        const regex = /\s\d+\/\d+/g;
        const matches = valor.match(regex);
        if (matches) {
            return " " + matches.join(' ').trim();
        }
        return "";
    }

}

export class Campos {
    public nome: string;
    public icone: string;
    public campoCalculo: string;
    public tipoResultado: number | undefined = undefined;
    public ordem: number;
    public loopingPai: string | undefined;
    public naoMostraCampo: boolean| undefined;
    public naoMostraTitulo: boolean| undefined;
    public ehComponenteGrafico: boolean| undefined;
    public ehCampoCabecalho: boolean| undefined;
    public componenteGraficoCss: string | undefined;
    public componenteGraficoHtml: string | undefined;
    public componenteGraficoPossicao: string | undefined;
    public orientacaoLoop: string;
    public ehLink: boolean| undefined;
    public linkRedirect: string | undefined;
    public linkVariaveis: string | undefined;
    public tipoValorGrafico: number | undefined;
    public nomeConsultaRefentente: string | undefined;
    public ehOcultoPadrao: boolean | undefined = undefined;
    public ehCampoOrdenacao: boolean | undefined = undefined;
    constructor(
        nome: string = "",
        campoCalculo: string = "",
        tipoResultado: number | undefined = undefined,
        ordem: number = 0,
        loopingPai: string| undefined = undefined,
        icone = "",
        naoMostraCampo :boolean| undefined,
        naoMostraTitulo :boolean| undefined,
        orientacaoLoop : string = "",
        ehComponenteGrafico :boolean| undefined =undefined,
        componenteGraficoHtml : string | undefined =undefined,
        componenteGraficoCss: string | undefined=undefined,
        componenteGraficoPossicao : string | undefined=undefined,
        ehLink :boolean| undefined =undefined,
        linkRedirect : string | undefined =undefined,
        linkVariaveis: string | undefined=undefined,
        ehCampoCabecalho:boolean| undefined = undefined,
        tipoValorGrafico: number | undefined = undefined,
        nomeConsultaRefentente: string | undefined = undefined,
        ehOcultoPadrao:boolean| undefined = undefined,
        ehCampoOrdenacao:boolean| undefined = undefined,
        ) {
      this.nome = nome;
      this.campoCalculo = campoCalculo;
      this.tipoResultado = tipoResultado;
      this.ordem = ordem;
      this.loopingPai = loopingPai;
      this.icone = icone;
      this.naoMostraCampo = naoMostraCampo;
      this.naoMostraTitulo = naoMostraTitulo;
      this.orientacaoLoop = orientacaoLoop;
      this.ehComponenteGrafico = ehComponenteGrafico;
      this.componenteGraficoCss = componenteGraficoCss;
      this.componenteGraficoHtml = componenteGraficoHtml;
      this.componenteGraficoPossicao = componenteGraficoPossicao;
      this.ehLink = ehLink;
      this.linkRedirect = linkRedirect;
      this.linkVariaveis = linkVariaveis;
      this.ehCampoCabecalho = ehCampoCabecalho;
      this.tipoValorGrafico = tipoValorGrafico;
      this.nomeConsultaRefentente = nomeConsultaRefentente;
      this.ehOcultoPadrao = ehOcultoPadrao;
      this.ehCampoOrdenacao = ehCampoOrdenacao;
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { indicador } from '../modal/Indicador';

@Injectable({
  providedIn: 'root',
})
export class MotorService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL_MOTOR;
  getVersao(): Observable<any> {
    const headers = new HttpHeaders({'Content-Type':'application/json;'});
    return this.http.get(this.apiUrl+"ss.versao"+'/getVersao');
  }
}

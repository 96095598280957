import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { regiao } from '../modal/regional/regiao';

@Injectable({
  providedIn: 'root',
})
export class RegiaoService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.regiao";

  getRegiao(): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
  }

  getRegiaoUsuario(usuario: any): Observable<any> {
    return this.http.post(this.apiUrl+this.map+'/find', usuario, {withCredentials: true});
  }

  getRegPaginacao(pageNumber: number, pageSize: number, OrdemOrdenacao: string): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&ordemOrdenacao='+OrdemOrdenacao, {withCredentials: true});
  }

  adicionarItem(item: regiao): Observable<regiao> {
    return this.http.post<regiao>(this.apiUrl+this.map+'/cadastro', item, {withCredentials: true});
  }

  deleteItem(id: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.map}/deletar/${id}`);
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findbyId/${id}`);
  }
}

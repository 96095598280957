export class mensagem {
    public id: number;
    public mensagem: string;
    public tipoMensagem: number;
    public loginCadastro: string;
    public dataCadastro: number;
    constructor(id: number,
        mensagem: string = "",
        tipoMensagem: number,
        loginCadastro:string,
        dataCadastro: number) {
      this.id = id;
      this.mensagem = mensagem;
      this.tipoMensagem = tipoMensagem;
      this.loginCadastro = loginCadastro;
      this.dataCadastro = dataCadastro;
    }
  }

import { FormsModule } from "@angular/forms";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOption, MatSelect, MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-multiple-selection-select-all',
    standalone: true,
    imports: [
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        MatToolbarModule,
        MatInputModule,
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    templateUrl: './multiple-selection-select-all.component.html',
    styleUrl: './multiple-selection-select-all.component.scss',

})
export class MultipleSelectionSelectAllComponent implements OnInit, OnChanges {
    @ViewChild('matRef') matRef: MatSelect | undefined;
    @Input() lista: any[] = []; // Recebe a lista do componente pai
    @Input() regiaoSelecionada: string | undefined;
    @Input() width: number = 0;
    @Output() infoClicada = new EventEmitter<any>(); // Emite eventos para o pai
    public PostosSelected: any;
    selectedStates = this.lista;


    onKey(value: any) {
        if (value == null) {
            this.selectedStates = this.lista;
        }
        else
            this.selectedStates = this.search(value);
    }

    ngOnInit() {
        console.log(this.lista);
        this.selectedStates = this.lista;
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['regiaoSelecionada']) {
            this.tratarMudancaValor(changes['regiaoSelecionada'].currentValue);
        }
    }

    isPostoDisabled(posto: any): boolean {
        return !this.selectedStates.some(selectedPosto => selectedPosto.codigoCentralizado === posto.codigoCentralizado);
      }

    tratarMudancaValor(valor: string) {
        this.PostosSelected=[];
        this.lista.forEach(element => {
            if(element.regiao.id == valor)
                this.PostosSelected.push(element.codigoCentralizado);
        });
        this.infoClicada.emit(this.PostosSelected);
      }

    clear() {
        if (this.matRef != undefined)
            this.matRef.options.forEach((data: MatOption) => data.deselect());
    }

    changeClient(value: any) {
        this.infoClicada.emit(value);
    }

    search(value: string) {
        console.log(this.lista);
        let filter = value.toLowerCase();

        return this.lista.filter(option =>
            option.descricao.toLowerCase().includes(filter)
        );
    }

}
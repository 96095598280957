import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private DescPostoSource = new Subject<string>();
  private CcPostoSource = new Subject<string>();

  descPosto$ = this.DescPostoSource.asObservable();
  ccPosto$ = this.CcPostoSource.asObservable();

  updateDescPostoSource(descPosto: string) {
    this.DescPostoSource.next(descPosto);
  }

  updateCcPosto(ccPosto: string) {
    this.CcPostoSource.next(ccPosto);
  }
}
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private oauthService: OAuthService) {
    console.log('intercept constructor!!!!!!!!!!!');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercept');
    // add authorization header with jwt token if available
    if (this.oauthService.hasValidAccessToken()) {
      console.log('intercept token: ' + this.oauthService.getAccessToken());
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      });
    } else {
      console.log('intercept else');
      request = request.clone({
        setHeaders: {
          Authorization: ``
        }
      });
    }
    return next.handle(request);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EditorConfigService {
  constructor() {}

  getEditorConfig() {
    return {
      toolbar: [
        ['bold', 'italic', 'underline'],
        //['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        //[{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        // [{ direction: 'rtl' }],
        // [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [
          {
            font: [
              'Arial, Helvetica, sans-serif',
              'Times New Roman, Times, serif',
              'Courier New, Courier, monospace',
            ],
          },
        ],
        // [{ 'align': [] }],
        //['clean'],
        ['link'],
      ],
    };
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-versionamento',
    standalone: true,
    template: `
    <h3 mat-dialog-title style="margin: 15px;justify-content: center;    display: flex;">{{ data.title }}</h3>
    <hr>
    <div mat-dialog-content  style="margin: 10px;width:400px">
      <p style="margin-left: 30px;">frontend: {{ data.versaofront }}</p>  
      <p style="margin-left: 30px;">backend: {{ data.versaoback }}</p>
      <p style="margin-left: 30px;">motor: {{ data.versaomotor }}</p>
    </div>
    <div mat-dialog-actions style="    display: flex;    justify-content: space-around;">
      <button mat-button class="btncancel btn btn-primary" style="width: 90%;" (click)="onNoClick()">OK</button>
    </div>
  `,
  styleUrl: './pop-up-versionamento.component.scss'
})

export class VersionamentoComponent {
    constructor(
        public dialogRef: MatDialogRef<VersionamentoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string; versaoback: string, versaofront: string, versaomotor: string  }
    ) { }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

}
import { CommonModule, formatDate, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  RouterLink,
  RouterOutlet,
  RouterModule,
  RouterLinkActive,
  Router,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { VagasDisponiveisModel } from '../../modal/VagasDisponiveisModel';
import { ChartModule } from 'primeng/chart'
import { IndicadorService } from '../../service/Indicador.service';
import { indicadorDTO } from '../../modal/dto/indicadorDTO';
import { valor } from '../../modal/valor';
import Chart from 'chart.js';
import { CORES } from '../../modal/mock-cor';
import { historicoVagasDTO } from '../../modal/dto/historicoVagasDTO';
import { AuthService } from '../../service/auth.service';
import localePt from '@angular/common/locales/pt';
import { SharedDataService } from '../../service/SharedDataService ';
import { LoadingIndicadoresService } from '../../loading-indicadores/loading-indicadores.service';
import { CadastroAdicionarOrgaoComponent } from '../cadastros/orgao/adicionar/cadastro-adicionar-orgao.component';
import { breadcrumb } from '../../modal/breadcrumb';

registerLocaleData(localePt); // Registra o locale 'pt-BR'

@Component({
  selector: 'app-vagas-disponiveis',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    RouterLinkActive,
    MatTooltipModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    FontAwesomeModule,
    MDBBootstrapModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatCardModule,
    MatTableModule,
    ChartModule
  ],
  templateUrl: './vagas-disponiveis.component.html',
  styleUrl: './vagas-disponiveis.component.scss',
})
export class VagasDisponiveisComponent implements OnInit, OnDestroy {

  public vagasDisponiveisArray: VagasDisponiveisModel[] = [];
  chartColors: any = '#2a505f';
  cores = CORES;
  dataRet = []
  public listaInd: indicadorDTO[] = [];
  dataCampos = []
  //Primeng
  listahitorico: historicoVagasDTO[] = [];
  data = [];
  options: any;
  public atualizando: boolean = false;
  tempoAtualizacao: any;
  intervalIds: any[] = [];
  perfil: any;
  private postosUsuario: number[] | undefined;
  receivedCcPosto!: string;
  receivedDescPosto!: string;
  public CarregandoTodosIndicadores = false;
  listabreadcrumb: breadcrumb[] = [];
  constructor(private indicadorService: IndicadorService,
    private sharedDataService: SharedDataService, private router: Router, private authService: AuthService, private loadingService: LoadingIndicadoresService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
    this.listabreadcrumb = [
      new breadcrumb("Dashboard", "/dashboard")
  ];
  }

  ngOnInit() {
    this.sharedDataService.descPosto$.subscribe(string => {
      localStorage.setItem('receivedDescPosto', string);
      this.receivedDescPosto = string;
    });
    this.sharedDataService.ccPosto$.subscribe(string => {
      if (string != this.receivedCcPosto) {
        this.loadingService.show();
        this.listaInd = [];
        this.vagasDisponiveisArray = [];
        localStorage.setItem('receivedCcPosto', string);
        this.listaInd = [];
        this.intervalIds.forEach(intervalId => clearInterval(intervalId));
        this.receivedCcPosto = string;
        this.getVagas();

      }
    });
    var _receivedDescPosto = localStorage.getItem('receivedDescPosto');
    if (_receivedDescPosto != null)
      this.receivedDescPosto = _receivedDescPosto;
    var _receivedCcPosto = localStorage.getItem('receivedCcPosto');
    if (_receivedCcPosto != null)
      this.receivedCcPosto = _receivedCcPosto;

    this.CarregandoTodosIndicadores = true;
    (async () => {
      await new Promise(f => setTimeout(f, 500));
      this.getVagas();
    })();

  }

  getVagas() {

    const cores = {
      blueGray800: '#37474f',
      azul500: '#5886ac',
      textColor: '#102a43',
      textColorSecondary: '#292222',
      colorGrid: '#858486'
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 1.3,
      responsive: true,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: true,
        },
        legend: {
          labels: {
            color: cores.textColor,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: cores.textColorSecondary
          },
          grid: {
            color: cores.colorGrid,
            drawBorder: false
          }
        },
        y: [{
          stacked: true,
          ticks: {
            color: cores.textColorSecondary,
            callback: function (value: number, index: number, values: number[]) {
              const additionalInfoIndex = values.length - 1; // Índice da série "Additional Info"
              if (index === additionalInfoIndex) return ''; // Retorna uma string vazia para excluir o valor da série "Additional Info"
              return value.toString();
            }
          },
          grid: {
            color: cores.colorGrid,
            drawBorder: false
          }
        }],
      },
    };


    this.perfil = this.authService.role();
    var retrievedObject = localStorage.getItem('salaSituacao');
    let login = localStorage.getItem('login');
    var retrievedObject = localStorage.getItem('salaSituacao');
    if (retrievedObject != null) {
      var sala = JSON.parse(retrievedObject);
      //console.table(sala['postos']);
      this.postosUsuario = sala['postos'];
      this.indicadorService.findGraficoTelaInteira("orgao", 1, 2, 0, undefined, login == undefined ? "" : login, this.postosUsuario, this.receivedCcPosto).pipe(
      ).subscribe((response) => {
        this.data = response;
        this.tempoAtualizacao = response["tempoAtualizacao"];
        console.log(this.data);
        this.data.forEach(res => {
          this.tempoAtualizacao = res["tempoAtualizacao"];
          this.listaInd = [];
          this.dataCampos = res["camposGrafico"];
          this.dataRet = res["indicadores"];
          var link = res["linkRedirect"];
          let i = 0;
          this.dataRet.forEach(element => {
            var v = element['valor'];
            var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null
              , new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
            var val = new valor(
              v['codigo'],
              v['nome'],
              v['expressao'],
              v['icone'],
              v['denominador'],
              v['nominador'],
              v['valorFormatado'],
              v['valorSemFormato'],
              v['naoMostraCampo'],
              v['naoMostraTitulo'],
              v['orientacaoLoop'],
              v['ehLooping'],
              v['ehComponenteGrafico'],
              v['componenteGraficoHtml'],
              v['componenteGraficoCss'],
              v['componenteGraficoPossicao'],
              v['ehLink'],
              link,
              v['linkVariaveis'],
              v['ordem'],
              v['ehCampoCabecalho'],
              this.getValores(v['valores'], ind),
              v['cor']
            )
            ind = new indicadorDTO(
              element['codigo'],
              element['nome'],
              element['cor'],
              element['etapa'],
              element['icone'],
              element['corFarol'],
              element['ordenacaoOrientacao'],
              element['proximaEtapa'],
              val,
              [], [],
              link
            );
            ind.valores = this.getValores(element['valores'], ind)
            this.listaInd.push(ind);
          });
          var titulo = '';
          var EixoHorizontal = "";
          var Legenda1 = "";
          var Legenda2 = "";
          this.dataCampos.forEach(element => {
            if (element["tipoValorGrafico"] == 1)
              titulo = element["nome"];
            if (element["tipoValorGrafico"] == 3)
              EixoHorizontal = element["nome"];
            if (element["tipoValorGrafico"] == 4)
              Legenda1 = element["nome"];
            if (element["tipoValorGrafico"] == 5)
              Legenda2 = element["nome"];
          });
          var data = formatDate(new Date(), 'dd/MM/yyyy', 'en');
          if (res["nomeTipoTela"] == "Visão Geral") {
            this.listaInd = this.listaInd.sort((a, b) => {
              if (parseInt(a.valor.valorFormatado, 10) < parseInt(b.valor.valorFormatado, 10)) return -1;
              if (parseInt(a.valor.valorFormatado, 10) > parseInt(b.valor.valorFormatado, 10)) return 1;
              return 0;
            });
          }
          this.listaInd.forEach(element => {
            var valorGrafico: any = {
              labels: [],
              datasets: [
                {
                  type: 'bar',
                  label: '' + Legenda1,
                  backgroundColor: cores.blueGray800,
                  data: [],
                },
                {
                  type: 'bar',
                  label: '' + Legenda2,
                  data: [],
                  backgroundColor: cores.azul500,// Cor de fundo transparente
                },
              ],
            };
            if (res["nomeTipoTela"] != "Visão Geral") {
              var DadosHoje = this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
              var VagasAbertasHoje = DadosHoje?.valores.find(x => x.nome == "vagas abertas")
              var VagasTotalHoje = DadosHoje?.valores?.find(x => x.nome == "Total de vagas")
              var VagasAgendadasHoje = DadosHoje?.valores?.find(x => x.nome == "Vagas agendadas")
              var VagasUtilizadasHoje = DadosHoje?.valores.find(x => x.nome == "VAGAS UTILIZADAS")

              var findCor = this.cores.find(x => x.status == element.cor);
              var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;
              var vagas = new VagasDisponiveisModel(
                1, element.valor.valorFormatado, data, cor, element.cor,
                VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato,
                VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato,
                VagasUtilizadasHoje?.valorSemFormato == undefined || isNaN(parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()))
                  ? "0%"
                  : parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) + "%",
                VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato,
                'bar', valorGrafico, element.codigo == null ? 0 : element.codigo, element.valor.valorSemFormato + "", link, element
              )
              this.vagasDisponiveisArray.push(vagas);
            }

            else {
              var VagaVpGeral = this.vagasDisponiveisArray.find(x => x.nomeOrgao == "Visão Geral")
              if (VagaVpGeral == null) {
                var VagasAbertasHoje: valor | undefined;
                var VagasTotalHoje: valor | undefined;
                var VagasAgendadasHoje: valor | undefined;
                var VagasUtilizadasHoje: valor | undefined;

                if (this.isToday(element.valor.valorFormatado)) {
                  console.log("HOJE", element)
                  VagasAbertasHoje = element?.valores?.find(x => x.nome == "vagas abertas")
                  VagasTotalHoje = element?.valores?.find(x => x.nome == "Total de vagas")
                  VagasAgendadasHoje = element?.valores?.find(x => x.nome == "Vagas agendadas")
                  VagasUtilizadasHoje = element?.valores?.find(x => x.nome == "VAGAS UTILIZADAS")

                }
                var findCor = this.cores.find(x => x.status == element.cor);
                var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;

                // Convertendo o valor formatado em um número


                this.setValoresVPGeral(element.valor, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
                this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
                var vagas = new VagasDisponiveisModel(
                  1, res["nomeTipoTela"], data, cor, element.cor,
                  VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato,
                  VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato,
                  VagasUtilizadasHoje?.valorSemFormato == undefined || isNaN(parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()))
                    ? "0%"
                    : parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) + "%",
                  VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato,
                  'bar', valorGrafico, element.codigo == null ? 0 : element.codigo, "", link, element
                )

                this.vagasDisponiveisArray.push(vagas)
              }
              else {
                var VagasAbertasHoje: valor | undefined;
                var VagasTotalHoje: valor | undefined;
                var VagasAgendadasHoje: valor | undefined;
                var VagasUtilizadasHoje: valor | undefined;
                if (this.isToday(element.valor.valorFormatado)) {
                  console.log("HOJE", element)
                  VagasAbertasHoje = element?.valores?.find(x => x.nome == "vagas abertas");
                  VagasTotalHoje = element?.valores?.find(x => x.nome == "Total de vagas");
                  VagasAgendadasHoje = element?.valores?.find(x => x.nome == "Vagas agendadas");
                  VagasUtilizadasHoje = element?.valores?.find(x => x.nome == "VAGAS UTILIZADAS");

                }
                if (VagaVpGeral.pTotalVagas == 0)
                  VagaVpGeral.pTotalVagas = VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato;
                if (VagaVpGeral.pTotalVagasAgendadas == 0)
                  VagaVpGeral.pTotalVagasAgendadas = VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato;
                if (VagaVpGeral.pVagasDoLivresDia == 0)
                  VagaVpGeral.pVagasDoLivresDia = VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato;
                if (VagaVpGeral.pVagasUtilizadas == "0%")
                  VagasUtilizadasHoje?.valorSemFormato == undefined || isNaN(parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()))
                    ? "0%"
                    : parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) + "%";
                if (element.cor != null) {
                  var findCor = this.cores.find(x => x.status == element.cor);
                  var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;
                  VagaVpGeral.backGroundColorCSS = cor;
                  VagaVpGeral.nomeCor = element.cor;
                }
                this.setValoresVPGeral(element.valor, EixoHorizontal, Legenda1, Legenda2, VagaVpGeral.chartDatasets);
                this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, VagaVpGeral.chartDatasets);
              }
            }
          });
          this.vagasDisponiveisArray.sort((a, b) => {
            if (a.nomeOrgao === "Visão Geral") return -1;
            if (b.nomeOrgao === "Visão Geral") return 1;
            return a.nomeOrgao.localeCompare(b.nomeOrgao);
          });
          console.log(this.vagasDisponiveisArray);


        });
        this.setAtualizacaoindicadores();
        this.loadingService.hide("CarregaVagas");
      });
    }
  }

  setValores(valores: valor[] | undefined, EixoHorizontal: string, Legenda1: string, Legenda2: string, valorGrafico: any): valor | undefined {
    var DadosHoje: valor | undefined = undefined;
    if (valores) {
      valores.forEach(element => {
        if (element.nome == EixoHorizontal) {
          valorGrafico["labels"].push(this.getDataFormatada(element.valorFormatado));
          if (this.isToday(element.valorFormatado)) {
            DadosHoje = element;
          }
        }
        if (element.nome == Legenda1) {
          valorGrafico["datasets"][0]["data"].push(element.valorSemFormato);
        }

        if (element.nome == Legenda2) {
          valorGrafico["datasets"][1]["data"].push(element.valorSemFormato);
        }
        this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
      });
    }
    return DadosHoje;
  }

  ngOnDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
  }

  setAtualizacaoindicadores() {
    const cores = {
      blueGray800: '#37474f',
      azul500: '#5886ac',
      textColor: '#102a43',
      textColorSecondary: '#292222',
      colorGrid: '#858486'
    };
    let login = localStorage.getItem('login');
    const intervalId = setInterval(() => {
      this.atualizando = true;
      this.indicadorService.findGraficoTelaInteira("orgao", 1, 2, 0, undefined, login == undefined ? "" : login, this.postosUsuario, this.receivedCcPosto).pipe(
      ).subscribe((response) => {
        this.listaInd = [];
        this.vagasDisponiveisArray = [];
        this.data = response;
        console.log(this.data);
        this.data.forEach(res => {
          this.listaInd = [];
          this.dataCampos = res["camposGrafico"];
          this.dataRet = res["indicadores"];
          let i = 0;
          this.dataRet.forEach(element => {
            var v = element['valor'];
            var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null
              , new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
            var val = new valor(
              v['codigo'],
              v['nome'],
              v['expressao'],
              v['icone'],
              v['denominador'],
              v['nominador'],
              v['valorFormatado'],
              v['valorSemFormato'],
              v['naoMostraCampo'],
              v['naoMostraTitulo'],
              v['orientacaoLoop'],
              v['ehLooping'],
              v['ehComponenteGrafico'],
              v['componenteGraficoHtml'],
              v['componenteGraficoCss'],
              v['componenteGraficoPossicao'],
              v['ehLink'],
              v['linkRedirect'],
              v['linkVariaveis'],
              v['ordem'],
              v['ehCampoCabecalho'],
              this.getValores(v['valores'], ind),
              v['cor']
            )
            ind = new indicadorDTO(
              element['codigo'],
              element['nome'],
              element['cor'],
              element['etapa'],
              element['icone'],
              element['corFarol'],
              element['ordenacaoOrientacao'],
              element['proximaEtapa'],
              val,
              [], [],
              element['linkRedirect']
            );
            ind.valores = this.getValores(element['valores'], ind)
            this.listaInd.push(ind);
          });
          var titulo = '';
          var EixoHorizontal = "";
          var Legenda1 = "";
          var Legenda2 = "";
          this.dataCampos.forEach(element => {
            if (element["tipoValorGrafico"] == 1)
              titulo = element["nome"];
            if (element["tipoValorGrafico"] == 3)
              EixoHorizontal = element["nome"];
            if (element["tipoValorGrafico"] == 4)
              Legenda1 = element["nome"];
            if (element["tipoValorGrafico"] == 5)
              Legenda2 = element["nome"];
          });
          var data = formatDate(new Date(), 'dd/MM/yyyy', 'en');
          if (res["nomeTipoTela"] == "Visão Geral") {
            this.listaInd = this.listaInd.sort((a, b) => {
              if (parseInt(a.valor.valorFormatado, 10) < parseInt(b.valor.valorFormatado, 10)) return -1;
              if (parseInt(a.valor.valorFormatado, 10) > parseInt(b.valor.valorFormatado, 10)) return 1;
              return 0;
            });
          }
          this.listaInd.forEach(element => {
            var valorGrafico: any = {
              labels: [],
              datasets: [
                {
                  type: 'bar',
                  label: '' + Legenda1,
                  backgroundColor: cores.blueGray800,
                  data: [],
                },
                {
                  type: 'bar',
                  label: '' + Legenda2,
                  data: [],
                  backgroundColor: cores.azul500,// Cor de fundo transparente
                },
              ],
            };
            if (res["nomeTipoTela"] != "Visão Geral") {
              var DadosHoje = this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
              var VagasAbertasHoje = DadosHoje?.valores.find(x => x.nome == "vagas abertas")
              var VagasTotalHoje = DadosHoje?.valores?.find(x => x.nome == "Total de vagas")
              var VagasAgendadasHoje = DadosHoje?.valores?.find(x => x.nome == "Vagas agendadas")
              var VagasUtilizadasHoje = DadosHoje?.valores.find(x => x.nome == "VAGAS UTILIZADAS")
              var findCor = this.cores.find(x => x.status == element.cor);
              var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;
              var vagas = new VagasDisponiveisModel(
                1, element.valor.valorFormatado, data, cor, element.cor,
                VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato,
                VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato,
                VagasUtilizadasHoje?.valorSemFormato == undefined || isNaN(parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()))
                  ? "0%"
                  : parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) + "%",
                VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato,
                'bar', valorGrafico, element.codigo == null ? 0 : element.codigo, element.valor.valorSemFormato + "", element.linkRedirect, element
              )
              this.vagasDisponiveisArray.push(vagas)
            }

            else {
              var VagaVpGeral = this.vagasDisponiveisArray.find(x => x.nomeOrgao == "Visão Geral")
              if (VagaVpGeral == null) {
                var VagasAbertasHoje: valor | undefined;
                var VagasTotalHoje: valor | undefined;
                var VagasAgendadasHoje: valor | undefined;
                var VagasUtilizadasHoje: valor | undefined;

                if (this.isToday(element.valor.valorFormatado)) {
                  console.log("HOJE", element)
                  VagasAbertasHoje = element?.valores?.find(x => x.nome == "vagas abertas")
                  VagasTotalHoje = element?.valores?.find(x => x.nome == "Total de vagas")
                  VagasAgendadasHoje = element?.valores?.find(x => x.nome == "Vagas agendadas")
                  VagasUtilizadasHoje = element?.valores?.find(x => x.nome == "VAGAS UTILIZADAS")
                }
                var findCor = this.cores.find(x => x.status == element.cor);
                var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;

                this.setValoresVPGeral(element.valor, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
                this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
                var vagas = new VagasDisponiveisModel(
                  1, res["nomeTipoTela"], data, cor, element.cor,
                  VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato,
                  VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato,
                  VagasUtilizadasHoje?.valorSemFormato == undefined || isNaN(parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()))
                    ? "0%"
                    : parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) + "%",
                  VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato,
                  'bar', valorGrafico, element.codigo == null ? 0 : element.codigo, "", element.linkRedirect, element
                )
                this.vagasDisponiveisArray.push(vagas)
              }
              else {
                var VagasAbertasHoje: valor | undefined;
                var VagasTotalHoje: valor | undefined;
                var VagasAgendadasHoje: valor | undefined;
                var VagasUtilizadasHoje: valor | undefined;
                if (this.isToday(element.valor.valorFormatado)) {
                  console.log("HOJE", element)
                  VagasAbertasHoje = element?.valores?.find(x => x.nome == "vagas abertas");
                  VagasTotalHoje = element?.valores?.find(x => x.nome == "Total de vagas");
                  VagasAgendadasHoje = element?.valores?.find(x => x.nome == "Vagas agendadas");
                  VagasUtilizadasHoje = element?.valores?.find(x => x.nome == "VAGAS UTILIZADAS");
                  console.log("TESTE: ", VagasUtilizadasHoje)
                }
                if (VagaVpGeral.pTotalVagas == 0)
                  VagaVpGeral.pTotalVagas = VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato;
                if (VagaVpGeral.pTotalVagasAgendadas == 0)
                  VagaVpGeral.pTotalVagasAgendadas = VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato;
                if (VagaVpGeral.pVagasDoLivresDia == 0)
                  VagaVpGeral.pVagasDoLivresDia = VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato;
                if (VagaVpGeral.pVagasUtilizadas == "0%")
                  VagaVpGeral.pVagasUtilizadas = VagasUtilizadasHoje?.valorFormatado == undefined ? "0%" : VagasUtilizadasHoje?.valorFormatado;
                if (element.cor != null) {
                  var findCor = this.cores.find(x => x.status == element.cor);
                  var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;
                  VagaVpGeral.backGroundColorCSS = cor;
                  VagaVpGeral.nomeCor = element.cor;
                }
                this.setValoresVPGeral(element.valor, EixoHorizontal, Legenda1, Legenda2, VagaVpGeral.chartDatasets);
                this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, VagaVpGeral.chartDatasets);
              }
            }
          });
          this.vagasDisponiveisArray.sort((a, b) => {
            if (a.nomeOrgao === "Visão Geral") return -1;
            if (b.nomeOrgao === "Visão Geral") return 1;
            return a.nomeOrgao.localeCompare(b.nomeOrgao);
          });
          console.log(this.vagasDisponiveisArray);
          this.atualizando = false;
        });
      });
    }, (this.tempoAtualizacao * 1000));
    this.intervalIds.push(intervalId);
  }

  isToday(dateInMillis: string): boolean {
    const date = new Date(parseInt(dateInMillis, 10));
    const today = new Date();
    const dateToCheck = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    return dateToCheck.getUTCDate() === today.getDate() &&
      dateToCheck.getUTCMonth() === today.getMonth() &&
      dateToCheck.getUTCFullYear() === today.getFullYear();
  }

  setValoresVPGeral(valor: valor | undefined, EixoHorizontal: string, Legenda1: string, Legenda2: string, valorGrafico: any): any {
    if (valor) {
      if (valor.nome == EixoHorizontal) {
        valorGrafico["labels"].push(this.getDataFormatada(valor.valorFormatado));
      }
      if (valor.nome == Legenda1) {
        valorGrafico["datasets"][0]["data"].push(valor.valorSemFormato);
      }
      if (valor.nome == Legenda2) {
        valorGrafico["datasets"][1]["data"].push(valor.valorSemFormato);
      }
    }
    return valorGrafico;
  }

  getDataFormatada(dataEmMili: string): string {
    var value = Number.parseInt(dataEmMili);
    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;
  }

  getValores(response: [], ind: indicadorDTO): valor[] {
    if (response == undefined) {
      return [];
    }
    var listavalores: valor[] = [];
    response.forEach(element => {
      var val = new valor(
        element['codigo'],
        element['nome'],
        element['expressao'],
        element['icone'],
        element['denominador'],
        element['nominador'],
        element['valorFormatado'],
        element['valorSemFormato'],
        element['naoMostraCampo'],
        element['naoMostraTitulo'],
        element['orientacaoLoop'],
        element['ehLooping'],
        element['ehComponenteGrafico'],
        element['componenteGraficoHtml'],
        element['componenteGraficoCss'],
        element['componenteGraficoPossicao'],
        element['ehLink'],
        element['linkRedirect'],
        element['linkVariaveis'],
        element['ordem'],
        element['ehCampoCabecalho'],
        this.getValores(element['valores'], ind),
        element['cor']
      )
      if (val.ehComponenteGrafico) {
        if (ind.listaComponentesGraficos == undefined)
          ind.listaComponentesGraficos = [];
        if (ind.listaComponentesGraficos.find(x =>
          x.valorFormatado == val.valorFormatado &&
          val.componenteGraficoCss == x.componenteGraficoCss &&
          val.componenteGraficoHtml == x.componenteGraficoHtml &&
          x.componenteGraficoPossicao == val.componenteGraficoPossicao) == null) // checa se ja não colocou o mesmo componente grafico na lista
          ind.listaComponentesGraficos.push(val);
      }
      listavalores.push(val);
    });
    return listavalores;
  }

  navigateWithState(Link: string | null, IdTela: number, CcOrgao: string) {
    var vaga = this.vagasDisponiveisArray.find(x => x.CodigoCentralizado == CcOrgao);
    if (CcOrgao != "" && vaga != undefined){

      this.listahitorico.push(new historicoVagasDTO(vaga.nomeOrgao, "Dashboard: ", undefined));
      if (this.receivedCcPosto != "" && this.receivedCcPosto != undefined)
        this.listahitorico.push(new historicoVagasDTO("", " do posto " + this.receivedDescPosto, vaga));
    }
    else {
      
      this.listahitorico.push(new historicoVagasDTO("Visão Geral", "Dashboard: ", undefined));
      if (this.receivedCcPosto != "" && this.receivedCcPosto != undefined) 
        this.listahitorico.push(new historicoVagasDTO("", " do posto " + this.receivedDescPosto, undefined));
    }
    window.localStorage.setItem('historicoVagas', JSON.stringify(this.listahitorico));
    if (this.receivedCcPosto != "") {
      this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
      window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
      this.router.navigate(['' + Link], {
        queryParams: { tipoTela: IdTela, CcOrgao: CcOrgao, CcPosto: this.receivedCcPosto, Etapa: 4, }
      });
    }
    else {
      
      this.router.navigate(['' + Link], {
        queryParams: { tipoTela: IdTela, CcOrgao: CcOrgao}
      });
    }
  }

}

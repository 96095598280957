import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { RegiaoService } from '../../../../service/regiao.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';


@Component({
  selector: 'app-cadastro-adicionar-regiao',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-adicionar-regiao.component.html',
  styleUrl: './cadastro-adicionar-regiao.component.scss'
})

export class CadastroAdicionarRegiaoComponent implements OnInit{
  data : any[]=[];
  public lista: regiao[] = [];
  novoItem: regiao = new regiao();

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private regiaoService: RegiaoService,
    public dialog: MatDialog,
    private authService: AuthService) {
    try{
      let login = localStorage.getItem('login');
      if(login == null || login == undefined)
        this.router.navigate(['/iniciar']);
      }
      catch(ex)
      {
        this.router.navigate(['/iniciar']);
      }
  }
  submitForm() {

    if (this.novoItem.descricao == "") {
      alert("Digite todos os campos obrigatorios!")
      return;
    }

    //console.log('Novo item', this.novoItem);
    this.regiaoService.adicionarItem(this.novoItem).subscribe(() => {
      //console.log('Novo item adicionado com sucesso', this.novoItem);
      this.novoItem = new regiao();
      this.authService.atualizarListaPostosERegiaoUsuario();
      this.router.navigate(['regiao-listagem']);
    }, error => {
      console.error('Erro ao adicionar novo item:', error);
    });

  }


  Limpar()
  {
    this.novoItem = new regiao();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let idEditar = params['IdEditar'];
      if(idEditar!=undefined)
        this.AbrirEditarItem(idEditar);
    });
  }
  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.regiaoService.editarItem(id?.toString()).subscribe(
        (response) => {
          //console.log(response);
          let p = response["Regiao"];

          this.novoItem.id = p['id'];
          this.novoItem.codigo = p['codigo'];
          this.novoItem.descricao = p['descricao'];
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }
}

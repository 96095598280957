import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { PostoService } from '../../../../service/posto.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { RegiaoService } from '../../../../service/regiao.service';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { orgao } from '../../../../modal/regional/orgao';
import { servico } from '../../../../modal/regional/servico';
import { mensagem } from '../../../../modal/mensagem';
import { indicadorAlertaPermanencia } from '../../../../modal/indicadorAlertaPermanencia';
import { indicadorAlertaPermanenciaService } from '../../../../service/indicadorAlertaPermanencia.service';
import { posto_simple } from '../../../../modal/posto_simple';
import { TimeFormatPipe } from '../../../../utilidades/time-format.pipe';
import { DateTimeFormatPipe } from '../../../../utilidades/date-time-format.pipe';
import { StatusIndicadorAlerta } from '../../../../utilidades/enum/status.indicador.alerta.enum';
import { TipoMensagemIndicadorAlerta } from '../../../../utilidades/enum/tipo.mensagem.indicador.alerta.enum';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'app-cadastro-incluisao-justificativas',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-incluisao-justificativas.component.html',
  styleUrl: './cadastro-incluisao-justificativas.component.scss',
  providers: [TimeFormatPipe, DateTimeFormatPipe]
})

export class CadastroIncluisaoJustificativasComponent implements OnInit {
  data = [];
  MensagemAlert = "";
  novaMensagem: mensagem = new mensagem(0, "", 0, "", 0);
  public ModalIndicadorAlerta: indicadorAlertaPermanencia | null = null;
  public listaMensagem: mensagem[] = [];
  public StatusIndicadorAlerta = StatusIndicadorAlerta;
  public TipoMensagemIndicadorAlerta = TipoMensagemIndicadorAlerta;
  public tipomensagem = TipoMensagemIndicadorAlerta.JUSTIFICATIVA;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private IndAlertaPermanenciaService: indicadorAlertaPermanenciaService,
    public dialog: MatDialog,
    public timeFormatPipe: TimeFormatPipe,
    public dateTimeFormatPipe: DateTimeFormatPipe,
    private authService: AuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  submitForm() {
    if (this.novaMensagem?.mensagem == undefined || this.novaMensagem.mensagem == "") {
      alert("Digite todos os campos obrigatorios!")
      return;
    }
    if (this.ModalIndicadorAlerta != null) {
      let login = localStorage.getItem('login');
      this.novaMensagem.id = this.listaMensagem.length + 1;
      this.novaMensagem.tipoMensagem = this.tipomensagem;

      this.novaMensagem.loginCadastro = login == null ? "" : login;
      const timestampEmMillis = Date.now();
      this.novaMensagem.dataCadastro = timestampEmMillis;
      this.ModalIndicadorAlerta?.mensagens?.push(this.novaMensagem);
      if (this.tipomensagem == TipoMensagemIndicadorAlerta.JUSTIFICATIVA) {
        this.ModalIndicadorAlerta.status = StatusIndicadorAlerta.PENDENTE_VALIDACAO;
      }
      else if (this.tipomensagem == TipoMensagemIndicadorAlerta.COBRANCA) {
        this.ModalIndicadorAlerta.status = StatusIndicadorAlerta.PEDENTE_POSTO;
      }
      if (this.ModalIndicadorAlerta != null) {
        this.IndAlertaPermanenciaService.salvarItem(this.ModalIndicadorAlerta).subscribe(() => {
          console.log('Novo item adicionado com sucesso', this.ModalIndicadorAlerta);
          this.ModalIndicadorAlerta = null;
          this.router.navigate(['listagem-justificativa']);
        }, error => {
          console.error('Erro ao adicionar novo item:', error);
        });
      }
    }
  }

  MudarTipoMensagem(novoTipo: number) {
    this.tipomensagem = novoTipo;
  }

  Finalizar() {
    if (this.ModalIndicadorAlerta?.mensagens?.length == 0) {
      this.MensagemAlert = "Deve ter pelo menos uma Justificativa antes de Finalizar."
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title: 'Confirmação', message: 'Tem certeza que deseja finalizar esse argumento? Após a finalização não será mais possível visualizar o argumento!' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.ModalIndicadorAlerta != null) {
            let login = localStorage.getItem('login');
            this.novaMensagem.id = this.listaMensagem.length + 1;
            this.novaMensagem.tipoMensagem = this.tipomensagem;
            this.ModalIndicadorAlerta.status = StatusIndicadorAlerta.FECHADO;

            this.novaMensagem.loginCadastro = login == null ? "" : login;
            const timestampEmMillis = Date.now();
            this.novaMensagem.dataCadastro = timestampEmMillis;
            if (this.ModalIndicadorAlerta != null) {
              this.IndAlertaPermanenciaService.salvarItem(this.ModalIndicadorAlerta).subscribe(() => {
                console.log('Novo item adicionado com sucesso', this.ModalIndicadorAlerta);
                this.ModalIndicadorAlerta = null;
                this.router.navigate(['listagem-justificativa']);
              }, error => {
                console.error('Erro ao adicionar novo item:', error);
              });
            }
          }
        }
      });
    }
  }

  Limpar() {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let idEditar = params['IdEditar'];
      if (idEditar != undefined)
        this.AbrirEditarItem(idEditar);
    });
  }

  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.IndAlertaPermanenciaService.editarItem(id?.toString()).subscribe(
        (response) => {
          console.log(response);
          var element = response["IndicadorAlertaPermanencia"];
          let p = new posto_simple(element['posto']['id'], element['posto']['descricao']);
          this.data = element["mensagens"];
          var M_i = 0;
          if (this.data != undefined)
            this.data.forEach(el_mensagem => {
              var m = new mensagem(M_i, el_mensagem["mensagem"], el_mensagem["tipoMensagem"], el_mensagem["loginCadastro"], el_mensagem["dataCadastro"]);
              this.listaMensagem.push(m);
            });
          let i = new indicadorAlertaPermanencia(
            element['id'],
            element['data_fim'],
            element['dataInicio'],
            element['tempo'],
            element['qtdCidadaoEmEspera'],
            element['status'],
            element['descFila'],
            element['qtdPontosAbertos'],
            p,
            this.listaMensagem);
          this.ModalIndicadorAlerta = i;
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }

  validarRole() {
    let role = this.authService.role();
    if (role == 'superuser' || role == 'superintendencia') {
      return true;
    }
    return false;
  }

  ModoLeituraTipoMen1() {
    if (this.ModalIndicadorAlerta != undefined && this.ModalIndicadorAlerta.mensagens != undefined && this.ModalIndicadorAlerta.mensagens.length != 0) {
      var ultimaMensagem = this.ModalIndicadorAlerta.mensagens[this.ModalIndicadorAlerta.mensagens.length -
        1]
      let role = this.authService.role();
      if (role == 'superuser' || role == 'superintendencia') {
        if (this.ModalIndicadorAlerta?.mensagens?.length == 0) {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        if (ultimaMensagem.tipoMensagem == 2)
          return false;
        else
          return true;
      }
    }
    else {
      return false;
    }
  }

  ModoLeituraBotaoSalvarTipoMen1() {
    if(this.tipomensagem == 2)
      return false;
    if (this.ModalIndicadorAlerta != undefined && this.ModalIndicadorAlerta.mensagens != undefined && this.ModalIndicadorAlerta.mensagens.length != 0) {
      var ultimaMensagem = this.ModalIndicadorAlerta.mensagens[this.ModalIndicadorAlerta.mensagens.length -
        1]
      let role = this.authService.role();
      if (role == 'superuser' || role == 'superintendencia') {
        if (this.ModalIndicadorAlerta?.mensagens?.length == 0) {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        if (ultimaMensagem.tipoMensagem == 2)
          return false;
        else
          return true;
      }
    }
    else {
      return false;
    }
  }

  

  ModoLeituraTipoMen2() {
    if (this.ModalIndicadorAlerta != undefined && this.ModalIndicadorAlerta.mensagens != undefined && this.ModalIndicadorAlerta.mensagens.length != 0) {
      var ultimaMensagem = this.ModalIndicadorAlerta.mensagens[this.ModalIndicadorAlerta.mensagens.length -
        1]
      let role = this.authService.role();
      if (role == 'superuser' || role == 'superintendencia') {
        if (this.ModalIndicadorAlerta?.mensagens?.length == 0) {
          return false;
        }
        else {
          if (this.ModalIndicadorAlerta != undefined && this.ModalIndicadorAlerta.mensagens != undefined) {
            var ultimaMensagem = this.ModalIndicadorAlerta.mensagens[this.ModalIndicadorAlerta.mensagens.length -
              1]

            if (ultimaMensagem.tipoMensagem == 2) {
              return true;
            }
            else {
              return false;
            }
          }
        }
        return;
      }
      else {
        if (this.ModalIndicadorAlerta?.mensagens?.length == 0)
          return false;
        else
          return false;
      }
    }
    else {
      return true;
    }
  }
}
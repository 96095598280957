<div class="div-dialog-title" mat-dialog-title>
    <h2>
        Ajuda
    </h2>
</div>

<div class="div-button-close">
    <mat-dialog-actions>
        <button mat-dialog-close><i class="fa-solid fa-xmark"></i></button>
    </mat-dialog-actions>
</div>

<mat-dialog-content>
    @for (indicadores of indicadorAjuda; track indicadores) {
        <details>
            <summary>
                {{ indicadores.nome }}
                <i class="fa-solid fa-angle-down control-icon control-icon-expand"></i>
                <i class="fa-solid fa-xmark control-icon control-icon-close"></i>
            </summary>
            <div class="div-details">
                <h3>Sobre:</h3>
                <p>{{ indicadores.descricao }}</p>

                <p [innerHtml]="sanitizer.bypassSecurityTrustHtml(indicadores.textoAjudaHTML)"></p>

                <!-- <h3>Calculo:</h3>
                <p>{{ htmlCalculo }}</p>

                <h3>Regra de Cor</h3>
                <p><span class="verde-detalhes">Verde</span> :  {{ htmlVerde }}</p>
                <p><span class="amarelo-detalhes">Amarelo</span> : {{ htmlAmarelo }}</p>
                <p><span class="vermelho-detalhes">vermelho</span> : {{ htmlVermelho }}</p> -->

                <h3>Tempo de atualização dos indicadores:</h3>
                <p>Tempo: {{ indicadores.tempoAtualizacao }} </p>                
               
            </div>
        </details>
    }
</mat-dialog-content>

<section>
  <section style="height: auto;">
    <nav>
        <ol class="cd-breadcrumb custom-separator">
            <table>
                <tbody>
                    <tr>
                        <td class="nomeTela">
                            Alteração de Posições dos Indicadores Dashboard
                        </td>
                    </tr>
                </tbody>
            </table>
        </ol>
    </nav>
</section>
  <main>
    <section class="main-section">
      <form class="form-class" data-toggle="collapse" style="align-items: center; display: flex; justify-content: center;">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          @for (ind of listaIndEtapa1; track $index) {
            <div class="example-box" cdkDrag>
              {{$index+1}} - {{ind.nome}}
            </div>
          }
        </div>
        
        <div class="div-button mb-3 p-3">
          <a style="margin-left: 80%;  margin-right: 10px;position: fixed; bottom: 58px; right: 65px;"
            [routerLink]="['/indicador-listagem']"><button id="class-button" class="btn btn-secondary"
              style="border-radius: 50%;width: 45px;height: 45px;"><i class="fa-solid fa-rotate-left"></i></button></a>
          <button id="class-button"
            style="position: fixed; bottom: 58px; right: 22px;border-radius: 50%;width: 45px;height: 45px;"
            (click)="submitForm()" class="btn btn-primary"><i class="fa-solid fa-floppy-disk"></i></button>
        </div>
      </form>
    </section>
  </main>
</section>
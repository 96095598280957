export class orgao {
    public id: string | null;
    public codigo: string | undefined;
    public descricao: string;
    public codigoCentralizado: string;
    public nomeApresentacao: string;

    constructor(id: string | null = null,
      codigo: string | undefined = undefined,
      descricao: string = "",
      codigoCentralizado: string = "",
      nomeApresentacao: string = "") {
      this.id = id;
      this.codigo = codigo;
      this.descricao = descricao;
      this.codigoCentralizado = codigoCentralizado;
      this.nomeApresentacao = nomeApresentacao;
    }
}
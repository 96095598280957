import { CalculoCores } from "./CalculoCores";
import { Campos } from "./Campos";

export class indicador {
    public id: string | null;
    public codigo: number | undefined;
    public etapa: number | undefined;
    public proximaEtapa: number | undefined;
    public tipoResultado: number | undefined;
    public nome: string;
    public descricao: string;
    public tempoAtualizacao: number | undefined;
    public icone: string;
    public consulta: string;
    public parametro: string;
    public colecao: string;
    public versaoIndice: string;
    public abreviatura: string;
    public formula: string;
    public formulaValor: string;
    public visualizacaoDisplay: string;
    public valoresPorcentagemCabecalho : boolean
    public multiplasConsultas: CalculoCores[] | undefined;
    public calculoCores: CalculoCores[] | undefined;
    public corDefault: string = "";
    public corDefaultDenominador: string = "";
    public corDefaultNominador: string = "";
    public corDefaultZero: string = "";
    public ordenacaoOrientacao: string = "";
    public linkRedirect: string = "";
    public nomeTipoTela: string = "";
    public camposConsulta: Campos[] | undefined;
    public ehGraficoIndicador: boolean| undefined;
    public inativo: boolean = false;
    public temRegraAberturaFechamento: boolean| undefined = undefined;
    public ordemDashboard: number | undefined;
    public tipoGraficoIndicador: number | undefined;
    public textoAjudaHTML:string="";

    public consultasCalculoFarol: CalculoCores[] | undefined;
    public formulaCalculoFarol: string = "";
    public tempoAtualizacaoCalculoFarol: number | undefined;
    public tipoFinal:  string = "";
    public desabilitarFarol: boolean | undefined;

    constructor(id: string | null = null,
      codigo: number | undefined = undefined,
      etapa: number | undefined = undefined,
      proximaEtapa: number | undefined = undefined,
      tipoResultado: number | undefined = undefined,
      nome: string = "",
      descricao: string = "",
      tempoAtualizacao: number | undefined = undefined,
      icone: string = "",
      consulta: string = "",
      parametro: string = "",
      colecao: string = "",
      versaoIndice: string = "",
      abreviatura: string = "",
      formula: string = "",
      calculoCores: CalculoCores[] | undefined  = undefined,
      formulaValor: string = "",
      multiplasConsultas: CalculoCores[] | undefined  = undefined,
      valoresPorcentagemCabecalho : boolean = false,
      corDefault: string = "Cinza",
      corDefaultDenominador: string = "",
      corDefaultNominador: string = "",
      corDefaultZero: string = "",
      ordenacaoOrientacao: string = "",
      linkRedirect: string = "",
      nomeTipoTela: string = "",
      camposConsulta: Campos[] | undefined  = undefined,
      visualizacaoDisplay:  string = "",
      ehGraficoIndicador: boolean| undefined =undefined,
      tipoGraficoIndicador: number | undefined = undefined,
      inativo: boolean = false,
      ordemDashboard: number | undefined = undefined,
      temRegraAberturaFechamento: boolean| undefined =undefined,
      textoAjudaHTML:string="") {
      this.id = id;
      this.etapa = etapa;
      this.codigo = codigo;
      this.descricao = descricao;
      this.proximaEtapa = proximaEtapa;
      this.tempoAtualizacao = tempoAtualizacao;
      this.nome = nome;
      this.icone = icone;
      this.consulta = consulta;
      this.parametro = parametro;
      this.colecao = colecao;
      this.versaoIndice = versaoIndice;
      this.abreviatura = abreviatura;
      this.formula = formula;
      this.calculoCores = calculoCores;
      this.formulaValor = formulaValor;
      this.multiplasConsultas = multiplasConsultas;
      this.tipoResultado = tipoResultado;
      this.valoresPorcentagemCabecalho = valoresPorcentagemCabecalho;
      this.corDefault = corDefault;
      this.corDefaultDenominador = corDefaultDenominador;
      this.corDefaultNominador = corDefaultNominador;
      this.corDefaultZero = corDefaultZero;
      this.ordenacaoOrientacao = ordenacaoOrientacao;
      this.linkRedirect = linkRedirect;
      this.nomeTipoTela = nomeTipoTela;
      this.camposConsulta = camposConsulta;
      this.visualizacaoDisplay = visualizacaoDisplay;
      this.ehGraficoIndicador = ehGraficoIndicador;
      this.tipoGraficoIndicador = tipoGraficoIndicador;
      this.inativo = inativo;
      this.ordemDashboard = ordemDashboard;
      this.temRegraAberturaFechamento = temRegraAberturaFechamento;
      this.textoAjudaHTML = textoAjudaHTML;

    }
}
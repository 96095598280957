<section style="height: auto;">
    <nav>
      <ol class="cd-breadcrumb custom-separator">
        <table>
          <tbody>
            <tr>
              <td class="nomeTela">
                {{NomeTela}}
              </td>
            </tr>
            <tr>
              <td>
                <li *ngFor="let bc of listabreadcrumb">
                  @if (bc.url != "") {
                    <button (click)="navigateWithStateBC(bc.url,bc.label)">{{bc.label}}</button>
                  }
                  @else {
                  <em>{{bc.label}}</em>
                  }
                </li>
              </td>
            </tr>
          </tbody>
        </table>
      </ol>
    </nav>
  </section>

<table>
    <tbody>
        <tr>
            <td class="tdAtalho">
                <div class="menu-regiao">
                    <div class="box-menu-regiao">
                        <div id="boxMenu-div-h4">
                            <h4>Postos:</h4>
                            
                        </div>
                        <div class="div-ngfor">
                            <input placeholder="Busca" class="buscaMenuVagas" (keyup)="onKey($event)">
                        </div>
                        <div class="div-ngfor" *ngFor="let item of vagasDisponiveisArrayMenu">
                            <button class="menulink" (click)="navigateWithState(item.linkRedirect, item.codigoIndicador, item.CodigoCentralizado)">
                                <p class="ptext"><i class="fas fa-chevron-right"
                                        style="--fa-secondary-opacity: 1;margin-right: 2%;"></i>{{item.nomeOrgao}}</p>
                                </button>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <section>
                    <div class="container-cards-vagas-disponiveis">
                        <div class="card-container-vagasdisponivel">
                            @for (item of vagasDisponiveisArray; track item.id) {
                            <button class="a-link" (click)="navigateWithState(item.linkRedirect, item.codigoIndicador, item.CodigoCentralizado)">
                                <div class="card" [ngStyle]="{'background-color': item.backGroundColorCSS}">
                
                                    <div class="div-in-card-1">
                                        <p style="color: #ffffff;">{{ item.nomeOrgao }}</p>
                                        <div *ngIf="atualizando" class="loader loader--style8" title="7">
                                          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px"
                                              viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s"
                                                      dur="0.6s" repeatCount="indefinite" />
                                                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s"
                                                      dur="0.6s" repeatCount="indefinite" />
                                                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s"
                                                      dur="0.6s" repeatCount="indefinite" />
                                              </rect>
                                              <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2"
                                                      begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                                  <animate attributeName="height" attributeType="XML" values="10; 20; 10"
                                                      begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s"
                                                      dur="0.6s" repeatCount="indefinite" />
                                              </rect>
                                              <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2"
                                                      begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s"
                                                      dur="0.6s" repeatCount="indefinite" />
                                                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s"
                                                      dur="0.6s" repeatCount="indefinite" />
                                              </rect>
                                          </svg>
                                      </div>
                                        <!--<p>Vagas disponíveis</p>-->
                                    </div>
                
                                    <p-chart type="bar" [data]="item.chartDatasets" [options]="options"
                                        style="padding: 10px;"></p-chart>
                                    <p class="p-in-card-3"> {{ item.dataVagasDisponPeriodo }}</p>
                                    <p class="p-in-card-3">Vagas Agendadas:
                                        {{ item.pTotalVagasAgendadas | number:'1.0-0':'pt-BR'}} X Total de vagas: {{ item.pTotalVagas | number:'1.0-0':'pt-BR'}}</p>
                
                                    <div class="div-in-card-2">
                                        <p style="color: #fff;">Vagas utilizadas: {{item.pVagasUtilizadas}} </p>
                                        <p style="color: #fff;">Vagas Livres: {{item.pVagasDoLivresDia | number:'1.0-0':'pt-BR'}} </p>
                                    </div>
                
                                </div>
                              </button>
                            }
                        </div>
                    </div>
                
                </section>
            </td>
        </tr>
    </tbody>
</table>

@if(perfil != 'posto'){
    <section style="height: auto;">
        <nav>
          <ol class="divbusca">
            <table>
              <tbody>
                <tr>
                  <td class="tdTitilo">
                    <label for="regiao" class="form-label">Região:</label>
                  </td>
                  <td class="tdCampo">
                    <select (change)="onSelecionar(mySelectFiltroRegiao.value)" #mySelectFiltroRegiao name="select"
                      aria-placeholder="Regiao" class="form-select">
                      <option value=""></option>
                      <option *ngFor="let objeto of listaRegiao" value={{objeto.id}}>{{objeto.descricao}}</option>
      
                    </select>
                  </td>
                  <td class="tdTitilo">
                    <label for="posto" class="form-label">Posto:</label>
                  </td>
                  <td class="tdCampo">
                    <app-multiple-selection-select-all [lista]="listaPostos" [width]="larguraTela/2.5" [regiaoSelecionada]="RegiaoSelecionada"
                      (infoClicada)="processarInfo($event)"></app-multiple-selection-select-all>
                  </td>
                  <td class="tdTitilo">
                    <button class="btn btn-primary" (click)="filtrarPesquisa()" id="class-button"
                      type="submit">Pesquisar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </ol>
        </nav>
      </section>
}
<div class="container">
    @for(item of resultado; track resultado;  let indexResultado = $index){
        @if(newFilter.length > 0){
            @for(filtro of newFilter; track filtro;){
                @if(item.idPosto == filtro.codigoCentralizado){
                    <ul style="margin-bottom: 25%;position: relative;">
                        <li>
                            <div class="gh-table">
                                <div id="gridHeat">
                                    <div class="div-title tooltip">
                                        <div><h4 class="card-title">{{ item.nomePosto }}</h4></div>
                                    </div>
                                    <div class="div-value" style="height: 316px;">
                                        <div class="gh-row">
                                            <div class="gh-cell"></div>
                                            <div class="gh-cell"></div>
                                            @for (datas of listaDatas; track datas) {
                                            <div class="gh-cell" style="position: sticky !important;">
                                                    <div style="background-color: rgb(238, 238, 238);">
                                                        <div class="gh-value">{{ datas }}</div>
                                                    </div>
                                            </div>
                                            }
                                        </div>
                                            @for(obj of item.listaOrgaoServico; track obj; let indexLine = $index){
                                                <div class="gh-row">
                                                    @if(obj[2]){
                                                        @if(obj[2].length > 0){
                                                            @for (valores of obj[2]; track valores; let indexCol = $index) {
                                                                @if(indexCol == 0){
                                                                    <div class="gh-cell rowLabel" style="left: 0px; background-color: rgb(238, 238, 238);">
                                                                        @if(validarOrgaoAtual(obj[1].trim()) == true){
                                                                            <span class='gh-value'>
                                                                                {{ obj[1].trim() }}
                                                                            </span>
                                                                        }@else{
                                                                            <span class='gh-value'></span>
                                                                        }
                                                                    </div>
                                                                    <div class="gh-cell rowLabel" style="left: 53px; width: 300px; background-color: rgb(238, 238, 238);">
                                                                        <span class='gh-value'>{{ obj[0] }}</span>
                                                                    </div>
                                                                }
                                                                @if(indexCol < 14){
                                                                    @if(veririficarValores(valores) == true){
                                                                        <div class="gh-cell">
                                                                            <span></span>
                                                                        </div>
                                                                    }@else {
                                                                        <div class="gh-cell" [style.background]="paint(valores)">
                                                                            <span class="gh-value" >
                                                                                {{valores.v}}
                                                                            </span>
                                                                        </div>

                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                </div>
                                            }
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                }
            }
        }
        @else{
            <ul style="margin-bottom: 25%;position: relative;">
                <li>
                    <div class="gh-table">
                        <div id="gridHeat">
                            <div class="div-title tooltip">
                                <div><h4 class="card-title">{{ item.nomePosto }}</h4></div>
                            </div>
                            <div class="div-value" style="height: 316px;">
                                <div class="gh-row">
                                    <div class="gh-cell">
                                    </div>
                                    <div class="gh-cell"></div>
                                    @for (datas of listaDatas; track datas) {
                                    <div class="gh-cell" style="position: sticky !important;">
                                            <div style="background-color: rgb(238, 238, 238);">
                                                <div class="gh-value">{{ datas }}</div>
                                            </div>
                                    </div>
                                    }
                                </div>
                                    @for(obj of item.listaOrgaoServico; track obj; let indexLine = $index){
                                        <div class="gh-row">
                                            @if(obj[2]){
                                                @if(obj[2].length > 0){
                                                    @for (valores of obj[2]; track valores; let indexCol = $index) {
                                                        @if(indexCol == 0){
                                                            <div class="gh-cell rowLabel" style="left: 0px; background-color: rgb(238, 238, 238);">
                                                                @if(validarOrgaoAtual(obj[1].trim()) == true){
                                                                    <span class='gh-value'>
                                                                        {{ obj[1].trim() }}
                                                                    </span>
                                                                }@else{
                                                                    <span class='gh-value'></span>
                                                                }
                                                            </div>
                                                            <div class="gh-cell rowLabel" style="left: 53px; width: 300px; background-color: rgb(238, 238, 238);">
                                                                <span class='gh-value'>{{ obj[0] }}</span>
                                                            </div>
                                                        }
                                                        @if(indexCol < 14){
                                                            @if(veririficarValores(valores) == true){
                                                                <div class="gh-cell">
                                                                    <span></span>
                                                                </div>
                                                            }@else {
                                                                <div class="gh-cell" [style.background]="paint(valores)">
                                                                    <span class="gh-value" >
                                                                        {{valores.v}}
                                                                    </span>
                                                                </div>

                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        }
    }
</div>

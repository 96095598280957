import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { IndicadorService } from '../../../../service/Indicador.service';
import { indicador } from '../../../../modal/Indicador';

@Component({
  selector: 'app-cadastro-listagem-indicador',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-listagem-indicador.component.html',
  styleUrl: './cadastro-listagem-indicador.component.scss'
})

export class CadastroListagemIndicadorComponent implements OnInit {
  data = [];
  public listaInd: indicador[] = [];
  pesquisaDesc: string  = "";
  pesquisaEtapa: string  = "";
  pesquisaCodigo: number | undefined = undefined;
  pesquisaGrafico: number | undefined = undefined;

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;


  optionsTipoGraficoIndicador: any[] = [
    { value: '0', label: '' },
    //{ value: '1', label: 'Grafico Adicional - Unico' },
    { value: '4', label: 'Grafico Adicional - Loop Diario' },
    { value: '2', label: 'Grafico Tela Completa' },
    { value: '3', label: 'Alerta' }
  ];

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private indicadorService: IndicadorService, 
    public dialog: MatDialog) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }

  ngOnInit() {
    this.Pesquisa();
  }

  MudaFiltro()
  {
    this.pageIndex =0;
    this.Pesquisa() 
  }

  Pesquisa() {
    this.listaInd = [];
    this.indicadorService.getIndPaginacao(this.pageIndex, this.pageSize,this.pesquisaDesc, this.pesquisaCodigo, this.pesquisaEtapa, this.pesquisaGrafico).pipe(
    ).subscribe((response) => {
      this.data = response["Indicador"];
      this.totalPages = response["totalPages"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        let p = new indicador(
          element['id'], 
        element['codigo'], 
        element['etapa'], 
        element['proximaEtapa'], 
        element['tipoResultado'], 
        element['nome'], 
        element['descricao'], 
        element['tempoAtualizacao'], 
        element['icone'], 
        element['consulta'], 
        element['parametro'], 
        element['colecao'], 
        element['versaoIndice'], 
        element['abreviatura'], 
        element['formula']);
        p.inativo = element['inativo'];
        //if (element['regiao'] != undefined)
        //  p.regiao = new regiao(element['regiao']['id'], element['regiao']['codigo'], element['regiao']['descricao'])
        this.listaInd.push(p)
      });
    });
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  InativarItem(id: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmação', message: 'Tem certeza que deseja inativar?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id != undefined) {
          this.indicadorService.InativarItem(id?.toString()).subscribe(
            (response) => {
              console.log('Item inativado com sucesso!');
              this.Pesquisa();
            },
            (error) => {
              console.error('Erro ao inativar o item:', error);
            }
          );
        }
      }
    });
    
  }

  AtivarItem(id: string | null): void {
    if (id != undefined) {
      this.indicadorService.AtivarItem(id?.toString()).subscribe(
        (response) => {
          this.Pesquisa();
          console.log('Item Ativado com sucesso!');
        },
        (error) => {
          console.error('Erro ao ativar o item:', error);
        }
      );
    }
  }
}

export class MotorConfig {
    public id: string | null;
    public nome: string;
    public status: Boolean;
    public dataAtualizacao: number | null; 
    public codigo : number | null;
    public dataUltimaExecucao: number | null; 

    constructor(
        id: string | null = null,
        nome: string = "",
        status: Boolean=true, // Default value for status
        dataAtualizacao: number | null = null,
        codigo : number | null = null,
        dataUltimaExecucao: number | null = null,

    ) {
        this.id = id;
        this.nome = nome;
        this.status = status;
        this.dataAtualizacao = dataAtualizacao;
        this.codigo = codigo
        this.dataUltimaExecucao= dataUltimaExecucao;
    }
}
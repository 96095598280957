<ng-container *ngIf="orientacaoLoop === 'VERTICAL'">
  <div style="justify-content: center; margin: 10px;">
    <div *ngFor="let valor of valores">
      <div class="icone-indicador" style="margin-right: 15px;">
        <ng-container *ngIf="valor.orientacaoLoop != 'HORIZONTAL'">
        <div style="display: flex;margin-top: 10px;justify-content: left; align-items: center;">
          <i [classList]="valor.icone"
            style="color: #4b4a4a;;transform: scale(1.7,1.7); --fa-secondary-opacity:0.7;"></i>
          <div *ngIf="valor.naoMostraCampo!=true && !valor.ehCampoCabecalho"
            style="font-size: 15px;margin-top: 3px;margin-left: 34px;color: black;">
            <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
              [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:valor.nome}"></ng-container>
            <ng-container *ngIf="valor.naoMostraTitulo!=true">{{ valor.nome }} </ng-container>
            @if(valor.ehLink)
            {
            <button (click)="navigateWithState(valor.linkRedirect,valor.linkVariaveis)" style="background: #ffffff00;">
              <p>{{ valor.valorFormatado }}</p>
            </button>
            }
            @else
            {
            <p>{{ valor.valorFormatado }}</p>
            }
            <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
              [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:valor.nome}"></ng-container>
          </div>
          </div>
          <sub-indicador-especial-card-vertical [valores]="valor.valores" [orientacaoLoop]="valor.orientacaoLoop"
            [listaComponentesGraficos]="listaComponentesGraficos" [listabreadcrumb]="listabreadcrumb" [IdTela]="IdTela"
            [CcPosto]="CcPosto" [CcOrgao]="CcOrgao"></sub-indicador-especial-card-vertical>
          </ng-container>
          <ng-container *ngIf="valor.orientacaoLoop === 'HORIZONTAL'">
            <div style="display: flex;margin-top: 10px;justify-content: left; align-items: center;">
              <i [classList]="valor.icone"
                style="color: #4b4a4a;;transform: scale(1.7,1.7); --fa-secondary-opacity:0.7;"></i>
              <div *ngIf="valor.naoMostraCampo!=true && !valor.ehCampoCabecalho" 
                style="font-size: 15px;margin-top: 3px;margin-left: 34px;color: black;">
                <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
                  [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:valor.nome}"></ng-container>
                <ng-container *ngIf="valor.naoMostraTitulo!=true ">{{ valor.nome }} </ng-container>
                @if(valor.ehLink)
                {
                <button (click)="navigateWithState(valor.linkRedirect,valor.linkVariaveis)" style="background: #ffffff00;">
                  <p>{{ valor.valorFormatado }}</p>
                </button>
                }
                @else
                {
                <p>{{ valor.valorFormatado }}</p>
                }
                <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
                  [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:valor.nome}"></ng-container>
              </div>          
              <sub-indicador-especial-card-vertical [valores]="valor.valores" [orientacaoLoop]="valor.orientacaoLoop"
                [listaComponentesGraficos]="listaComponentesGraficos" [listabreadcrumb]="listabreadcrumb" [IdTela]="IdTela"
                [CcPosto]="CcPosto" [CcOrgao]="CcOrgao"></sub-indicador-especial-card-vertical>
            </div>
          </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="orientacaoLoop === 'HORIZONTAL'">
  <div style="margin: 10px; display: flex; flex-wrap: wrap; justify-content: center;">
    <div *ngFor="let valor of valores" style="display: flex; align-items: center; margin-right: 20px;">
      <div class="icone-indicador" style="margin-top: 10px;align-items: center;">
        <i [classList]="valor.icone" style="color: #4b4a4a; transform: scale(1.7,1.7); margin-right: 10px;"></i>
        <div *ngIf="valor.naoMostraCampo!=true && !valor.ehCampoCabecalho"  style="font-size: 15px; color: black;">
          <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
            [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:valor.nome}"></ng-container>
          <ng-container *ngIf="valor.naoMostraTitulo!=true">{{ valor.nome }} </ng-container>
          @if(valor.ehLink)
          {
          <button (click)="navigateWithState(valor.linkRedirect,valor.linkVariaveis)" style="background: #ffffff00;">
            <p>{{ valor.valorFormatado }}</p>
          </button>
          }
          @else
          {
          <p>{{ valor.valorFormatado }}</p>
          }
          <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
            [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:valor.nome}"></ng-container>
        </div>
        <sub-indicador-especial-card-vertical [valores]="valor.valores" [orientacaoLoop]="valor.orientacaoLoop"
          [listaComponentesGraficos]="listaComponentesGraficos" [listabreadcrumb]="listabreadcrumb" [IdTela]="IdTela"
          [CcPosto]="CcPosto" [CcOrgao]="CcOrgao"></sub-indicador-especial-card-vertical>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #RenderComponenteGrafico let-valor="valor" let-possicao="possicao" let-campo="campo">
  <ng-container *ngIf="valor.componenteGraficoPossicao=='{'+campo+';'+possicao+'}'">
    <app-display-componente-grafico [valor]="valor"></app-display-componente-grafico>
  </ng-container>
</ng-template>
import { Component, Input, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { IndicadorService } from '../../service/Indicador.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { indicador } from '../../modal/Indicador';

@Component({
  selector: 'app-ajuda',
  standalone: true,
  templateUrl: "./ajuda.component.html",
  styleUrl: './ajuda.component.scss',
  imports: [MatDialogTitle,
     MatDialogContent,
      MatDialogActions,
       MatDialogClose,
        MatButtonModule,
         MatTableModule],
})

export class AjudaComponent implements OnInit {

    indicadorAjuda:any = [];
    htmlCalculo:any = 'Quantidade de pontos abertos / Total de pontos * 100';
    htmlVerde:any = '100% a 90%';
    htmlVermelho:any = '89% a 75%';
    htmlAmarelo:any = '74% a 0%';
    indicadorEtapa:any;
    arrayIndicadores!: Array<indicador>;
    constructor(
        private indicadorService: IndicadorService,
         private router: Router,
         private activatedRoute : ActivatedRoute,
         public sanitizer: DomSanitizer
         ){}

    ngOnInit() {
        this.mapearIndicadorEtapa(this.router.url);
        var etapa:Number = this.indicadorEtapa.etapa
        console.log("Etapa: ",etapa)
       
        var idIndicador:Number = 0;
        if(this.indicadorEtapa.idIndicador > 0){
            idIndicador = this.indicadorEtapa.idIndicador;
        }

        this.indicadorService.getIndicador().pipe().subscribe((indicadores:any) => {    
            this.arrayIndicadores = indicadores.Indicador;
            indicadores.Indicador.forEach((element:any) => {                
                if(element.etapa == etapa && element.codigo !== 14){
                    if(etapa == 1){
                        var tempoConvertido = this.formataMinuto(element.tempoAtualizacao);
                        element.tempoAtualizacao = tempoConvertido                    
                        this.indicadorAjuda.push(element);   
                        
                    }else                    
                        if(element.codigo == idIndicador && this.indicadorAjuda.length==0){

                            //console.log("elementoEtapaUM ***123 : ",  this.indicadorAjuda.length) 
                            var tempoConvertido = this.formataMinuto(element.tempoAtualizacao);
                            element.tempoAtualizacao = tempoConvertido
                            // Buscar por elemento com mesma abreviatura e etapa 1
                            const elementoEtapaUM = indicadores.Indicador.find((item: any) => item.abreviatura === element.abreviatura &&  item.etapa === 1 && item.codigo===idIndicador);
                            //console.log("elementoEtapaUM***: ", elementoEtapaUM)
                            if (elementoEtapaUM) {
                            element.textoAjudaHTML = elementoEtapaUM.textoAjudaHTML;
                            }
                           
                            this.indicadorAjuda.push(element);      
                         
                        }
                                       
                    

                }
            });
        })

        this.indicadorAjuda = this.indicadorAjuda.sort((a:any, b:any) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
        })     
    }

    formataMinuto(tempoAtualizacao:any){
        
        var date =tempoAtualizacao + " Segundos"
        if(tempoAtualizacao>=60){
            
             date = tempoAtualizacao / 60 +" Minutos"
             
        }
      ;
       return date;
    }

    public mapearIndicadorEtapa(url: string){
        var idIndicador:Number = 0;
        if(!url.includes('dashboard')){
            this.activatedRoute.queryParams.subscribe((params:any) => {
                idIndicador = parseInt(params.tipoTela);
            })
        }
        console.log("idIndicador: ",idIndicador)       
        if(url.includes('dashboard')){
            this.indicadorEtapa = {etapa: 1};
        }else if(url.includes('indicador-posto')){
            this.indicadorEtapa = {etapa: 2, idIndicador: idIndicador};
        }else if(url.includes('indicador-orgao')){
            this.indicadorEtapa = {etapa: 3, idIndicador: idIndicador};
        }else if(url.includes('indicador-fila')){
            this.indicadorEtapa = {etapa: 4, idIndicador: idIndicador};
        }else if(url.includes('vagas-disponiveis-regiao')){
            this.indicadorEtapa = {etapa: 2, idIndicador: idIndicador};
        }else if(url.includes('vagas-disponiveis-posto')){
            this.indicadorEtapa = {etapa: 3, idIndicador: idIndicador};
        }else if(url.includes('vagas-disponiveis-orgao')){
            this.indicadorEtapa = {etapa: 4, idIndicador: idIndicador};
        }else if(url.includes('vagas-disponiveis-servico')){
            this.indicadorEtapa = {etapa: 5, idIndicador: idIndicador};
        }else if(url.includes('relatorios/vagasDisponiveis')){
            this.indicadorEtapa = {etapa: 1, idIndicador: idIndicador};
        }else if(url.includes('relatorios/agendamentoDiasEspera')){
            this.indicadorEtapa = {etapa: 1, idIndicador: idIndicador};
        }
    }

}

import { HttpClientModule } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { boxInfo } from '../../modal/boxInfo';
import { CORES } from '../../modal/mock-cor';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CommonModule, formatDate } from '@angular/common';
import { breadcrumb } from '../../modal/breadcrumb';
import { ChartModule } from 'primeng/chart'
import { PChartCidadaoEsperaFilaUltimoMes, PChartCidadaoEsperaMediaEsperaServico, PChatCidadaoEsperaFilaTempoREAL } from '../../modal/cidadaoEsperaServicoModel';
import { ICONE } from '../../modal/icone';
import { IndicadorService } from '../../service/Indicador.service';
import { IndicadorFilaService } from '../../service/IndicadorFila.service';
import { TamanhoTituloService } from '../../cache/tamanho-indicador';
import { VagasDisponiveisModel } from '../../modal/VagasDisponiveisModel';
import { valor } from '../../modal/valor';
import { indicadorDTO } from '../../modal/dto/indicadorDTO';
import { retornoCamposGraficos } from '../../modal/dto/retornoCamposGraficos';

const cores = {
  blueGray800: '#37474f',
  azul500: '#5886ac',
  textColor: '#102a43',
  textColorSecondary: '#292222',
  colorGrid: '#858486'
};

@Component({
  selector: 'app-indicador-fila',
  standalone: true,
  imports: [
    HttpClientModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    RouterLinkActive,
    MatTooltipModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    FontAwesomeModule,
    CommonModule,
    MDBBootstrapModule,

    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatCardModule,
    MatTableModule,

    //PrimeNg
    ChartModule

  ],
  providers: [ICONE],
  templateUrl: './indicador-fila.component.html',
  styleUrl: './indicador-fila.component.scss'
})


export class IndicadorFilaComponent implements OnInit, OnDestroy {
  datafila = [];
  public linkRedirect = "";
  public nomeCidadaoPosto: string = "Posto Araçatuba"
  public valorIndicadorPosto: number = 6
  public corIindicadorPosto: string = "#fff"
  public listaSer: boxInfo[] = [];
  cores = CORES;
  public IdTela = 0;
  public NomeTela = "";
  listabreadcrumb: breadcrumb[] = [];
  public Icone = "";
  public CcPosto = "";
  public CcOrgao = "";
  public SemDados = false;
  maiorTamanhoTitulo: number = 0;
  private _iniciou: boolean = false;
  public atualizando: boolean = false;
  public atualizandoGraficos: boolean = false;
  maxHeight: number = 0;
  textInd: string | null = "";
  textPos: string | null = "";
  textOrg: string | null = "";
  tempoAtualizacao: any;
  GraficosTempoAtualizacao: any;
  NomeGrafico = "";

  public GraficosUnicoTempoAtualizacao: any;
  public GraficosUnicosArray: VagasDisponiveisModel[] = [];
  public GraficosLoopDiaArray: VagasDisponiveisModel[] = [];
  dataRet = []
  public listaInd: indicadorDTO[] = [];
  dataCampos = [];
  data = [];
  options: any;


  intervalIds: any[] = [];
  set iniciou(value: boolean) {
    this._iniciou = value;
    this.verificarTitulo();
  }



  constructor(private route: ActivatedRoute, private icon: ICONE, private router: Router, private tamanhoTituloService: TamanhoTituloService, private indicadorService: IndicadorService, private FilaIndicadorService: IndicadorFilaService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
    
    const breadcrumbString = window.localStorage.getItem('breadcrumb');
    if (breadcrumbString) {
      var bc = JSON.parse(breadcrumbString);
    }
    if (bc != undefined && bc.length != 0) {
      for (let index = 0; index < bc.length; index++) {
        const element = bc[index];
        this.listabreadcrumb.push(new breadcrumb(element.label, element.url));
      }
    }
    else {
      this.listabreadcrumb = [
        new breadcrumb("Dashboard", "/dashboard"),
        new breadcrumb("Posto", "/indicador-posto"),
        new breadcrumb("Orgão", "/indicador-orgao")
      ];
    }
  }

  ngOnInit() {
    let login = localStorage.getItem('login');
    this.maiorTamanhoTitulo = this.tamanhoTituloService.getMaiorTamanhoTitulo();
    if (this.maiorTamanhoTitulo == 0)
      this.maiorTamanhoTitulo = 31;
    // Roteamento de telas
    this.route.queryParamMap.subscribe(params => {
      let tipotela = params.get('tipoTela');
      this.IdTela = tipotela == null ? 0 : Number.parseInt(tipotela);
      this.textInd = params.get('textInd');
      this.textPos = params.get('textPos');
      this.textOrg = params.get('textOrg');
      let CodPosto = params.get('CcPosto');
      this.CcPosto = CodPosto == null ? "" : CodPosto;

      var retrievedObject = localStorage.getItem('salaSituacao');
        if (retrievedObject != null) {
            var sala = JSON.parse(retrievedObject);
            console.log(retrievedObject);
            //console.table(sala['postos']);
            var postosUsuario = sala['postos'];
            if(!sala.postos.includes(Number.parseInt(this.CcPosto)))
              this.router.navigate(['/dashboard']);
        }
        else
          this.router.navigate(['/iniciar']);

      let CodOrgao = params.get('CcOrgao');
      this.CcOrgao = CodOrgao == null ? "" : CodOrgao;
      this.FilaIndicadorService.getindicadores(this.IdTela, this.CcPosto, this.CcOrgao, login == undefined ? "" : login).pipe(
      ).subscribe((response) => {
        console.log(response)
        this.datafila = response["indicadoresFila"];
        //console.log("CARREGA", this.datafila);
        if(!this.listabreadcrumb.find(x=>x.label == "" + response["nomeTipoTela"]))
          this.listabreadcrumb.push(new breadcrumb("" + response["nomeTipoTela"], ""));
        if (this.datafila.length != 0) {
          this.SemDados = false;
          this.tempoAtualizacao = response["tempoAtualizacao"];
          this.linkRedirect = response["linkRedirectIndicador"];
          if (response["orgao"] != null) {
            this.NomeTela =
              this.datafila[0]["nome"] + (this.textInd == null ? "" : this.textInd)
              + " (do orgão " + response["orgao"]['orgao']['descricao']
              + (this.textOrg == null ? "" : this.textOrg) + " do posto "
              + response["posto"]['posto']['descricao']
              + (this.textPos == null ? "" : this.textPos) + ")";
          }
          else {
            this.NomeTela =
              this.datafila[0]["nome"] + (this.textInd == null ? "" : this.textInd)
              + " (" + (this.textOrg == null ? "" : this.textOrg) + "do posto "
              + response["posto"]['posto']['descricao']
              + (this.textPos == null ? "" : this.textPos) + ")";
          }
          var ord = this.datafila[0]["ordenacaoOrientacao"];
          if (ord == "DESC") {
            this.datafila.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
          } else if (ord == "CRES") {
            this.datafila.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
          }
          var i = 0;
          this.datafila.forEach(element => {
            if (element['valor']['codigo'] == null)
              i++;
            else
              i = element['valor']['codigo'];
            this.listaSer.push(new boxInfo(i.toString(), element['valor']['nome'], "", this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"]));
          });

          this.listaSer.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);

          this.iniciou = true;
          this.setAtualizacaoindicadores();
        }
        else {
          //console.log(response);
          this.listaSer.push(new boxInfo("0", "Sem Registros", "", this.cores.find(x => x.status == "Cinza"), "0", undefined, response["icone"]));
          this.tempoAtualizacao = response["tempoAtualizacao"];
          if (response["orgao"] != null) {
            this.NomeTela = response["posto"]["nome"] + " (do orgão " + response["orgao"]['orgao']['descricao'] + " do posto " + response["posto"]['posto']['descricao'] + ")";
          }
          else {
            this.NomeTela = response["posto"]["nome"] + " (do posto " + response["posto"]['posto']['descricao'] + ")";
          }
          this.setAtualizacaoindicadores();
        }

      });
      //this.setGraficosUnicos();
      this.setGraficosLoop(true);

    });

  }

  ngOnDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
  }

  setAtualizacaoindicadores() {
    let login = localStorage.getItem('login');
    const intervalId = setInterval(() => {
      this.atualizando = true;

      this.FilaIndicadorService.getindicadores(this.IdTela, this.CcPosto, this.CcOrgao, login == undefined ? "" : login).pipe(
      ).subscribe((response) => {
        this.datafila = response["indicadoresFila"];
        //console.log("ATAULIZA", this.datafila);
        var ord = this.datafila[0]["ordenacaoOrientacao"];
        this.tempoAtualizacao = response["tempoAtualizacao"];
        this.linkRedirect = response["linkRedirectIndicador"];
        if (ord == "DESC") {
          this.datafila.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
        } else if (ord == "CRES") {
          this.datafila.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
        }
        var i = 0;
        this.listaSer = [];
        this.datafila.forEach(element => {
          if (element['valor']['codigo'] == null)
            i++;
          else
            i = element['valor']['codigo'];
          this.listaSer.push(new boxInfo(i.toString(), element['valor']['nome'], "", this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"]));
        });

        this.listaSer.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);

        this.atualizando = false;
      });
    }, (this.tempoAtualizacao * 1000));
    this.intervalIds.push(intervalId);
  }

  verificarTitulo(): void {
    const tituloElement = document.querySelector('.title-card');
    if (tituloElement) {
      this.calcularAlturaMaxima();
    } else {
      setTimeout(() => {
        this.verificarTitulo();
      }, 10);
    }

  }

  calcularAlturaMaxima(): void {
    this.listaSer.forEach(ind => {
      const cardHeight = this.getHeightOfCard(ind);
      //console.log(cardHeight);
      if (cardHeight > this.maxHeight) {
        this.maxHeight = cardHeight;
      }
    });
  }

  getHeightOfCard(ind: any): number {
    // Calcula a altura do cartão com base no conteúdo
    const titleElement = document.querySelector('#titulo_' + ind.id);
    const cardContentElement = document.querySelector('#content_' + ind.id);
    // Verifica se os elementos existem antes de medir suas alturas

    if (titleElement && cardContentElement) {
      const titleHeight = titleElement.clientHeight;
      const cardContentHeight = cardContentElement.clientHeight;

      // Adiciona margem superior e inferior para obter a altura total do cartão
      const cardHeight = titleHeight + cardContentHeight + 30; // Ajuste conforme necessário

      return cardHeight;
    }

    return 0; // Retorna 0 se os elementos não forem encontrados
  }

  navigateWithStateBC(Link: string | null) {
    if (Link != null) {
      const index = this.listabreadcrumb.findIndex(b => b.url === Link);
      //console.log("LINK", Link);
      if (index !== -1) {
        this.listabreadcrumb = this.listabreadcrumb.slice(0, index);
      }
      window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    }
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, CcPosto: this.CcPosto, textInd: this.textInd, textPos: this.textPos }
    });
  }

  setGraficosLoop(setAtualizacao: boolean) {
    this.GraficosLoopDiaArray = [];
    var ordenacaoGrafico: string = "";
    const cores = {
      blueGray800: '#37474f',
      azul500: '#5886ac',
      textColor: '#102a43',
      textColorSecondary: '#292222',
      colorGrid: '#858486'
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 1.3,
      responsive: true,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: true,
          callbacks: {
            label: function (context: { dataset: { label: string; data: []; extraInfo: []; EhHora: boolean }; raw: any; dataIndex: number; }) {
              const extraInfo = context.dataset["extraInfo"][context["dataIndex"]];

              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }
              label += context.raw;
              if (context.dataset["EhHora"] != undefined && context.dataset["EhHora"] == true) {
                label = label + " min";
              }

              // Adiciona a informação extra no tooltip
              if (extraInfo != undefined)
                return label + ' (' + extraInfo + ')';
              else
                return label;
            },
          },
        },
        legend: {
          labels: {
            color: cores.textColor,
          },
          onHover: function (event: { native: { target: any; }; }, legendItem: any) {
            // Muda o cursor para "pointer" quando o mouse está sobre a legenda
            const chartElement = event.native.target;
            chartElement.style.cursor = 'pointer';
          },
          onLeave: function (event: { native: { target: any; }; }, legendItem: any) {
            // Reseta o cursor para o padrão quando o mouse sai da legenda
            const chartElement = event.native.target;
            chartElement.style.cursor = 'default';
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: cores.textColorSecondary,
            //autoSkip: false, // Adicione esta linha para exibir todos os rótulos
            //maxRotation: 90, // Ajuste o ângulo de rotação
            //minRotation: 60,
          },
          grid: {
            color: cores.colorGrid,
            drawBorder: false
          }
        },
        y: [{
          stacked: true,
          ticks: {
            color: cores.textColorSecondary,
            callback: function (value: number, index: number, values: number[]) {
              const additionalInfoIndex = values.length - 1; // Índice da série "Additional Info"
              if (index === additionalInfoIndex) return ''; // Retorna uma string vazia para excluir o valor da série "Additional Info"
              return value.toString();
            }
          },
          grid: {
            color: cores.colorGrid,
            drawBorder: false
          }
        }],
      },
    };

    let login = localStorage.getItem('login');

    if (this.CcOrgao == "") {
      var tipoTela = "fila";
      var variaveis = "{\"variaveis\": [{\"CcPosto\":\"" + this.CcPosto + "\"}]}";
    }
    else {
      var tipoTela = "servico 2";
      var variaveis = "{\"variaveis\": [{\"CcOrgao\": " + this.CcOrgao + "}, {\"CcPosto\":\"" + this.CcPosto + "\"}]}";
    }
    this.indicadorService.findGraficoAdicional(tipoTela, 4, 4, this.IdTela, variaveis, login == undefined ? "" : login).pipe(
    ).subscribe((response) => {
      this.data = response;
      if (this.data.length == 0) {
        return;
      }
      console.log(this.data);
      this.data.forEach(res => {
        this.GraficosTempoAtualizacao = res["tempoAtualizacao"];
        this.listaInd = [];
        this.dataCampos = res["camposGrafico"];
        this.dataRet = res["indicadores"];
        this.NomeGrafico = res["nomeTipoTela"];
        let i = 0;
        this.dataRet.forEach(element => {
          var v = element['valor'];
          var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null
            , new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
          var val = new valor(
            v['codigo'],
            v['nome'],
            v['expressao'],
            v['icone'],
            v['denominador'],
            v['nominador'],
            v['valorFormatado'],
            v['valorSemFormato'],
            v['naoMostraCampo'],
            v['naoMostraTitulo'],
            v['orientacaoLoop'],
            v['ehLooping'],
            v['ehComponenteGrafico'],
            v['componenteGraficoHtml'],
            v['componenteGraficoCss'],
            v['componenteGraficoPossicao'],
            v['ehLink'],
            v['linkRedirect'],
            v['linkVariaveis'],
            v['ordem'],
            v['ehCampoCabecalho'],
            this.getValores(v['valores'], ind),
            v['cor']
          )
          ind = new indicadorDTO(
            element['codigo'],
            element['nome'],
            element['cor'],
            element['etapa'],
            element['icone'],
            element['corFarol'],
            element['ordenacaoOrientacao'],
            element['proximaEtapa'],
            val,
            [], [],
            element['linkRedirect']
          );
          ordenacaoGrafico = element['ordenacaoOrientacao'];
          ind.valores = this.getValores(element['valores'], ind)
          this.listaInd.push(ind);
        });
        var titulo = '';
        var EixoHorizontal = "";
        var Legenda1 = "";
        var Legenda2 = "";
        var CampoOrdenacao = "";
        var InfoToolTipo: string[] = [];
        var InfoQuadroGeral: string[] = [];
        var TipoHoravalor1 = false;
        var HiddenPadraoValor2 = false;
        console.log(this.dataCampos)

        this.dataCampos.forEach(element => {
          if (element["tipoValorGrafico"] == 1)
            titulo = element["nome"];
          if (element["tipoValorGrafico"] == 3)
            EixoHorizontal = element["nome"];
          if (element["tipoValorGrafico"] == 4) {
            if (element["tipoResultado"] == 16)
              TipoHoravalor1 = true;
            Legenda1 = element["nome"];
          }
          if (element["tipoValorGrafico"] == 5) {
            if (element["ehOcultoPadrao"] == true)
              HiddenPadraoValor2 = true;
            Legenda2 = element["nome"];
          }
          if (element["tipoValorGrafico"] == 7)
            InfoToolTipo.push(element["nome"]);

          if (element["tipoValorGrafico"] == 8)
            InfoQuadroGeral.push(element["nome"]);

          if (element["ehCampoOrdenacao"] == true) {
            CampoOrdenacao = element["nome"];
          }
        });

        const today = new Date();

        // Obtém a data de ontem
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate());

        // Obtém a data de 30 dias atrás
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);

        // Formata as datas (opcional)
        const formattedYesterday = formatDate(yesterday, 'dd/MM/yyyy', 'en');
        const formattedThirtyDaysAgo = formatDate(thirtyDaysAgo, 'dd/MM/yyyy', 'en');
        var data = "Dados de " + formattedThirtyDaysAgo + " até " + formattedYesterday;

        var valorGrafico: any = {
          labels: [],
          datasets: [
            {
              type: 'bar',
              label: '' + Legenda1,
              backgroundColor: cores.blueGray800,
              data: [],
            },
          ],
        };

        this.listaInd.forEach(element => {
          var valorGrafico: any = {
            labels: [],
            datasets: [
              {
                type: 'bar',
                label: '' + Legenda1,
                backgroundColor: cores.blueGray800,
                extraInfo: [],
                EhHora: TipoHoravalor1,
                data: [],
              },
            ],
          };
          if (Legenda2 != "") {
            valorGrafico["datasets"].push({
              type: 'bar',
              label: '' + Legenda2,
              data: [],
              extraInfo: [],
              hidden: false,
              backgroundColor: cores.azul500,// Cor de fundo transparente
            });
          }
          var retCamposGraficos = this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico, InfoToolTipo, InfoQuadroGeral, HiddenPadraoValor2, CampoOrdenacao, new retornoCamposGraficos());
          var vagas = new VagasDisponiveisModel(
            1, element.valor.valorFormatado, data, "#7fccff", "#7fccff", 0, 0, "0%", 0,
            'bar', valorGrafico,
            element.codigo == null ? 0 : element.codigo, element.valor.valorSemFormato + "", "", element
          )
          vagas.ListainformacoesGraficoGeral = retCamposGraficos.DadosQuadroGeral;
          vagas.valorOrdenacao = retCamposGraficos.ValorOrdenacao;
          if (element.valor.valorFormatado != "NAO MOSTRAR")
            this.GraficosLoopDiaArray.push(vagas)

        });
        if (ordenacaoGrafico == "DESC") {
          this.GraficosLoopDiaArray.sort((a, b) => b['valorOrdenacao'] - a['valorOrdenacao']);
        } else if (ordenacaoGrafico == "CRES") {
          this.GraficosLoopDiaArray.sort((a, b) => a['valorOrdenacao'] - b['valorOrdenacao']);
        }
        console.log(this.GraficosLoopDiaArray);


      });
      if (setAtualizacao)
        this.setAtualizacaoGraficos();
    });
    this.atualizandoGraficos = false;
  }

  getValores(response: [], ind: indicadorDTO): valor[] {
    if (response == undefined) {
      return [];
    }
    var listavalores: valor[] = [];
    response.forEach(element => {
      var val = new valor(
        element['codigo'],
        element['nome'],
        element['expressao'],
        element['icone'],
        element['denominador'],
        element['nominador'],
        element['valorFormatado'],
        element['valorSemFormato'],
        element['naoMostraCampo'],
        element['naoMostraTitulo'],
        element['orientacaoLoop'],
        element['ehLooping'],
        element['ehComponenteGrafico'],
        element['componenteGraficoHtml'],
        element['componenteGraficoCss'],
        element['componenteGraficoPossicao'],
        element['ehLink'],
        element['linkRedirect'],
        element['linkVariaveis'],
        element['ordem'],
        element['ehCampoCabecalho'],
        this.getValores(element['valores'], ind),
        element['cor']
      )
      if (val.ehComponenteGrafico) {
        if (ind.listaComponentesGraficos == undefined)
          ind.listaComponentesGraficos = [];
        if (ind.listaComponentesGraficos.find(x =>
          x.valorFormatado == val.valorFormatado &&
          val.componenteGraficoCss == x.componenteGraficoCss &&
          val.componenteGraficoHtml == x.componenteGraficoHtml &&
          x.componenteGraficoPossicao == val.componenteGraficoPossicao) == null) // checa se ja não colocou o mesmo componente grafico na lista
          ind.listaComponentesGraficos.push(val);
      }
      listavalores.push(val);
    });
    return listavalores;
  }

  setValores(valores: valor[] | undefined, EixoHorizontal: string, Legenda1: string, Legenda2: string, valorGrafico: any, InfoToolTipo: string[], InfoQuadroGeral: string[], HiddenPadraoValor2: boolean, CampoOrdenacao: string, retCamposGraficos: retornoCamposGraficos): retornoCamposGraficos {
    if (valores) {
      valores.forEach(element => {
        if (element.nome == EixoHorizontal) {
          valorGrafico["labels"].push(this.getDataFormatada(element.valorFormatado));
        }

        if (element.nome == Legenda1) {
          valorGrafico["datasets"][0]["data"].push(Number.parseInt(element.valorFormatado));
          const isFirstDatasetAllZero = valorGrafico["datasets"][0]["data"].every((value: number) => value === 0);
          if (Legenda2 != "" && HiddenPadraoValor2) {
            valorGrafico["datasets"][1]["hidden"] = !isFirstDatasetAllZero;
          }
        }

        if (element.nome == Legenda2) {
          valorGrafico["datasets"][1]["data"].push(Number.parseInt(element.valorFormatado));
        }

        if (element.nome == CampoOrdenacao) {
          retCamposGraficos.ValorOrdenacao = (Number.parseInt(element.valorFormatado));
        }

        InfoToolTipo.forEach(Itooltipo => {
          if (element.nome == Itooltipo) {
            valorGrafico["datasets"][0]["extraInfo"].push(element.nome + ": " + Number.parseInt(element.valorFormatado));
          }
        });
        InfoQuadroGeral.forEach(infQuadroGeal => {
          if (element.nome == infQuadroGeal) {
            retCamposGraficos.DadosQuadroGeral.push(element.nome + ": " + Number.parseInt(element.valorFormatado));
          }
        });
        retCamposGraficos = this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico, InfoToolTipo, InfoQuadroGeral, HiddenPadraoValor2, CampoOrdenacao, retCamposGraficos);
      });
    }
    return retCamposGraficos;
  }
  getDataFormatada(dataEmMili: string): string {
    var value = Number.parseInt(dataEmMili);
    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;
  }

  setAtualizacaoGraficos() {
    let login = localStorage.getItem('login');
    const intervalId = setInterval(() => {
      this.atualizandoGraficos = true;
      this.setGraficosLoop(false);
    }, (this.GraficosTempoAtualizacao * 1000)); // 30 min
    this.intervalIds.push(intervalId);
  }

  navigateWithState(Link: string | null, CcFila: string, valor: string) {
    var variaveis = "{\"variaveis\": [{\"CcFila\":\"" + CcFila + "\"},{\"etapa\":\"" + 5 + "\"}]}";
    this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
    var textPos = this.extractMatches(valor);
    window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, variaveis: variaveis, textInd: this.textInd, textPos: textPos, CcPosto: this.CcPosto }
    });
  }

  extractMatches(valor: string): string {
    const regex = /\s\d+\/\d+/g;
    const matches = valor.match(regex);
    if (matches) {
      return " " + matches.join(' ').trim();
    }
    return "";
  }

  //ESSE CODIGO É PARA O CASO DE GRAFICO DO TIPO UNICO PARA TODAS AS FILAS E SERVIÇOS - INATIVA POR AGR PQ NN TEM NENHUM GRAFICO DO TIPO
  // setGraficosUnicos() {

  //   const cores = {
  //     blueGray800: '#37474f',
  //     azul500: '#5886ac',
  //     textColor: '#102a43',
  //     textColorSecondary: '#292222',
  //     colorGrid: '#858486'
  //   };

  //   this.options = {
  //     maintainAspectRatio: false,
  //     aspectRatio: 1.3,
  //     responsive: true,
  //     plugins: {
  //       tooltip: {
  //         mode: 'index',
  //         intersect: true,
  //       },
  //       legend: {
  //         labels: {
  //           color: cores.textColor,
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         stacked: true,
  //         ticks: {
  //           color: cores.textColorSecondary,
  //           //autoSkip: false, // Adicione esta linha para exibir todos os rótulos
  //           //maxRotation: 90, // Ajuste o ângulo de rotação
  //           //minRotation: 60,
  //         },
  //         grid: {
  //           color: cores.colorGrid,
  //           drawBorder: false
  //         }
  //       },
  //       y: [{
  //         stacked: true,
  //         ticks: {
  //           color: cores.textColorSecondary,
  //           callback: function (value: number, index: number, values: number[]) {
  //             const additionalInfoIndex = values.length - 1; // Índice da série "Additional Info"
  //             if (index === additionalInfoIndex) return ''; // Retorna uma string vazia para excluir o valor da série "Additional Info"
  //             return value.toString();
  //           }
  //         },
  //         grid: {
  //           color: cores.colorGrid,
  //           drawBorder: false
  //         }
  //       }],
  //     },
  //   };

  //   let login = localStorage.getItem('login');
  //   var variaveis = "{\"variaveis\": [{\"CcPosto\":\"" + this.CcPosto + "\"}]}";
  //   this.indicadorService.findGraficoAdicional("", 4, 1, this.IdTela, variaveis, login == undefined ? "" : login).pipe(
  //   ).subscribe((response) => {
  //     this.data = response;
  //     this.tempoAtualizacao = response["tempoAtualizacao"];
  //     console.log(this.data);
  //     this.data.forEach(res => {
  //       var GraficosUnicoTempoAtualizacao = res["tempoAtualizacao"];
  //       this.listaInd = [];
  //       this.dataCampos = res["camposGrafico"];
  //       this.dataRet = res["indicadores"];
  //       let i = 0;
  //       this.dataRet.forEach(element => {
  //         var v = element['valor'];
  //         var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", null, null, null, "", null
  //           , new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
  //         var val = new valor(
  //           v['codigo'],
  //           v['nome'],
  //           v['expressao'],
  //           v['icone'],
  //           v['denominador'],
  //           v['nominador'],
  //           v['valorFormatado'],
  //           v['valorSemFormato'],
  //           v['naoMostraCampo'],
  //           v['naoMostraTitulo'],
  //           v['orientacaoLoop'],
  //           v['ehLooping'],
  //           v['ehComponenteGrafico'],
  //           v['componenteGraficoHtml'],
  //           v['componenteGraficoCss'],
  //           v['componenteGraficoPossicao'],
  //           v['ehLink'],
  //           v['linkRedirect'],
  //           v['linkVariaveis'],
  //           v['ordem'],
  //           v['ehCampoCabecalho'],
  //           this.getValores(v['valores'], ind),
  //           v['cor']
  //         )
  //         ind = new indicadorDTO(
  //           element['codigo'],
  //           element['nome'],
  //           element['cor'],
  //           element['etapa'],
  //           element['icone'],
  //           element['isBolinhaAmarela'],
  //           element['isBolinhaVerde'],
  //           element['isBolinhaVermelha'],
  //           element['ordenacaoOrientacao'],
  //           element['proximaEtapa'],
  //           val,
  //           [], [],
  //           element['linkRedirect']
  //         );
  //         ind.valores = this.getValores(element['valores'], ind)
  //         this.listaInd.push(ind);
  //       });
  //       var titulo = '';
  //       var EixoHorizontal = "";
  //       var Legenda1 = "";
  //       var Legenda2 = "";
  //       this.dataCampos.forEach(element => {
  //         if (element["tipoValorGrafico"] == 1)
  //           titulo = element["nome"];
  //         if (element["tipoValorGrafico"] == 3)
  //           EixoHorizontal = element["nome"];
  //         if (element["tipoValorGrafico"] == 4)
  //           Legenda1 = element["nome"];
  //         if (element["tipoValorGrafico"] == 5)
  //           Legenda2 = element["nome"];
  //       });
  //       var data = formatDate(new Date(), 'dd/MM/yyyy', 'en');

  //       var valorGrafico: any = {
  //         labels: [],
  //         datasets: [
  //           {
  //             type: 'bar',
  //             label: '' + Legenda1,
  //             backgroundColor: cores.blueGray800,
  //             data: [],
  //           },
  //         ],
  //       };

  //       var vagas = new VagasDisponiveisModel(
  //         1, "GRAFICO MENSAL", data, "#9e9e9e", "#9e9e9e",
  //         0, 0, "0%", 0,
  //         'bar', valorGrafico, 0, "", "", undefined);
  //       this.listaInd.forEach(element => {
  //         var findCor = this.cores.find(x => x.status == element.cor);
  //         var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;
  //         var DadosHoje = this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
  //         vagas.chartDatasets = valorGrafico;
  //       });
  //       this.GraficosUnicosArray.push(vagas)
  //       console.log(this.GraficosUnicosArray);


  //     });
  //   });
  // }
}

import { CommonModule, formatDate, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  RouterLink,
  RouterOutlet,
  RouterModule,
  RouterLinkActive,
  ActivatedRoute,
  Router,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { VagasDisponiveisModel } from '../../modal/VagasDisponiveisModel';
import { breadcrumb } from '../../modal/breadcrumb';
import { MatDividerModule } from '@angular/material/divider';
import { ChartModule } from 'primeng/chart';
import { valor } from '../../modal/valor';
import { indicadorDTO } from '../../modal/dto/indicadorDTO';
import { IndicadorService } from '../../service/Indicador.service';
import { CORES } from '../../modal/mock-cor';
import { historicoVagasDTO } from '../../modal/dto/historicoVagasDTO';
import { AuthService } from '../../service/auth.service';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt); // Registra o locale 'pt-BR'


@Component({
  selector: 'app-vagas-disponiveis-posto',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    RouterLinkActive,
    MatTooltipModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    FontAwesomeModule,
    MDBBootstrapModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatCardModule,
    MatTableModule,
    MatDividerModule,
    ChartModule
  ],
  templateUrl: './vagas-disponiveis-posto.component.html',
  styleUrl: './vagas-disponiveis-posto.component.scss',
})
export class VagasDisponiveisPostoComponent implements OnInit, OnDestroy {

  public vagasDisponiveisArray: VagasDisponiveisModel[] = [];
  public vagasDisponiveisArrayMenu: VagasDisponiveisModel[] = [];
  chartColors: any = '#2a505f';
  listahitorico: historicoVagasDTO[] = [];
  cores = CORES;
  dataRet = []
  public listaInd: indicadorDTO[] = [];
  dataCampos = []
  public IdTela: number = 0;
  public CcOrgao: string | null;
  public IdRegiao: string | null;
  public variaveis: string | undefined;
  public NomeTela = "";
  public LinkRedirect = "";
  public atualizando: boolean = false;
  tempoAtualizacao: any;
  intervalIds: any[] = [];
  //Primeng
  data = [];
  options: any;
  perfil: any;
  private postosUsuario: number[] | undefined;
  listabreadcrumb: breadcrumb[] = [];
  constructor(private route: ActivatedRoute, private indicadorService: IndicadorService, private router: Router, private authService: AuthService) {
    let tipotela = this.route.snapshot.queryParamMap.get('tipoTela');
    this.IdRegiao = this.route.snapshot.queryParamMap.get('IdRegiao');
    this.IdTela = tipotela == null ? 0 : Number.parseInt(tipotela);
    this.CcOrgao = this.route.snapshot.queryParamMap.get('CcOrgao');
    if (this.CcOrgao != "") {
      this.variaveis = "{\"variaveis\": [{\"CcOrgao\": " + this.CcOrgao + "}, {\"idregiao\":\"" + this.IdRegiao + "\"}]}";
    }
    else
      this.variaveis = "{\"variaveis\": [{\"idregiao\": \"" + this.IdRegiao + "\"}]}";
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }

    const breadcrumbString = window.localStorage.getItem('breadcrumb');
    if (breadcrumbString) {
      var bc = JSON.parse(breadcrumbString);
    }
    if (bc != undefined && bc.length != 0) {
      for (let index = 0; index < bc.length; index++) {
        const element = bc[index];
        this.listabreadcrumb.push(new breadcrumb(element.label, element.url));
      }
      this.listabreadcrumb.push(new breadcrumb("Posto", ""));
      console.log(this.listabreadcrumb);
    }
    else {
      this.listabreadcrumb = [
        new breadcrumb("Dashboard", "/dashboard"),
        new breadcrumb("Regiao", "/vagas-disponiveis-regiao"),
        new breadcrumb("Posto", "")
      ];
    }

    const historicoString = window.localStorage.getItem('historicoVagas');
    if (historicoString) {
      var hv = JSON.parse(historicoString);
    }
    if (hv != undefined && hv.length != 0) {
      for (let index = 0; index < hv.length; index++) {
        const element = hv[index];
        this.listahitorico.push(new historicoVagasDTO(element.nome, element.tipoTela, undefined));
        this.NomeTela = this.NomeTela + element.tipoTela + " " + element.nome;
      }
    }
  }

  ngOnInit() {
    const cores = {
      blueGray800: '#37474f',
      azul500: '#5886ac',
      textColor: '#102a43',
      textColorSecondary: '#292222',
      colorGrid: '#858486'
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 1.3,
      responsive: true,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: true,
        },
        legend: {
          labels: {
            color: cores.textColor,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: cores.textColorSecondary
          },
          grid: {
            color: cores.colorGrid,
            drawBorder: false
          }
        },
        y: [{
          stacked: true,
          ticks: {
            color: cores.textColorSecondary,
            callback: function (value: number, index: number, values: number[]) {
              const additionalInfoIndex = values.length - 1; // Índice da série "Additional Info"
              if (index === additionalInfoIndex) return ''; // Retorna uma string vazia para excluir o valor da série "Additional Info"
              return value.toString();
            }
          },
          grid: {
            color: cores.colorGrid,
            drawBorder: false
          }
        }],
      },
    };

    this.perfil = this.authService.role();
    var retrievedObject = localStorage.getItem('salaSituacao');
      let login = localStorage.getItem('login');
      var retrievedObject = localStorage.getItem('salaSituacao');
      if (retrievedObject != null) {
        var sala = JSON.parse(retrievedObject);
        //console.table(sala['postos']);
        this.postosUsuario = sala['postos'];
        this.indicadorService.findGraficoTelaInteira("posto", 3, 2, this.IdTela, this.variaveis, login == undefined ? "" : login,this.postosUsuario).pipe(
        ).subscribe((response) => {
          this.data = response;
          console.log(this.data);

          this.data.forEach(res => {
            this.listaInd = [];
            this.tempoAtualizacao = res["tempoAtualizacao"];
            this.dataCampos = res["camposGrafico"];
            this.dataRet = res["indicadores"];
            this.LinkRedirect = res["linkRedirect"];
            let i = 0;
            this.dataRet.forEach(element => {
              var v = element['valor'];
              var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null, new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
              var val = new valor(
                v['codigo'],
                v['nome'],
                v['expressao'],
                v['icone'],
                v['denominador'],
                v['nominador'],
                v['valorFormatado'],
                v['valorSemFormato'],
                v['naoMostraCampo'],
                v['naoMostraTitulo'],
                v['orientacaoLoop'],
                v['ehLooping'],
                v['ehComponenteGrafico'],
                v['componenteGraficoHtml'],
                v['componenteGraficoCss'],
                v['componenteGraficoPossicao'],
                v['ehLink'],
                v['linkRedirect'],
                v['linkVariaveis'],
                v['ordem'],
                v['ehCampoCabecalho'],
                this.getValores(v['valores'], ind),
                v['cor']
              )
              ind = new indicadorDTO(
                element['codigo'],
                element['nome'],
                element['cor'],
                element['etapa'],
                element['icone'],
                element['corFarol'],
                element['ordenacaoOrientacao'],
                element['proximaEtapa'],
                val, [], [], ""
              );
              ind.valores = this.getValores(element['valores'], ind)
              this.listaInd.push(ind);
            });
            var titulo = '';
            var EixoHorizontal = "";
            var Legenda1 = "";
            var Legenda2 = "";
            this.dataCampos.forEach(element => {
              if (element["tipoValorGrafico"] == 1)
                titulo = element["nome"];
              if (element["tipoValorGrafico"] == 3)
                EixoHorizontal = element["nome"];
              if (element["tipoValorGrafico"] == 4)
                Legenda1 = element["nome"];
              if (element["tipoValorGrafico"] == 5)
                Legenda2 = element["nome"];
            });
            var data = formatDate(new Date(), 'dd/MM/yyyy', 'en');
            this.listaInd.forEach(element => {
              var valorGrafico: any = {
                labels: [],
                datasets: [
                  {
                    type: 'bar',
                    label: '' + Legenda1,
                    backgroundColor: cores.blueGray800,
                    data: [],
                  },
                  {
                    type: 'bar',
                    label: '' + Legenda2,
                    data: [],
                    backgroundColor: cores.azul500,// Cor de fundo transparente
                  },
                ],
              };
              var DadosHoje = this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
              var VagasAbertasHoje = DadosHoje?.valores.find(x => x.nome == "vagas abertas")
              var VagasTotalHoje = DadosHoje?.valores?.find(x => x.nome == "Total de vagas")
              var VagasAgendadasHoje = DadosHoje?.valores?.find(x => x.nome == "Vagas agendadas")
              var VagasUtilizadasHoje = DadosHoje?.valores.find(x => x.nome == "VAGAS UTILIZADAS")
              var findCor = this.cores.find(x => x.status == element.cor);
              var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;
              var vagas = new VagasDisponiveisModel(
                1, element.valor.valorFormatado, data, cor, element.cor,
                VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato,
                VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato,
                VagasUtilizadasHoje?.valorSemFormato == undefined || isNaN(parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString())) 
                ? "0%" 
                : parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()).toLocaleString('pt-BR', { 
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2 
                  }) + "%",
                VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato,
                'bar', valorGrafico,
                element.codigo == null ? 0 : element.codigo
                , element.valor.valorSemFormato + "", this.LinkRedirect, element
              )

              this.vagasDisponiveisArray.push(vagas)

            });


            this.vagasDisponiveisArray.sort((a, b) => a.nomeOrgao.localeCompare(b.nomeOrgao));
            this.vagasDisponiveisArrayMenu = this.vagasDisponiveisArray;
          });
          this.setAtualizacaoindicadores();
        });
      }
  }

  setValores(valores: valor[] | undefined, EixoHorizontal: string, Legenda1: string, Legenda2: string, valorGrafico: any): valor | undefined {
    var DadosHoje: valor | undefined = undefined;
    if (valores) {
      valores.forEach(element => {
        if (element.nome == EixoHorizontal) {
          valorGrafico["labels"].push(this.getDataFormatada(element.valorFormatado));
          if (this.isToday(element.valorFormatado)) {
            DadosHoje = element;
          }
        }
        if (element.nome == Legenda1) {
          valorGrafico["datasets"][0]["data"].push(element.valorSemFormato);
        }

        if (element.nome == Legenda2) {
          valorGrafico["datasets"][1]["data"].push(element.valorSemFormato);
        }
        this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
      });
    }
    return DadosHoje;
  }

  isToday(dateInMillis: string): boolean {
    const date = new Date(parseInt(dateInMillis, 10));
    const today = new Date();
    const dateToCheck = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    return dateToCheck.getUTCDate() === today.getDate() &&
      dateToCheck.getUTCMonth() === today.getMonth() &&
      dateToCheck.getUTCFullYear() === today.getFullYear();
  }

  getDataFormatada(dataEmMili: string): string {
    var value = Number.parseInt(dataEmMili);
    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;
  }

  getValores(response: [], ind: indicadorDTO): valor[] {
    if (response == undefined) {
      return [];
    }
    var listavalores: valor[] = [];
    response.forEach(element => {
      var val = new valor(
        element['codigo'],
        element['nome'],
        element['expressao'],
        element['icone'],
        element['denominador'],
        element['nominador'],
        element['valorFormatado'],
        element['valorSemFormato'],
        element['naoMostraCampo'],
        element['naoMostraTitulo'],
        element['orientacaoLoop'],
        element['ehLooping'],
        element['ehComponenteGrafico'],
        element['componenteGraficoHtml'],
        element['componenteGraficoCss'],
        element['componenteGraficoPossicao'],
        element['ehLink'],
        element['linkRedirect'],
        element['linkVariaveis'],
        element['ordem'],
        element['ehCampoCabecalho'],
        this.getValores(element['valores'], ind),
        element['cor']
      )
      if (val.ehComponenteGrafico) {
        if (ind.listaComponentesGraficos == undefined)
          ind.listaComponentesGraficos = [];
        if (ind.listaComponentesGraficos.find(x =>
          x.valorFormatado == val.valorFormatado &&
          val.componenteGraficoCss == x.componenteGraficoCss &&
          val.componenteGraficoHtml == x.componenteGraficoHtml &&
          x.componenteGraficoPossicao == val.componenteGraficoPossicao) == null) // checa se ja não colocou o mesmo componente grafico na lista
          ind.listaComponentesGraficos.push(val);
      }

      listavalores.push(val);
    });
    return listavalores;
  }

  ngOnDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
  }

  setAtualizacaoindicadores() {
    const cores = {
      blueGray800: '#37474f',
      azul500: '#5886ac',
      textColor: '#102a43',
      textColorSecondary: '#292222',
      colorGrid: '#858486'
    };


    let login = localStorage.getItem('login');
    const intervalId = setInterval(() => {
      this.atualizando = true;
      this.indicadorService.findGraficoTelaInteira("posto", 3, 2, this.IdTela, this.variaveis, login == undefined ? "" : login, this.postosUsuario).pipe(
      ).subscribe((response) => {
        this.data = response;
        console.log(this.data);
        this.listaInd = [];
        this.data.forEach(res => {
          this.listaInd = [];
          this.dataCampos = res["camposGrafico"];
          this.dataRet = res["indicadores"];
          this.LinkRedirect = res["linkRedirect"];
          let i = 0;
          this.dataRet.forEach(element => {
            var v = element['valor'];
            var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null, new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
            var val = new valor(
              v['codigo'],
              v['nome'],
              v['expressao'],
              v['icone'],
              v['denominador'],
              v['nominador'],
              v['valorFormatado'],
              v['valorSemFormato'],
              v['naoMostraCampo'],
              v['naoMostraTitulo'],
              v['orientacaoLoop'],
              v['ehLooping'],
              v['ehComponenteGrafico'],
              v['componenteGraficoHtml'],
              v['componenteGraficoCss'],
              v['componenteGraficoPossicao'],
              v['ehLink'],
              v['linkRedirect'],
              v['linkVariaveis'],
              v['ordem'],
              v['ehCampoCabecalho'],
              this.getValores(v['valores'], ind),
              v['cor']
            )
            ind = new indicadorDTO(
              element['codigo'],
              element['nome'],
              element['cor'],
              element['etapa'],
              element['icone'],
              element['corFarol'],
              element['ordenacaoOrientacao'],
              element['proximaEtapa'],
              val, [], [], ""
            );
            ind.valores = this.getValores(element['valores'], ind)
            this.listaInd.push(ind);
          });
          var titulo = '';
          var EixoHorizontal = "";
          var Legenda1 = "";
          var Legenda2 = "";
          this.dataCampos.forEach(element => {
            if (element["tipoValorGrafico"] == 1)
              titulo = element["nome"];
            if (element["tipoValorGrafico"] == 3)
              EixoHorizontal = element["nome"];
            if (element["tipoValorGrafico"] == 4)
              Legenda1 = element["nome"];
            if (element["tipoValorGrafico"] == 5)
              Legenda2 = element["nome"];
          });
          var data = formatDate(new Date(), 'dd/MM/yyyy', 'en');
          this.vagasDisponiveisArray = [];
          this.listaInd.forEach(element => {
            var valorGrafico: any = {
              labels: [],
              datasets: [
                {
                  type: 'bar',
                  label: '' + Legenda1,
                  backgroundColor: cores.blueGray800,
                  data: [],
                },
                {
                  type: 'bar',
                  label: '' + Legenda2,
                  data: [],
                  backgroundColor: cores.azul500,// Cor de fundo transparente
                },
              ],
            };
            var DadosHoje = this.setValores(element.valores, EixoHorizontal, Legenda1, Legenda2, valorGrafico);
            var VagasAbertasHoje = DadosHoje?.valores.find(x => x.nome == "vagas abertas")
            var VagasTotalHoje = DadosHoje?.valores?.find(x => x.nome == "Total de vagas")
            var VagasAgendadasHoje = DadosHoje?.valores?.find(x => x.nome == "Vagas agendadas")
            var VagasUtilizadasHoje = DadosHoje?.valores.find(x => x.nome == "VAGAS UTILIZADAS")
            var findCor = this.cores.find(x => x.status == element.cor);
            var cor = findCor == undefined ? "#9e9e9e" : findCor.fundoCor;
            var vagas = new VagasDisponiveisModel(
              1, element.valor.valorFormatado, data, cor, element.cor,
              VagasTotalHoje?.valorSemFormato == undefined ? 0 : VagasTotalHoje?.valorSemFormato,
              VagasAgendadasHoje?.valorSemFormato == undefined ? 0 : VagasAgendadasHoje?.valorSemFormato,
              VagasUtilizadasHoje?.valorSemFormato == undefined || isNaN(parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString())) 
              ? "0%" 
              : parseFloat(VagasUtilizadasHoje?.valorSemFormato.toString()).toLocaleString('pt-BR', { 
                  minimumFractionDigits: 2, 
                  maximumFractionDigits: 2 
                }) + "%",
              VagasAbertasHoje?.valorSemFormato == undefined ? 0 : VagasAbertasHoje?.valorSemFormato,
              'bar', valorGrafico,
              element.codigo == null ? 0 : element.codigo
              , element.valor.valorSemFormato + "", this.LinkRedirect, element
            )

            this.vagasDisponiveisArray.push(vagas)

          });


          this.vagasDisponiveisArray.sort((a, b) => a.nomeOrgao.localeCompare(b.nomeOrgao));
          this.vagasDisponiveisArrayMenu = this.vagasDisponiveisArray;
        });
        this.atualizando = false;
      });
    }, (this.tempoAtualizacao * 1000));
    this.intervalIds.push(intervalId);
  }



  navigateWithState(Link: string | null, IdTela: number, CcPosto: string) {
    this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
    window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));

    var vaga = this.vagasDisponiveisArray.find(x => x.CodigoCentralizado == CcPosto);
    if (vaga != undefined)
      this.listahitorico.push(new historicoVagasDTO(vaga.nomeOrgao, " do posto", vaga));
    window.localStorage.setItem('historicoVagas', JSON.stringify(this.listahitorico));
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: IdTela, CcOrgao: this.CcOrgao, CcPosto: CcPosto, Etapa: 4, IdRegiao: this.IdRegiao }
    });
  }

  navigateWithStateBC(Link: string | null, nome: string | null) {

    if (Link != null) {
      const index = this.listabreadcrumb.findIndex(b => b.url === Link);
      if (index !== -1) {
        this.listabreadcrumb = this.listabreadcrumb.slice(0, index);
      }
      window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    }

    if (nome != null) {
      const index = this.listahitorico.findIndex(b => b.nome.toLowerCase().trim().includes(nome.toLowerCase().trim()));
      console.log(nome);
      if (index !== -1) {
        this.listahitorico = this.listahitorico.slice(0, index);
      }
      else {
        const index = this.listahitorico.findIndex(b => b.tipoTela.toLowerCase().trim().includes(nome.toLowerCase().trim()));
        if (index !== -1) {
          this.listahitorico = this.listahitorico.slice(0, index);
        }
      }
      window.localStorage.setItem('historicoVagas', JSON.stringify(this.listahitorico));
    }

    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, CcOrgao: this.CcOrgao }
    });
  }
  onKey(event: any) {
    //console.log(event.target.value);
    this.vagasDisponiveisArrayMenu = this.vagasDisponiveisArray.filter(x => x.nomeOrgao.toLowerCase().includes(event.target.value.toLowerCase()))
  }
}


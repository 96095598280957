import { posto } from "../regional/posto";
import { regiao } from "../regional/regiao";

export class regiaoPostoFiltroDTO {
    public regiao: regiao | undefined = undefined;
    public postos: posto[] = [];
    public open: boolean= false;
    public mostrarTudo: boolean= false;
    constructor() {
    }
  }
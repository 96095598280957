import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { indicador } from '../../../../modal/Indicador';
import { IndicadorService } from '../../../../service/Indicador.service';
import { CalculoCores } from '../../../../modal/CalculoCores';
import { Campos } from '../../../../modal/Campos';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDragPreview,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-mudar-posicao-indicador',
  standalone: true,
  imports: [
    CdkDropList, CdkDrag, CdkDragPreview,
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './mudar-posicao-indicador.component.html',
  styleUrl: './mudar-posicao-indicador.component.scss'
})

export class MudarPosicaoIndicadorComponent implements OnInit {
  public listaIndEtapa1: indicador[] = [];
  data = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private indicadorService: IndicadorService,
    public dialog: MatDialog) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  ngOnInit() {

    this.indicadorService.getIndicadorAtapa1SemGrafico().pipe(
    ).subscribe((response) => {
      this.data = response["Indicador"];

      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        var Ind = new indicador(
          element['id'],
          element['codigo'],
          element['etapa'],
          element['proximaetapa'],
          element['tipo_resultado'],
          element['nome'],
        );
        Ind.ordemDashboard = element['ordemDashboard'];
        this.listaIndEtapa1.push(Ind);
      });
      this.listaIndEtapa1 = this.listaIndEtapa1.sort((a, b) => {       
        if (a.ordemDashboard === undefined) return 1;       
        if (b.ordemDashboard === undefined) return -1;       
        return a.ordemDashboard - b.ordemDashboard; });
    });
  }

  submitForm() {
    var index = 1;
    this.listaIndEtapa1.forEach(element => {
      element.ordemDashboard = index;
      index++;
    });

    this.indicadorService.alterarPosições(this.listaIndEtapa1).subscribe(() => {
      console.log('Posições alteradas com sucesso');
      alert("Salvo com sucesso!");
    }, error => {
      console.error('Erro:', error);
    });
  }

  drop(event: CdkDragDrop<indicador>) {
    moveItemInArray(this.listaIndEtapa1, event.previousIndex, event.currentIndex);
    console.log(this.listaIndEtapa1);
  }
}
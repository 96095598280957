import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  OnInit,
  Inject
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ETheme } from '../enums/ETheme.enum';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertaVagasComponent } from '../utilidades/alerta-vagas/alerta-vagas.component';
import { AlarmeService } from '../service/alarme.service';
import { indicadorDTO } from '../modal/dto/indicadorDTO';
import { valor } from '../modal/valor';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    CommonModule,
    // * MATERIAL IMPORTS
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    SidenavComponent,
    AlertaVagasComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  @Output() themeToggle = new EventEmitter<string>();
  salaSituacao: any;
  data = [];
  tempoAtualizacao: any;
  dataRet = [];
  dataCampos = [];
  public listaInd: indicadorDTO[] = [];
  public openalerta: boolean = false;
  onToggleTheme(theme: string) {
    this.themeToggle.emit(theme);
  }
  // Se o icon for sun o tema recebe light
  // Se o icon for moon o tema recebe dark, e
  public icon: any;
  public iconSun = ETheme.ICON_SUN;
  public iconMoon = ETheme.ICON_MOON;
  login: string | null | undefined
  numeroAlertas: number = 0;
  VisualizacaoPadrao: string = "LIGHT";
  public themeClass: string = this.VisualizacaoPadrao == "DARK" ? 'main maindark' : 'main';
  @ViewChild(AlertaVagasComponent) alertComponent!: AlertaVagasComponent;


  constructor(public dialog: MatDialog, public router: Router, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private oauthService: OAuthService, private authService: AuthService, private alarmeService: AlarmeService) {
    const localStorage = document.defaultView?.localStorage;

    if (localStorage) {

      try {
        this.login = localStorage.getItem('login');
      }
      catch (ex) {
        this.login = undefined;
      }
      var NewVisu = localStorage.getItem('MODOTELA');
      this.VisualizacaoPadrao = NewVisu == null ? "LIGHT" : NewVisu;
    }
  }

  ngOnInit() {

    (async () => {
      this.login = undefined;
      try {
        await new Promise(f => setTimeout(f, 500));
        let flogin = localStorage.getItem('forca-login');
        if (flogin != "true") {
          let token = this.oauthService.getAccessToken();
          if (this.oauthService.hasValidAccessToken()) {

            let claims = this.oauthService.getIdentityClaims();
            if (claims) {
              localStorage.setItem('deslogar', "false");
              localStorage.setItem('token', token);

              this.authService.getRoles(claims['preferred_username']).pipe(
              ).subscribe((response) => {
                //console.table(response);
                localStorage.setItem('login', response.nome);
                this.salaSituacao = response['meusDados']['roles']['salaSituacao'];
                //console.log(this.salaSituacao['perfil']);
                localStorage.setItem('salaSituacao', JSON.stringify(this.salaSituacao));
                let login = response.nome;
                if (this.salaSituacao != null) {
                  var postosUsuario = this.salaSituacao['postos'];
                  this.getAlerta(postosUsuario, login);
                }

              });

              this.login = localStorage.getItem('login');
            } else {
              this.removerLogin("false");
            }
          } else {
            this.removerLogin("false");
          }
        }
        else {
          this.login = localStorage.getItem('login');
          var retrievedObject = localStorage.getItem('salaSituacao');
          if (retrievedObject != null) {
            var sala = JSON.parse(retrievedObject);
            var postosUsuario = sala['postos'];
            var login = localStorage.getItem('login')
            this.getAlerta(postosUsuario, login == null ? undefined : login);
          }
        }
      }
      catch (ex) {
        this.removerLogin("false");
      }

    })();
    //localStorage.setItem('token', "token");
    //localStorage.setItem('login', "ENTRA A FORÇA");
  }

  getAlerta(postosUsuario: any, login: undefined | string) {
    this.alarmeService.getAlertas(postosUsuario, login == undefined ? "" : login).pipe(
    ).subscribe((responsealarme) => {
      this.numeroAlertas = responsealarme["indicadores"].length;
      this.tempoAtualizacao = responsealarme["tempoAtualizacao"];
      this.listaInd = [];
      this.dataCampos = responsealarme["camposGrafico"];
      this.dataRet = responsealarme["indicadores"];
      let i = 0;
      this.dataRet.forEach(element => {
        var v = element['valor'];
        var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "","", "", null
          , new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
        var val = new valor(
          v['codigo'],
          v['nome'],
          v['expressao'],
          v['icone'],
          v['denominador'],
          v['nominador'],
          v['valorFormatado'],
          v['valorSemFormato'],
          v['naoMostraCampo'],
          v['naoMostraTitulo'],
          v['orientacaoLoop'],
          v['ehLooping'],
          v['ehComponenteGrafico'],
          v['componenteGraficoHtml'],
          v['componenteGraficoCss'],
          v['componenteGraficoPossicao'],
          v['ehLink'],
          v['linkRedirect'],
          v['linkVariaveis'],
          v['ordem'],
          v['ehCampoCabecalho'],
          this.getValores(v['valores'], ind),
          v['cor']
        )
        ind.valor = val;
        ind.valores = this.getValores(element['valores'], ind)
        this.listaInd.push(ind);
      });

      this.setAtualizacaoalerta(postosUsuario, login == undefined ? "" : login);
    });
  }

  AbrirAlertas() {
    this.alertComponent.abrirAlerta();
  }

  logar() {

    localStorage.setItem('forca-login', "false");
    this.oauthService.initImplicitFlow();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  retornoParaHome() {
    this.router.navigate(['dashboard']);
  }

  AlterarModoTela(newvisu: string) {
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      //localStorage.setItem('VISUPADRAO', newvisu);
      localStorage.setItem('MODOTELA', newvisu);
      this.VisualizacaoPadrao = newvisu;
    }
    this.themeClass = this.VisualizacaoPadrao == "DARK" ? 'main maindark' : 'main';
    this.onToggleTheme(newvisu);
  }

  deslogar() {
    let flogin = localStorage.getItem('forca-login');
    if (flogin == "true") {
      localStorage.clear();
      localStorage.setItem('forca-login', "false");
      this.router.navigate(['/iniciar']);
    }
    else {
      this.alertComponent.closeChat();
      this.oauthService.logOut();
      this.removerLogin("true");
    }
  }

  removerLogin(EhuserDeslogar: string) {
    console.log("removerLogin")
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      //localStorage.removeItem('login');
      //localStorage.removeItem('token');
      localStorage.clear();
      this.login = undefined;
      this.salaSituacao = undefined;
      localStorage.setItem('deslogar', EhuserDeslogar);
      //this.router.navigate(['/iniciar']);
    }
  }

  public role() {
    var local: any = localStorage.getItem('salaSituacao');
    local = JSON.parse(local);
    var roles = local.perfil;
    return roles
  }

  setAtualizacaoalerta(postosUsuario: number[] | undefined, login: string) {

    const intervalId = setInterval(() => {
      this.alarmeService.getAlertas(postosUsuario, login == undefined ? "" : login).pipe(
      ).subscribe((responsealarme) => {
        console.log("TEMPO", responsealarme);
        this.numeroAlertas = responsealarme["indicadores"].length;
        this.tempoAtualizacao = responsealarme["tempoAtualizacao"];
        this.listaInd = [];
        this.dataCampos = responsealarme["camposGrafico"];
        this.dataRet = responsealarme["indicadores"];
        let i = 0;
        this.dataRet.forEach(element => {
          var v = element['valor'];
          var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null
            , new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], [], "");
          var val = new valor(
            v['codigo'],
            v['nome'],
            v['expressao'],
            v['icone'],
            v['denominador'],
            v['nominador'],
            v['valorFormatado'],
            v['valorSemFormato'],
            v['naoMostraCampo'],
            v['naoMostraTitulo'],
            v['orientacaoLoop'],
            v['ehLooping'],
            v['ehComponenteGrafico'],
            v['componenteGraficoHtml'],
            v['componenteGraficoCss'],
            v['componenteGraficoPossicao'],
            v['ehLink'],
            v['linkRedirect'],
            v['linkVariaveis'],
            v['ordem'],
            v['ehCampoCabecalho'],
            this.getValores(v['valores'], ind),
            v['cor']
          )
          ind.valor = val;
          ind.valores = this.getValores(element['valores'], ind)
          this.listaInd.push(ind);
        });
        this.alertComponent.setdados(this.listaInd);
      });
    }, (this.tempoAtualizacao * 1000));
  }

  getValores(response: [], ind: indicadorDTO): valor[] {
    if (response == undefined) {
      return [];
    }
    var listavalores: valor[] = [];
    response.forEach(element => {
      var val = new valor(
        element['codigo'],
        element['nome'],
        element['expressao'],
        element['icone'],
        element['denominador'],
        element['nominador'],
        element['valorFormatado'],
        element['valorSemFormato'],
        element['naoMostraCampo'],
        element['naoMostraTitulo'],
        element['orientacaoLoop'],
        element['ehLooping'],
        element['ehComponenteGrafico'],
        element['componenteGraficoHtml'],
        element['componenteGraficoCss'],
        element['componenteGraficoPossicao'],
        element['ehLink'],
        element['linkRedirect'],
        element['linkVariaveis'],
        element['ordem'],
        element['ehCampoCabecalho'],
        this.getValores(element['valores'], ind),
        element['cor']
      )
      listavalores.push(val);
    });
    return listavalores;
  }
}



/*

 onClickToggleDarkTheme() {
        const evento = this.toggleDarkTheme.emit();
        // if( this.isDarkTheme ){
        //     this.icon = ETheme.ICON_MOON;
        //     this.toggleDarkTheme.emit();
        // }
        //return evento
    }

    // public toggleDarkTheme() {
    // const body = this.renderer.selectRootElement('body');
    // const isDarkTheme = body.classList.contains('moon-dark-theme');
    // // Alterna entre os temas
    // body.classList.toggle('moon-dark-theme');
    // body.classList.toggle('sun-light-theme');
    // // Atualiza o ícone conforme o tema selecionado
    // if (isDarkTheme) {
    //     this.icon = ETheme.ICON_MOON; // Se estava no tema dark, troca para o ícone de lua
    // } else {
    //     this.icon = ETheme.ICON_SUN; // Se estava no tema light, troca para o ícone de sol
    // }
    // // Define o background-color conforme o tema selecionado
    // const themeColor = isDarkTheme ? 'var(--body-light)' : 'var(--body-dark)';
    // this.renderer.setStyle(body, 'background-color', themeColor);
    // }



AQUI FUNCIONA
public toggleDarkTheme(){
        let theme = document.body.classList.toggle('moon-dark-theme');
        if( theme ){
            return this.icon = ETheme.ICON_SUN;
        }
        return this.icon = ETheme.ICON_MOON;
    }

 public buttonThemeDARK(){
    this.buttonDark.nativeElement.classList.toggle('sun-light-theme')
    if( this.buttonDark ){
        this.buttonDark.nativeElement.classList.toggle('moon-dark-theme')
        return this.icon = ETheme.ICON_SUN
    }
    return (this.icon = ETheme.ICON_MOON)
  }
*/

export class valor {
  public nome: string;
  public codigo: number | null;
  public denominador: number;
  public expressao: string;
  public icone: string;
  public nominador: number;
  public valorFormatado: string;
  public valorSemFormato: number;
  public naoMostraCampo: boolean | null;
  public naoMostraTitulo: boolean | null;
  public ehLooping: boolean | null;
  public ehCampoCabecalho: boolean | null;
  public orientacaoLoop: string | null;
  public ehComponenteGrafico: boolean | null;
  public componenteGraficoCss: string | null;
  public componenteGraficoHtml: string | null;
  public componenteGraficoPossicao: string | null;
  public ordem: number;
  public ehLink: boolean | null;
  public linkRedirect: string | null;
  public linkVariaveis: string | null;
  public valores: valor[]; // Valores podem ser um array de valores
  public cor: string | null;
  constructor(codigo: number | null = null,
    nome: string,
    expressao: string,
    icone: string,
    denominador: number,
    nominador: number,
    valorFormatado: string,
    valorSemFormato: number,
    naoMostraCampo: boolean | null,
    naoMostraTitulo: boolean | null,
    orientacaoLoop: string | null,
    ehLooping: boolean | null,
    ehComponenteGrafico: boolean | null = null,
    componenteGraficoHtml: string | null = null,
    componenteGraficoCss: string | null = null,
    componenteGraficoPossicao: string | null = null,
    ehLink: boolean | null = null,
    linkRedirect: string | null = null,
    linkVariaveis: string | null = null,
    ordem: number,
    ehCampoCabecalho: boolean | null = null,
    valores: valor[],
    cor: string | null) {
    this.codigo = codigo;
    this.nome = nome;
    this.expressao = expressao;
    this.icone = icone;
    this.denominador = denominador;
    this.nominador = nominador;
    this.valorFormatado = valorFormatado;
    this.valorSemFormato = valorSemFormato;
    this.naoMostraCampo = naoMostraCampo;
    this.naoMostraTitulo = naoMostraTitulo;
    this.orientacaoLoop = orientacaoLoop;
    this.ehLooping = ehLooping;
    this.ehComponenteGrafico = ehComponenteGrafico;
    this.componenteGraficoHtml = componenteGraficoHtml;
    this.componenteGraficoCss = componenteGraficoCss;
    this.componenteGraficoPossicao = componenteGraficoPossicao;
    this.ehLink = ehLink;
    this.linkRedirect = linkRedirect;
    this.linkVariaveis = linkVariaveis;
    this.ordem = ordem;
    this.ehCampoCabecalho = ehCampoCabecalho;
    this.valores = valores;
    this.cor = cor;
  }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number | undefined): string {
    if (!value) return '00:00';
    
    const totalSeconds = Math.floor(value / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${this.padZero(hours)}:${this.padZero(minutes)}hr`;
    } else if (minutes > 0) {
      return `${this.padZero(minutes)}:${this.padZero(seconds)}min`;
    } else {
      return `${seconds} seg`;
    }
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }
}
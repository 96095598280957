<div id="live-chat" class="live-chat">
    <button class="chat-close" (click)="closeChat()"><i class="fa-solid fa-xmark"></i></button>
    <header (click)="toggleChat()" class="clearfix">
        <h4 style="font-weight: bold;">Alerta Agendamento</h4>
    </header>
    <div class="chat" *ngIf="chatVisible">
        <div class="chat-history">
            @for (a of DadosAlerta; track $index) {
            <div class="chat-message clearfix">
                <div class="chat-message-content clearfix">
                    <a (click)="VerDetalhe(a.valor.valorSemFormato)"><h5 style="font-weight: bold;">{{a.valor.valorFormatado}}</h5></a>
                    @for (b of a.valores; track $index) {
                    @for (c of b.valores; track $index) {
                    <span class="chat-time">{{c.valorSemFormato}} dias em espera</span>
                    }
                    <p>{{b.valorFormatado}}</p>
                    }
                </div>
            </div>
            <hr>
            }
        </div>
    </div>
</div>
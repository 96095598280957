<!-- Criar um Componente para cards -->
<div id="color-box" class="color-box"></div>
<section class="sectionInd">

<div #menu [class.fixed]="isFixed" class="menu">
  <p style="margin-bottom:0px ;" *ngIf="receivedDescPosto!='' && receivedDescPosto!=undefined">Dashboard por: {{receivedDescPosto}} <button (click)="tirarFiltroPosto()"><i class="fas fa-times"></i></button></p>
</div>
  <div style="display: grid; justify-content: center; align-items: center;" *ngIf="CarregandoTodosIndicadores === true">
    <p>Aguarde...</p>
  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
 </svg>
</div>
  <div class="cards-containers">
    <div *ngFor="let ind of listaInd">
      @if(perfil == 'posto')
      {
      <button class="cards-layout-CidEspera" (click)="navigateWithState('/indicador-posto',ind.id, ind.nome)">
        <div>

          <mat-card class="mat-card-dark"
            [ngStyle]="{'background-color': ind.cor.fundoCor,'width.px': maiorTamanhoTitulo * 8}">
            <div class="title-card" [ngStyle]="{'background-color': ind.cor.cabecalhoCor}">
              <div *ngIf="ind.atualizando" class="loader loader--style8" title="7">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px"
                  viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                      repeatCount="indefinite" />
                  </rect>
                  <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                      repeatCount="indefinite" />
                  </rect>
                  <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                      repeatCount="indefinite" />
                  </rect>
                </svg>
              </div>
              {{ind.nome}}
            </div>
            <div style="display: flex; margin-right: 5px;">
              <div class="Ind_icone">
                <i [classList]="ind.icone"
                  style="color: #ffffff;;transform: scale(1.7,1.7); --fa-secondary-opacity:0.7;"></i>

              </div>
              <div class="card-content">
                <h2>{{ind.valor}}</h2>
              </div>
              <!--Semaforo-->
              <div style="display: flex; justify-content: center; text-align: center;">
                <div class="semaforo">
                  @if (ind.corfarol != undefined) {
                  <div>
                    <div class="sinal" [ngStyle]="{'background-color': ind.corfarol.cabecalhoCor}"></div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </button>
      }
      @else {
        @if(ind.linkRedirect !="")
        {
      <button class="cards-layout-CidEspera" (click)="navigateWithState(ind.linkRedirect,ind.id, ind.nome)">
        <div>

          <mat-card class="mat-card-dark"
            [ngStyle]="{'background-color': ind.cor.fundoCor,'width.px': maiorTamanhoTitulo * 8}">
            <div class="title-card" [ngStyle]="{'background-color': ind.cor.cabecalhoCor}">
              <div *ngIf="ind.atualizando" class="loader loader--style8" title="7">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px"
                  viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                      repeatCount="indefinite" />
                  </rect>
                  <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                      repeatCount="indefinite" />
                  </rect>
                  <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                      repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                      repeatCount="indefinite" />
                  </rect>
                </svg>
              </div>
              {{ind.nome}}
            </div>
            <div style="display: flex; margin-right: 5px;">
              <div class="Ind_icone">
                <i [classList]="ind.icone"
                  style="color: #ffffff;;transform: scale(1.7,1.7); --fa-secondary-opacity:0.7;"></i>
              </div>
              <div class="card-content">
                <h2>{{ind.valor}}</h2>
              </div>
              <!--Semaforo-->
              <div style="display: flex; justify-content: center; text-align: center;">
                <div class="semaforo">
                  @if (ind.corfarol != undefined) {
                  <div>
                    <div class="sinal" [ngStyle]="{'background-color': ind.corfarol.cabecalhoCor}"></div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </button>
      }
      @else
      {
        <div class="cards-layout-CidEspera">
          <div>
  
            <mat-card class="mat-card-dark"
              [ngStyle]="{'background-color': ind.cor.fundoCor,'width.px': maiorTamanhoTitulo * 8}">
              <div class="title-card" [ngStyle]="{'background-color': ind.cor.cabecalhoCor}">
                <div *ngIf="ind.atualizando" class="loader loader--style8" title="7">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px"
                    viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                    </rect>
                    <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                    </rect>
                    <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                    </rect>
                  </svg>
                </div>
                {{ind.nome}}
              </div>
              <div style="display: flex; margin-right: 5px;">
                <div class="Ind_icone">
                  <i [classList]="ind.icone"
                    style="color: #ffffff;;transform: scale(1.7,1.7); --fa-secondary-opacity:0.7;"></i>
                </div>
                <div class="card-content">
                  <h2>{{ind.valor}}</h2>
                </div>
                <!--Semaforo-->
                <div style="display: flex; justify-content: center; text-align: center;">
                  <div class="semaforo">
                    @if (ind.corfarol != undefined) {
                    <div>
                      <div class="sinal" [ngStyle]="{'background-color': ind.corfarol.cabecalhoCor}"></div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      }
    }

    </div>

  </div>
</section>

<app-vagas-disponiveis></app-vagas-disponiveis>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TamanhoTituloService {
  maiorTamanhoTitulo: number = 0;
  private chaveArmazenamento = 'maiorTamanhoTitulo';

  setMaiorTamanhoTitulo(tamanho: number) {
    localStorage.setItem(this.chaveArmazenamento, tamanho.toString());
    this.maiorTamanhoTitulo = tamanho;
  }

  getMaiorTamanhoTitulo(): number {
    const tamanhoArmazenado = localStorage.getItem(this.chaveArmazenamento);
    return tamanhoArmazenado ? parseInt(tamanhoArmazenado, 10) : 31;
  }
}
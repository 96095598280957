import { cormodal } from './cormodal';
import { farolmodal } from './farolmodal';
export class boxInfo {
    public id: string;
    public nome: string;
    public linkRedirect: string;
    public cor: cormodal;
    public valor: string;
    public selecionadoFiltro: boolean;
    public icone: string;
    public corfarol: cormodal | undefined;
    public atualizando: boolean;
    public valorNum: number = 0;
  
    constructor(id: string, 
                nome: string, 
                linkRedirect: string, 
                cor: cormodal = new cormodal("Defalt","#9e9e9e","#818181",2), 
                valor: string= '', 
                selecionadoFiltro: boolean= false, 
                icone: string= '',
                corfarol: cormodal| undefined =undefined,
                atualizando: boolean = false) {
      this.id = id;
      this.nome = nome;
      this.linkRedirect = linkRedirect;
      this.cor = cor;
      this.valor = valor;
      this.selecionadoFiltro = selecionadoFiltro;
      this.icone = icone;
      this.corfarol = corfarol;
      this.atualizando = atualizando;
    }  
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class IndicadorOrgaoService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.orgao";

  getOrgao(codigo: number, codigoCentralPosto: string, login: string): Observable<any> {
    const url = this.apiUrl+this.map+'/getOrgaoIndicador?id='+codigo+'&ccPosto='+codigoCentralPosto+'&login='+login;
    return this.http.get(url);
  }
}

import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    Router,
    RouterLink,
    RouterLinkActive,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { HeaderComponent } from '../header/header.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoadingIndicadoresService } from './loading-indicadores.service';

@Component({
    selector: 'app-loading-indicadores',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        RouterLink,
        RouterOutlet,
        RouterModule,
        RouterLinkActive,
        MatSidenavModule,
    ],
    templateUrl: './loading-indicadores.component.html',
    styleUrl: './loading-indicadores.component.scss',
})
export class LoadingIndicadoresComponent  implements OnInit {
    isLoading = false;

    constructor(private loadingService: LoadingIndicadoresService) {}
  
    ngOnInit(): void {
      this.loadingService.isLoading$.subscribe((loading) => {
        this.isLoading = loading;
      });
    }
}

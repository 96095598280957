import { RequestOptions } from './../modal/RequestOptions';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  constructor(private http: HttpClient) { }

  private apiUrl = environment.API_URL;
  private map = this.apiUrl+"ss.relatorios";

  findRelatorio(id: number, filtro: any): Observable<any> {
    return this.http.post<any>(`${this.map}/findRelatorios/codigo=${id}`, filtro );
  }

  findById(codigo: number): Observable<any> {
    return this.http.get<any>(`${this.map}/findbyId/${codigo}`);
  }
}

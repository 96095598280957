<app-loading-indicadores></app-loading-indicadores>
<app-sidenav [style.display]="hideDiv ? 'none' : 'block'" [isDarkTheme]="isDarkTheme" (onToggleSidenav)="onToggleSideNav($event)"></app-sidenav>

<app-home
  (themeToggle)="toggleTheme($event)"
  [collapsed]="isSieNavCollapsed"
  [screenWidth]="screenWihdth"
>

</app-home>

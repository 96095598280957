import { orgao } from "./orgao";

export class servico {
    public id: string | null;
    public codigo: string | undefined;
    public descricao: string;
    public descricaoVagas: string;
    public codigoCentralizado: string;
    public orgao: orgao | undefined;
  
    constructor(id: string | null = null,
      codigo: string | undefined = undefined,
      descricao: string = "",
      descricaoVagas: string = "",
      codigoCentralizado: string = "",
      orgao: orgao | undefined = undefined) {
      this.id = id;
      this.codigo = codigo;
      this.descricao = descricao;
      this.codigoCentralizado = codigoCentralizado;
      this.orgao = orgao;
      this.descricaoVagas = descricaoVagas;
    }
}
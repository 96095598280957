<section class="section-form-main">
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            @if(url.includes('create-users')){
                                <td class="nomeTela">
                                    Cadastro de Perfil de usuários
                                </td>
                            }@else {
                                <td class="nomeTela">
                                    Edição de Perfil de usuários
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>

    <div class="div-form-card">

        <form class="form-class" data-toggle="collapse" *ngIf="novoItemEditado">
            <div class="card-body">
                <label>Informações do Usuário <strong>{{novoItemEditado.cpf}}</strong></label>
                <div *ngIf="MensagemErro!= ''" class="alert alert-danger" role="alert">
                   {{MensagemErro}}
                </div>
                <div class="divs-input">
                    <label for="nome" for="userName">Nome<span> *</span></label>
                    <input type="text" class="form-control" id="nome" name="nome"  [ngClass]="{'input-error': botaoSalvarClicado && !novoItemEditado.nome}" [disabled]="validar() == false" [(ngModel)]="novoItemEditado.nome" />
                </div>
                <div class="divs-input">
                    <label for="email" for="useremail">E-mail</label>
                    <input type="text" class="form-control" id="email" name="email" [disabled]="validar() == false" [(ngModel)]="novoItemEditado.email" />
                </div>

                <div class="divs-input">
                    <label for="cpf">CPF<span> *</span></label>
                    <input type="text" mask="000.000.000-00" maxlength="14" class="form-control" id="cpf" name="cpf" [(ngModel)]="novoItemEditado.cpf" [ngClass]="{'input-error': botaoSalvarClicado && !novoItemEditado.cpf}" required/>
                </div>

                <div class="divs-input">
                    @if(novoItemEditado.meusDados){
                        Seu usuário atual é : {{ formataUsuario(novoItemEditado.meusDados) }} <br>
                    }
                    <label for="meusDados">Perfil<span> *</span></label>
                    <form>
                        <select name="selectPerfil" #role="ngModel" ngModel class="form-control" (change)="ajustaMeuDados(role.value)" [disabled]="validar() == false" 
                        [ngClass]="{'input-error': botaoSalvarClicado && isPerfilValid(novoItemEditado)}" 
                        >
                            <option value="">Escolha o perfil para o usuário</option>
                            @for (perfis of listaPerfil; track perfis) {
                                <option [ngValue]="perfis.perfil">{{ perfis.desc }}</option>
                            }
                        </select>

                    </form>

                </div>
                <hr>
                <div class="containerFiltro">
                    <span>
                        <h5>{{ qtdOldRegiao }} - Região</h5>
                        <div class="filtro">
                            @for(regiao of oldRegiao; track regiao){
                                <button [ngClass]="{ 'selected': isSelectedRegiao(regiao) }" (click)="selecionarRegiao(regiao)" [disabled]="validar() == false">
                                    {{ regiao.descricao }}
                                </button>
                            }
                        </div>
                    </span><span class="filtroSelecao">
                        @if(qtdOldRegiao == 0 && qtdNewRegiao == 0){
                            <button class="btn" (click)="addRegiaoSelecionado()" [disabled]="validar() == false">></button>
                            <button class="btn" (click)="addTodosRegiao()" [disabled]="validar() == false">>></button>
                        }@else {
                            <button class="btn" (click)="addRegiaoSelecionado()" [disabled]="validar() == false || qtdOldRegiao == 0">></button>
                            <button class="btn" (click)="addTodosRegiao()" [disabled]="validar() == false || qtdOldRegiao == 0">>></button>
                        }

                        <button class="btn" (click)="removerRegiaoSelecioando()" [disabled]="validar() == false || qtdNewRegiao == 0"><</button>
                        <button class="btn" (click)="removerTodosRegiao()" [disabled]="validar() == false || qtdNewRegiao == 0"><<</button>
                    </span>
                    <span>
                        <h5>{{ qtdNewRegiao }} - Região Selecionado<span> *</span></h5>
                        <div class="filtro">
                            @for(regiaoSelecioandos of newRegiao; track regiaoSelecioandos){
                                <button [ngClass]="{ 'selected': isSelectedRegiao(regiaoSelecioandos) }" (click)="selecionarRegiao(regiaoSelecioandos)" [disabled]="validar() == false">
                                    {{ regiaoSelecioandos.descricao }}
                                </button>
                            }
                        </div>
                    </span>
                </div>
                <hr>
                <div class="containerFiltro">
                    <span>
                        <h5>{{ qtdOldPosto }} - Postos</h5>
                        <div class="filtro" >
                            @for(postos of oldPosto; track postos){
                                <button  [ngClass]="{ 'selected': isSelectedPosto(postos) }" (click)="selecionarPosto(postos)" [disabled]="validar() == false">
                                    {{ postos.descricao }}
                                </button>
                            }
                        </div>
                    </span>
                    <span class="filtroSelecao">
                        @if(qtdOldPosto == 0 && qtdNewPosto == 0){
                            <button class="btn" (click)="addPostoSelecionado()" >></button>
                            <button class="btn" (click)="addTodosPostos()">>></button>
                        }@else{
                            <button class="btn" (click)="addPostoSelecionado()" [disabled]="validar() == false || qtdOldPosto == 0">></button>
                            <button class="btn" (click)="addTodosPostos()" [disabled]="validar() == false  || qtdOldPosto == 0">>></button>
                        }

                        <button class="btn" (click)="removerSelecioando()" [disabled]="validar() == false  || qtdNewPosto == 0"><</button>
                        <button class="btn" (click)="removerTodosPostos()" [disabled]="validar() == false  || qtdNewPosto == 0"><<</button>
                    </span>
                    <span>
                        <h5>{{ qtdNewPosto }} - Posto Selecionado</h5>
                        <div class="filtro">
                            @for(postosSelecioandos of newPosto; track postosSelecioandos){
                                <button [ngClass]="{ 'selected': isSelectedPosto(postosSelecioandos) }" (click)="selecionarPosto(postosSelecioandos)" [disabled]="validar() == false">
                                    {{ postosSelecioandos.descricao }}
                                </button>
                            }
                        </div>
                    </span>
                </div>

            </div>
        </form>
    </div>

    <div class="div-botao-edicao">
        <!-- <button class="btn btn-primary" (click)="editarUsuarioIDTS()">Salvar Perfil do Usuário</button> -->
        @if(validar() == true){
            @if(url.includes('create-users')){
                <button class="btn btn-primary" (click)="salvarUsuario(novoItemEditado)" [disabled]="!validarFormulario(novoItemEditado)">Cadastrar Perfil do Usuário</button>
            }
            @else {
                <button class="btn btn-primary" (click)="editarUsuarioIDTS(novoItemEditado)" [disabled]="!validarFormulario(novoItemEditado)">Salvar Perfil do Usuário</button>
            }
            <button class="btn btn-secondary" style="margin-left: 20px;" (click)="resetForm()">Limpar Formulário</button>
        }
        <button class="btn btn-secondary" [routerLink]="['/permissao-users']" style="margin-left: 20px;" >Voltar</button>
    </div>

</section>

import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, ContentChild, Inject, OnInit, TemplateRef, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { breadcrumb } from '../../../modal/breadcrumb';
import { IndicadorEspecialService } from '../../../service/IndicadorEspecial.service';
import { SubIndicadorEspecialCardVertical } from '../sub-indicador-especial-vertical/sub-indicador-especial-card-vertical.component';
import { indicador } from '../../../modal/Indicador';
import { indicadorDTO } from '../../../modal/dto/indicadorDTO';
import { valor } from '../../../modal/valor';
import { DisplayComponenteGrafico } from '../display-componente-grafico/display-componente-grafico.component';
import { cabecalhoDTO } from '../../../modal/dto/cabecalhoDTO';
@Component({
  selector: 'app-indicador-especial',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    SubIndicadorEspecialCardVertical,
    DisplayComponenteGrafico
  ],
  templateUrl: './indicador-especial.component.html',
  styleUrl: './indicador-especial.component.scss'
})

export class IndicadorEspecialComponent implements OnInit {
  public NomeTela = "";
  public TipoVisualizacaoTela = "TABELA";
  public DisplayTela = "";
  public IdTela: number = 0;
  public CcPosto = "";
  tempoAtualizacao: any;
  intervalIds: any[] = [];
  public CcOrgao = "";
  public variaveis: string = "";
  textInd: string | null = "";
  textPos: string | null = "";
  textOrg: string | null = "";
  public ListaDentroLista: boolean = false;
  public SemDados: boolean = false;
  public SemOPrimeiroCampo: boolean = false;
  cabecalho: cabecalhoDTO[] = [];
  public listaMesa: indicadorDTO[] = [];
  public listaComponentesGraficos: valor[] = [];
  public atualizando: boolean = false;
  data = []
  listabreadcrumb: breadcrumb[] = [];
  login: string | null | undefined = null
  constructor(private route: ActivatedRoute, private indicadorEspecialService: IndicadorEspecialService, private router: Router, @Inject(DOCUMENT) private document: Document) {
    try {
      const localStorage = document.defaultView?.localStorage;
      if (localStorage)
        this.login = localStorage.getItem('login');
      if (this.login == null || this.login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      var NewVisu = localStorage.getItem('VISUPADRAO');
      this.TipoVisualizacaoTela = NewVisu == null ? "TABELA" : NewVisu;
    }

    //console.log(NewVisu)

    const breadcrumbString = window.localStorage.getItem('breadcrumb');
    if (breadcrumbString) {
      var bc = JSON.parse(breadcrumbString);
    }
    if (bc != undefined && bc.length != 0) {
      for (let index = 0; index < bc.length; index++) {
        const element = bc[index];
        this.listabreadcrumb.push(new breadcrumb(element.label, element.url));
      }
    }
    else {
      this.listabreadcrumb = [
        new breadcrumb("Dashboard", "/dashboard"),
        new breadcrumb("Posto", "/indicador-posto"),
        new breadcrumb("Orgão", "/indicador-orgao")
      ];
    }
  }
  ngOnInit() {
    let login = localStorage.getItem('login');
    this.cabecalho = [];
    // Roteamento de telas
    this.route.queryParamMap.subscribe(params => {
      let tipotela = params.get('tipoTela');
      this.IdTela = tipotela == null ? 0 : Number.parseInt(tipotela);
      this.textInd = params.get('textInd');
      this.textPos = params.get('textPos');
      this.textOrg = params.get('textOrg');
      let CodPosto = params.get('CcPosto');
      this.CcPosto = CodPosto == null ? "" : CodPosto;
      var retrievedObject = localStorage.getItem('salaSituacao');
        if (retrievedObject != null) {
            var sala = JSON.parse(retrievedObject);
            console.log(retrievedObject);
            //console.table(sala['postos']);
            var postosUsuario = sala['postos'];
            if(!sala.postos.includes(Number.parseInt(this.CcPosto)))
              this.router.navigate(['/dashboard']);
        }
        else
          this.router.navigate(['/iniciar']);
      let CodOrgao = params.get('CcOrgao');
      let varr = params.get('variaveis');
      this.variaveis = varr == null ? "" : varr;
      this.CcOrgao = CodOrgao == null ? "" : CodOrgao;
      this.indicadorEspecialService.getindicadores(this.IdTela, this.CcPosto, this.CcOrgao, this.variaveis, login == undefined ? "" : login).pipe(
      ).subscribe((response) => {
        console.log(response)
        this.data = response['indicadores'];
        if (this.data.length == 0) {
          this.SemDados = true;
        }
        else {
          this.tempoAtualizacao = response["tempoAtualizacao"];
          this.DisplayTela = response['visualizacaoDisplay'];
          if (response["orgao"] == null) {
            this.NomeTela =
              this.data[0]["nome"] + (this.textInd == null ? "" : this.textInd)
              + " (do posto "
              + response["posto"]['posto']['descricao']
              + (this.textPos == null ? "" : this.textPos) + ")";
          }
          else {
            this.NomeTela =
              this.data[0]["nome"] + (this.textInd == null ? "" : this.textInd)
              + " (do orgão " + response["orgao"]['orgao']['descricao']
              + (this.textOrg == null ? "" : this.textOrg) + " do posto "
              + response["posto"]['posto']['descricao']
              + (this.textPos == null ? "" : this.textPos) + ")";
          }
          this.listabreadcrumb.push(new breadcrumb("" + response["nomeTipoTela"], ""));
          this.data.forEach(element => {
            var v = element['valor'];
            var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null, new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], undefined, "");
            var val = new valor(
              v['codigo'],
              v['nome'],
              v['expressao'],
              v['icone'],
              v['denominador'],
              v['nominador'],
              v['valorFormatado'],
              v['valorSemFormato'],
              v['naoMostraCampo'],
              v['naoMostraTitulo'],
              v['orientacaoLoop'],
              v['ehLooping'],
              v['ehComponenteGrafico'],
              v['componenteGraficoHtml'],
              v['componenteGraficoCss'],
              v['componenteGraficoPossicao'],
              v['ehLink'],
              v['linkRedirect'],
              v['linkVariaveis'],
              v['ordem'],
              v['ehCampoCabecalho'],
              this.getValores(v['valores'], ind),
              v['cor']
            )
            if (val.naoMostraCampo)
              this.SemOPrimeiroCampo = true;
            ind = new indicadorDTO(
              element['codigo'],
              element['nome'],
              element['cor'],
              element['etapa'],
              element['icone'],
              element['corFarol'],
              element['ordenacaoOrientacao'],
              element['proximaEtapa'],
              val, [], undefined, ""
            );
            ind.valores = this.getValores(element['valores'], ind)
            if (ind.valor.naoMostraCampo) {
              var Vcabecalho = this.getValoresCabecalho(ind.valores)
              if (Vcabecalho != null)
                ind.valor = Vcabecalho;
            }
            this.listaMesa.push(ind);

          });

          this.listaMesa.sort((a, b) => {
            const lengthA = a.valores ? a.valores.length : 0;
            const lengthB = b.valores ? b.valores.length : 0;
            return lengthB - lengthA;
          });
          this.setAtualizacaoindicadores();
          //console.log(this.listaMesa)
        }
      });
    });
  }

  ngOnDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
  }

  setAtualizacaoindicadores() {
    let login = localStorage.getItem('login');
    const intervalId = setInterval(() => {
      this.atualizando = true;

      this.indicadorEspecialService.getindicadores(this.IdTela, this.CcPosto, this.CcOrgao, this.variaveis, login == undefined ? "" : login).pipe(
      ).subscribe((response) => {
        this.listaMesa = [];
        console.log(response)
        this.data = response['indicadores'];
        if (this.data.length == 0) {
          this.SemDados = true;
        }
        else {
          this.data.forEach(element => {
            var v = element['valor'];
            var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null, new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], undefined, "");
            var val = new valor(
              v['codigo'],
              v['nome'],
              v['expressao'],
              v['icone'],
              v['denominador'],
              v['nominador'],
              v['valorFormatado'],
              v['valorSemFormato'],
              v['naoMostraCampo'],
              v['naoMostraTitulo'],
              v['orientacaoLoop'],
              v['ehLooping'],
              v['ehComponenteGrafico'],
              v['componenteGraficoHtml'],
              v['componenteGraficoCss'],
              v['componenteGraficoPossicao'],
              v['ehLink'],
              v['linkRedirect'],
              v['linkVariaveis'],
              v['ordem'],
              v['ehCampoCabecalho'],
              this.getValores(v['valores'], ind),
              v['cor']
            )
            if (val.naoMostraCampo)
              this.SemOPrimeiroCampo = true;
            ind = new indicadorDTO(
              element['codigo'],
              element['nome'],
              element['cor'],
              element['etapa'],
              element['icone'],
              element['corFarol'],
              element['ordenacaoOrientacao'],
              element['proximaEtapa'],
              val, [], undefined, ""
            );
            ind.valores = this.getValores(element['valores'], ind)
            if (ind.valor.naoMostraCampo) {
              var Vcabecalho = this.getValoresCabecalho(ind.valores)
              if (Vcabecalho != null)
                ind.valor = Vcabecalho;
            }
            this.listaMesa.push(ind);

          });

          this.listaMesa.sort((a, b) => {
            const lengthA = a.valores ? a.valores.length : 0;
            const lengthB = b.valores ? b.valores.length : 0;
            return lengthB - lengthA;
          });
          this.atualizando = false;
        }
      });
    }, (this.tempoAtualizacao * 1000));
    this.intervalIds.push(intervalId);
  }

  navigateWithState(Link: string | null, variaveis: string | null) {
    this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
    window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, variaveis, CcPosto: this.CcPosto, CcOrgao: this.CcOrgao }
    });
  }

  navigateWithStateBC(Link: string | null) {
    if (Link != null) {
      const index = this.listabreadcrumb.findIndex(b => b.url === Link);
      console.log("LINK", Link);
      if (index !== -1) {
        this.listabreadcrumb = this.listabreadcrumb.slice(0, index);
      }
      window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    }
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, CcPosto: this.CcPosto, CcOrgao: this.CcOrgao, textInd: this.textInd, textPos: this.textPos }
    });
  }
  getValoresCabecalho(valores: valor[]): valor | null {
    var retorno: valor | null = null;
    valores.forEach(element => {
      if (element.ehCampoCabecalho)
        retorno = element;
      else {
        var v = this.getValoresCabecalho(element.valores)
        if (v != null)
          retorno = v
      }
    });
    return retorno;
  }

  getValores(response: [], ind: indicadorDTO): valor[] {
    if (response == undefined) {
      return [];
    }
    var listavalores: valor[] = [];
    response.forEach(element => {
      var val = new valor(
        element['codigo'],
        element['nome'],
        element['expressao'],
        element['icone'],
        element['denominador'],
        element['nominador'],
        element['valorFormatado'],
        element['valorSemFormato'],
        element['naoMostraCampo'],
        element['naoMostraTitulo'],
        element['orientacaoLoop'],
        element['ehLooping'],
        element['ehComponenteGrafico'],
        element['componenteGraficoHtml'],
        element['componenteGraficoCss'],
        element['componenteGraficoPossicao'],
        element['ehLink'],
        element['linkRedirect'],
        element['linkVariaveis'],
        element['ordem'],
        element['ehCampoCabecalho'],
        this.getValores(element['valores'], ind),
        element['cor']
      )
      if (val.naoMostraCampo != true) {
        if (!this.cabecalho.find(x => x.nome == val.nome)) {
          var c: cabecalhoDTO = new cabecalhoDTO(val.nome, val.ordem, val.icone);

          this.cabecalho.push(c);
        }
      }
      this.cabecalho.sort((a, b) => {
        return a.ordem - b.ordem
      });
      if (val.ehComponenteGrafico) {
        if (ind.listaComponentesGraficos == undefined)
          ind.listaComponentesGraficos = [];
        if (ind.listaComponentesGraficos.find(x =>
          x.valorFormatado == val.valorFormatado &&
          val.componenteGraficoCss == x.componenteGraficoCss &&
          val.componenteGraficoHtml == x.componenteGraficoHtml &&
          x.componenteGraficoPossicao == val.componenteGraficoPossicao) == null) // checa se ja não colocou o mesmo componente grafico na lista
          ind.listaComponentesGraficos.push(val);
      }
      listavalores.push(val);
      if (val != undefined && val.ehLooping == true) {
        this.ListaDentroLista = true;
      }
    });
    return listavalores;
  }

  MudarVisualizacaoTela(NovoEstilo: string) {
    this.TipoVisualizacaoTela = NovoEstilo;
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      localStorage.setItem('VISUPADRAO', NovoEstilo);
    }
  }

}
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { PostoService } from '../../../../service/posto.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { RegiaoService } from '../../../../service/regiao.service';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';
import { indicadorAlertaPermanenciaService } from '../../../../service/indicadorAlertaPermanencia.service';
import { indicadorAlertaPermanencia } from '../../../../modal/indicadorAlertaPermanencia';
import { posto_simple } from '../../../../modal/posto_simple';
import { TimeFormatPipe } from '../../../../utilidades/time-format.pipe';
import { DateTimeFormatPipe } from '../../../../utilidades/date-time-format.pipe';
import { StatusIndicadorAlerta } from '../../../../utilidades/enum/status.indicador.alerta.enum';
import { StatusCorIndicadorAlerta } from '../../../../utilidades/enum/status.cor.indicardor.alerta.enum';

@Component({
  selector: 'app-listagem-incluisao-justificativas',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './listagem-incluisao-justificativas.component.html',
  styleUrl: './listagem-incluisao-justificativas.component.scss',
  providers: [TimeFormatPipe, DateTimeFormatPipe]
})

export class ListagemIncluisaoJustificativasComponent implements OnInit {
  data = [];
  public StatusIndicadorAlerta = StatusIndicadorAlerta;
  public StatusCorIndicadorAlerta = StatusCorIndicadorAlerta;
  public listaInd: indicadorAlertaPermanencia[] = [];
  pesquisaNomePosto: string = "";
  pesquisaFilaDesc: string = "";
  pesquisaDataInit: string = "";
  pesquisaDataFim: string = "";
  pesquisaStatus: number | null = null;

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;


  order = "asc";
  CampoOrder =  "data_fim";

  @ViewChild('mySelectFiltroStatus') mySelectFiltroStatus: any;

  constructor(
    private route: ActivatedRoute,
    public timeFormatPipe: TimeFormatPipe,
    public dateTimeFormatPipe: DateTimeFormatPipe,
    private router: Router,
    private IndAlertaPermanenciaService: indicadorAlertaPermanenciaService,
    public dialog: MatDialog,
    private authService: AuthService) {
    try {
      console.log(this.timeFormatPipe.transform(60009));

      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }

  onOptionChangeFiltroStatus(newValue: number) {
    this.pesquisaStatus = newValue;
  }

  ngOnInit() {
    this.Pesquisa();
  }

  MudaFiltro() {
    this.pageIndex = 0;
    this.Pesquisa()
  }

  Pesquisa() {
    console.log("STATUSSS", this.pesquisaStatus);
    this.listaInd = [];
    var retrievedObject = localStorage.getItem('salaSituacao');
    if (retrievedObject != null) {
      var sala = JSON.parse(retrievedObject);
      var millisInicio: number | null = null;
      if(this.pesquisaDataInit != ""  && this.pesquisaDataInit != undefined){
      const dateObject = new Date(this.pesquisaDataInit); // Converte a string para um objeto Date
      millisInicio = dateObject.getTime();
      }

      var millisFim: number | null = null;
      if(this.pesquisaDataFim != "" && this.pesquisaDataFim != undefined){
      const dateObject = new Date(this.pesquisaDataFim); // Converte a string para um objeto Date
      millisFim = dateObject.getTime();
      }
      console.log(this.pesquisaDataInit, millisInicio);
      console.log(this.pesquisaDataFim, millisFim);

      this.IndAlertaPermanenciaService.getPaginacao(this.pageIndex, this.pageSize,this.order,this.CampoOrder, this.pesquisaNomePosto, this.pesquisaFilaDesc, millisInicio, millisFim, this.pesquisaStatus,sala['postos']).pipe(
      ).subscribe((response) => {
        this.data = response["IndicadorAlertaPermanencia"];
        this.totalPages = response["totalPages"];
        console.log(response);
        this.data.forEach(element => {
          let p = new posto_simple(element['posto']['id'], element['posto']['descricao']);
          let i = new indicadorAlertaPermanencia(
            element['id'],
            element['data_fim'],
            element['dataInicio'],
            element['tempo'],
            element['qtdCidadaoEmEspera'],
            element['status'],
            element['descFila'],
            element['qtdPontosAbertos'],
            p);
          this.listaInd.push(i)
        });
        console.log(this.listaInd);
      });
    }
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  isAsc: boolean = true;
  sortTable(column: string) {
    console.log(column);
    this.CampoOrder = column;
    this.isAsc = !this.isAsc;
    if(this.isAsc)
      this.order = "asc";
    else
      this.order = "desc";
    this.Pesquisa();
  }

  validarRole() {
    let role = this.authService.role();
    if (role == 'superuser') {
      return true;
    }
    return false;
  }

  validarRolePosto() {
    let role = this.authService.role();
    if (role == 'posto' || role == 'gestorregional') {
      return true;
    }
    return false;
  }
}

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environments';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.idpsp_issuer, //'https://rhsso.idp-hml.sp.gov.br/auth/realms/idpsp',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + environment.redirectUri,


  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: 'poupatempo-sala-de-situacao.postos',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  //dummyClientSecret: 'ubG025VxrCwCmoIWV9z21AP4ygobCLaX',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid profile email',

  showDebugInformation: true,

  tokenEndpoint: environment.idpsp_tokenEndpoint, //'https://rhsso.idp-hml.sp.gov.br/auth/realms/idpsp/protocol/openid-connect/token',

  logoutUrl: environment.idpsp_logoutUrl, //'https://rhsso.idp-hml.sp.gov.br/auth/realms/idpsp/protocol/openid-connect/logout',

  postLogoutRedirectUri: window.location.origin + environment.redirectUri,

  // Not recommented:
  // disablePKCI: true,

  clearHashAfterLogin: false,
};

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { servico } from '../modal/regional/servico';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: false
    };
    constructor(private http: HttpClient, private oauthService: OAuthService,) { }
    private apiUrl = environment.API_URL;
    private map = "ss.usuario";
    headers = new HttpHeaders().set('Content-Type', 'application/json');

    isTokenValid(): boolean {
        return this.oauthService.hasValidAccessToken();
      }

      getNewToken(): string {
        return this.oauthService.getAccessToken();
      }

    getRoles(cpf: string): Observable<any> {
        return this.http.post(this.apiUrl + this.map + '/findByCPF', cpf, this.httpOptions)
            .pipe(retry(1), catchError(this.handleError))
    }

    getNomeUsuario(cpf: string): Observable<any> {
        return this.http.post(this.apiUrl + this.map + '/findNomeUsuario', cpf, this.httpOptions)
            .pipe(retry(1))
    }

    public handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Cliente-side error
            errorMessage = `Erro do cliente: ${error.error.message}`;
        } else if (error.error && error.error.message) {
            // Verificação adicional para erros no lado do servidor
            errorMessage = `Erro do servidor: ${error.error.message}`;
        } else {
            // Resposta de erro do servidor sem um corpo de erro específico
            errorMessage = `Código do erro: ${error.status}, mensagem: ${error.statusText}`;
        }
        console.error(errorMessage);
        return throwError(() => new Error(errorMessage));
    }

    role(){
        let local:any = localStorage.getItem('salaSituacao');
        local = JSON.parse(local);
        let role = local.perfil;
        return role;
    }

    getVersionamento(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}${this.map}/getVersionamento`);
    }

    atualizarListaPostosERegiaoUsuario(){
    var salaSituacao;
    let claims = this.oauthService.getIdentityClaims();
    if (claims) {
        this.getRoles(claims['preferred_username']).pipe().subscribe((response) => {
            salaSituacao = response['meusDados']['roles']['salaSituacao'];
            localStorage.setItem('salaSituacao', JSON.stringify(salaSituacao));
            });
        }
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { PAsContratados } from '../modal/PAsCadastrados';
import { environment } from '../../environments/environments';
import {
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class PASCadastradosService {

    constructor(private http: HttpClient) {}

    private apiUrl = environment.API_URL;

    urlPontosContratados = this.apiUrl+"pontos.contratados.api"

    FindPaginacao(pageNumber: number, pageSize: number, filtroDesc: string): Observable<any> {
        return this.http.get(this.urlPontosContratados+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&filtroDesc='+filtroDesc, {withCredentials: true});
      }

    GetPASContratados(usuario: any): Observable<any> {
        return this.http.post(this.urlPontosContratados + '/findPontoContratado', usuario, {withCredentials: true });
    }

    GetPASContratadoPorID(id: string): Observable<PAsContratados> {
        return this.http.get<PAsContratados>(
            this.urlPontosContratados + "/findPontoContratado/" + id,
            { withCredentials: true }
        );
    }
    GetPASContratadoPorPostoID(id: string): Observable<any> {
        return this.http.get<PAsContratados>(
            this.urlPontosContratados + "/findbyPostoId/" + id,
            { withCredentials: true }
        );
    }

    PostAdicionarItem(pACadastrados: PAsContratados): Observable<PAsContratados> {
        return this.http.post<PAsContratados>(
            this.urlPontosContratados + '/pontoContratado', pACadastrados, { withCredentials: true }
        );
    }

    editarPASContratadoItemPorIDTelaCadastro(id: string): Observable<any> {
        //console.log("Retorno da API PAS @###@_", `${this.urlPontosContratados}/findbyId/${id}`)
        return this.http.get<any>(`${this.urlPontosContratados}/findbyId/${id}`);
    }

    DeletePASContratadoItemPorID(id: string): Observable<any>{
        return this.http.delete<any>(`${this.urlPontosContratados}/deletar/${id}`);
    }

}

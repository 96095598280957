import { Component, Input, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { valor } from '../../../modal/valor';
import { DisplayComponenteGrafico } from '../display-componente-grafico/display-componente-grafico.component';
import { breadcrumb } from '../../../modal/breadcrumb';

@Component({
  selector: 'sub-indicador-especial-card-vertical',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    DisplayComponenteGrafico
  ],
  templateUrl: './sub-indicador-especial-card-vertical.component.html',
  styleUrl: './sub-indicador-especial-card-vertical.component.scss'
})
export class SubIndicadorEspecialCardVertical  implements OnInit{
  @Input() valores: valor[] | undefined;
  @Input() listaComponentesGraficos: valor[] | undefined;
  @Input() orientacaoLoop: string | null = "";
  @Input() listabreadcrumb: breadcrumb[] = [];
  @Input() IdTela: number = 0;
  @Input() CcPosto = "";
  @Input() CcOrgao = "";
  constructor(private router: Router) {
  }
  ngOnInit() {
  }

  navigateWithState(Link: string | null, variaveis: string | null) {
    this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url;
    this.router.navigate(['' + Link], {
      state: { tipoTela: this.IdTela, variaveis, breadcrumb: this.listabreadcrumb, CcPosto: this.CcPosto, CcOrgao: this.CcOrgao }
    });
  }
}
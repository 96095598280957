import { mensagem } from "./mensagem";
import { posto_simple } from "./posto_simple";


export class indicadorAlertaPermanencia {
    public id: string | null;
    public data_fim: number | undefined;
    public dataInicio: number | undefined;
    public tempo: number | undefined;
    public qtdCidadaoEmEspera: number | undefined;
    public qtdPontosAbertos: number | undefined;
    public status: number;
    public descFila: string;
    public posto: posto_simple | undefined;
    public mensagens: mensagem[] | undefined;
    constructor(id: string | null = null,
        data_fim: number | undefined = undefined,
        dataInicio: number | undefined = undefined,
        tempo: number | undefined = undefined,
        qtdCidadaoEmEspera: number | undefined = undefined,
        status: number,
      descFila: string = "",
      qtdPontosAbertos: number | undefined = undefined,
      posto: posto_simple | undefined  = undefined,
      mensagens: mensagem[] | undefined  = undefined,
      ) {
      this.id = id;
      this.data_fim = data_fim;
      this.dataInicio = dataInicio;
      this.tempo = tempo;
      this.qtdCidadaoEmEspera = qtdCidadaoEmEspera;
      this.status = status;
      this.descFila = descFila;
      this.qtdPontosAbertos = qtdPontosAbertos;
      this.posto = posto;
      this.mensagens = mensagens;
      
    }
}
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { valor } from '../../../modal/valor';
@Component({
    selector: 'app-display-componente-grafico',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatCardModule,
        MatMenuModule,
        CommonModule,
        RouterLink,
        RouterOutlet,
        RouterModule
      ],
    templateUrl: './display-componente-grafico.component.html',
    styleUrls: ['./display-componente-grafico.component.scss']
})
export class DisplayComponenteGrafico implements OnInit {
    dynamicHtml: string = '';
    css: string = '';
    @Input() valor: valor | undefined;
    constructor(private renderer: Renderer2) { }
    ngOnInit(): void {
        if (this.valor != undefined && this.valor.componenteGraficoHtml != null) {
            const jsonData = JSON.parse(this.valor.componenteGraficoHtml);
            
            // Encontre o caso que corresponde ao valor atual
            const matchingCase = jsonData["caso"].find((caso: any) => {
                switch (caso.tipo) {
                    case '==':
                        return caso.valor === this.valor?.valorFormatado;
                    default:
                        return false;
                }
            });
            
            if (matchingCase) {
                this.dynamicHtml = matchingCase.innerHTML;
                this.css = this.valor.componenteGraficoCss ==null? "" : this.valor.componenteGraficoCss;
                this.injectCss(this.valor.componenteGraficoCss ==null? "" : this.valor.componenteGraficoCss);
            }
        }
    }
    injectCss(css: string): void {
        const styleEl = this.renderer.createElement('style');
        this.renderer.appendChild(styleEl, this.renderer.createText(css));
        this.renderer.appendChild(document.head, styleEl);
      }
}
import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter, withDebugTracing, withHashLocation } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideServiceWorker } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { authInterceptor } from './_helpers/auth.interceptorfn';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
    providers: [
        //provideRouter(routes, withDebugTracing()),
        provideRouter(routes, withHashLocation()),
        provideAnimations(),
        provideClientHydration(),
        provideHttpClient(withInterceptors([authInterceptor])),
        provideOAuthClient({
            resourceServer: {
                allowedUrls: ['//127.0.0.1:8084/sala-situacao-ap'],
                sendAccessToken: true,
            }
        }),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        })]
};

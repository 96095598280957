import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { posto } from '../modal/regional/posto';

@Injectable({
  providedIn: 'root',
})
export class PostoService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.posto";
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  getPosto(): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
  }
  getPostoUsuario(usuario:any): Observable<any> {
    return this.http.post(this.apiUrl+this.map+'/findUsuario', usuario, {withCredentials: true});
  }
  getPosPaginacao(pageNumber: number, pageSize: number, filtroDesc: string, filtroReg: string, filtroCodigo: string, filtroCC: string, filtroStatus: string): Observable<any> {
    //return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
    return this.http.get(this.apiUrl+this.map+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&filtroDesc='+filtroDesc+'&filtroReg='+filtroReg+'&filtroCodigo='+filtroCodigo+'&filtroCC='+filtroCC+'&filtroStatus='+filtroStatus, {withCredentials: true});
  }

  adicionarItem(item: posto): Observable<posto> {
    return this.http.post<posto>(this.apiUrl+this.map+'/cadastro', item, {withCredentials: true});
  }

  deleteItem(id: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.map}/deletar/${id}`);
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findbyId/${id}`);
  }

  getPaginacaoOrderBy(pageNumber: number, pageSize: number, orderBy: string, descOrderBy: string): Observable<any> {
    //return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
    return this.http.get(this.apiUrl+this.map+'/findPaginacaoOrderBy?pageNumber='+pageNumber+'&pageSize='+pageSize+'&orderBy='+orderBy+'&descOrderBy='+descOrderBy, {withCredentials: true});
  }

  findByCodigo(codigo: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findByCodigo/${codigo}`);
  }

  findByCodigoCentralizado(codigoCentralizado: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findByCodigoCentralizado/${codigoCentralizado}`);
  }
}

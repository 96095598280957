<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Cadastro PAs Contratados
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        <div *ngIf="MensagemError != ''" class="alert alert-danger" role="alert">
            {{MensagemError}}
           </div>
           <div *ngIf="MensagemAlert != ''" class="alert alert-warning" role="alert">
            {{MensagemAlert}}
          </div>
        <section class="main-section">
            <form class="form-class" data-toggle="collapse" (ngSubmit)="submitForm()">
                
                <div class="form-div p-3">
                    <label for="regiao" class="form-label">Posto</label>
                    <select #mySelect name="select" class="form-select" (change)="onOptionChange(mySelect.value)">
                      <option value="">Escolha o posto</option>
                      <option *ngFor="let objeto of listaPostos" value={{objeto.codigoCentralizado}}>{{objeto.descricao}}</option>
                    </select>
                  </div>

                <div class="form-div p-3">
                    <label for="quantidadePontos" class="form-label">Qtde de Pontos</label>
                    <input type="number" maxlength="3" class="form-control" id="quantidadePontos" name="quantidadePontos"
                        [(ngModel)]="novoItem.quantidadePontos" required>
                        <!-- {{novoItemPAsContratado.quantidadePontos}} -->
                </div>

                <div style="display: flex; flex-direction: row; justify-content: flex-start;"
                    class="div-button mb-3 p-3">
                    <button style="margin-right: 2em;" class="class-button1" type="submit"
                        class="btn btn-primary">Salvar
                    </button>
                    <button style="margin-right: 2em;" class="class-button2" class="btn btn-secondary"
                        (click)="Limpar()">Limpar formulário
                    </button>
                    <button style="margin-right: 2em;" class="class-button2" class="btn btn-secondary"
                        (click)="voltar()">Voltar
                    </button>
                </div>
            </form>
        </section>
    </main>
</section>

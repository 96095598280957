import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { Router } from '@angular/router';
import { ControlePerfilUsersService } from '../../../../service/controle-perfil-users.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DropdownModule, CheckboxModule, TableModule } from 'angular-bootstrap-md';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { ClasseControlePermissaoUsers } from '../../../../modal/ControlePermissaoUsers';
import { ClasseMeusDados } from '../../../../modal/ControlePermissaoUsers_MeusDados'
import { PostoService } from '../../../../service/posto.service';
import { HeaderComponent } from '../../../../header/header.component';
import { AuthService } from '../../../../service/auth.service';
import { RegiaoService } from '../../../../service/regiao.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { GenericValidator } from '../../../../utilidades/GenericValidator';
import { regiao } from '../../../../modal/regional/regiao';
import { posto } from '../../../../modal/regional/posto';
import { empty } from 'rxjs';

@Component({
    selector: 'app-controle-perfil-user-modal',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatCardModule,
        MatMenuModule,

        CommonModule,

        RouterLinkActive,
        RouterLink,
        RouterOutlet,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        MatPaginatorModule,
        HttpClientModule,
        ButtonModule,
        InputTextModule,
        DropdownModule,
        CalendarModule,
        CheckboxModule,
        NgxMaskDirective,
        TableModule,
        HttpClientModule,
    ],
    templateUrl: './controle-perfil-user-modal.component.html',
    styleUrl: './controle-perfil-user-modal.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [provideNgxMask({ /* opções de cfg */ })]
})
export class ControlePerfilUserModalComponent {

    botaoSalvarClicado  = false;
    

    constructor(
        public routeActive: ActivatedRoute,
        public router: Router,
        public dialog: MatDialog,
        public userPerfilService: ControlePerfilUsersService,
        private postoService: PostoService,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private regiaoService: RegiaoService,
        private activatedRoute: ActivatedRoute
    ) { }
    public MensagemErro = "";
    listaPostos: any = [];
    listaRegiao: any = [];
    listaPostoUsuario: any = [];
    listaRegiaoUsuario: any = [];
    listaPerfil: any = []
    oldPosto: any = []
    newPosto: any = []
    oldRegiao: any = []
    newRegiao: any = []
    postoSelecionado: posto[] = [];
    regiaoSelecionado: regiao[] = [];
    postoPorRegiao: any = [];
    usuarioAtual: any;
    roleNovoItemEditado: any;
    qtdOldPosto: number = 0;
    qtdNewPosto: number = 0;
    qtdOldRegiao: number = 0;
    qtdNewRegiao: number = 0;
    usuario: any;
    url: any;


    listaUsuarios: ClasseControlePermissaoUsers[] = []
    perfilUser: ClasseControlePermissaoUsers = {
        id: "",
        nome: "",
        cpf: "",
        senha: "",
        email: "",
        meusDados: {
            dataCriacao: "",
            perfil: "",
            roles: {
                SALADESITUACAO: {
                    funcionalidades: [],
                    perfil: "",
                    postos: []
                },
                relacionamento: [],
                contingencia: [],
                eventosCommons: []
            }
        }
    }

    // novoItemEditado: ClasseControlePermissaoUsers = new ClasseControlePermissaoUsers();
    novoItemEditado: ClasseControlePermissaoUsers = {
        id: "",
        nome: "",
        cpf: "",
        senha: "",
        email: "",
        meusDados: {
            dataCriacao: "",
            perfil: "",
            roles: {
                SALADESITUACAO: {
                    funcionalidades: [],
                    perfil: "",
                    postos: []
                },
                relacionamento: [],
                contingencia: [],
                eventosCommons: []
            }
        }
    }

    novoUsuario: any = {
        id: "",
        nome: "",
        cpf: "",
        senha: "",
        perfil: "",
        postos: []
    }
    listaUsers: any = [];

    ngOnInit() {

        this.url = this.router.url;
        this.usuario = localStorage.getItem('salaSituacao');
        this.userPerfilService.getPerfilUsuarios().subscribe(response => {
            this.listaUsers = response.map(element => {
                if (element && element["meusDados"]?.perfil && element["senha"]) {
                    return {
                        id: element["id"],
                        cpf: element["cpf"],
                        meusDados: element["meusDados"],
                        nome: element["nome"],
                        senha: element["senha"],
                        email: element["email"]
                    }
                } else {
                    return null;
                }
            }).filter(item => item !== null);

        });
        this.usuarioAtual = this.authService.role();
        this.routeActive.queryParams.subscribe((params) => {
            const id = params["id"]
            if (id && id !== null && id !== undefined && id.length > 0) {

                this.usuarioFindId(id);


            }
            else {
                console.log("ELSE AQUI")
                this.listarPostos();
                this.listarUsuarios();
                this.listarRegiao();
            }
        })


    }

    usuarioFindId(id: string) {
        if (id && id !== null && id !== undefined && id.length > 0) {
            this.userPerfilService.usuarioFindId(id?.toString()).subscribe(
                (response) => {
                    //console.log("response  usuarioFindId(id: string) >>>>> ",response)
                    const pUser = response["Usuario"]
                    //console.table(pUser);
                    if (pUser && pUser !== null && pUser !== undefined) {
                        this.novoItemEditado.id = pUser["id"],
                            this.novoItemEditado.nome = pUser["nome"],
                            this.novoItemEditado.cpf = pUser["cpf"],
                            this.novoItemEditado.senha = pUser["senha"],
                            this.novoItemEditado.email = pUser["email"],
                            this.novoItemEditado.meusDados = pUser["meusDados"] ? pUser["meusDados"]["roles"]["SALADESITUACAO"]["perfil"] : undefined;
                        this.listaPostoUsuario = pUser["meusDados"]["roles"]["SALADESITUACAO"]["postos"]
                        this.listaRegiaoUsuario = pUser["meusDados"]["roles"]["SALADESITUACAO"]["regiao"]
                        this.roleNovoItemEditado = pUser["meusDados"]["roles"]["SALADESITUACAO"]["perfil"];
                        //console.log(pUser["meusDados"]["roles"]["SALADESITUACAO"]["perfil"]);
                        //console.table(pUser["meusDados"]["roles"]);
                        this.validar();
                        this.listarPostos();
                        this.listarUsuarios();
                        this.listarRegiao();
                    }
                },
                (error) => { console.error("Erro ao atualizar item", error) })
        }
    }

    validar() {
               
        if (this.roleNovoItemEditado != 'superuser' && this.usuarioAtual == 'superuser') {
            return true;
        }
        if (this.roleNovoItemEditado == 'superuser' && this.usuarioAtual == 'superuser') {
            return true;
        }
        if (this.roleNovoItemEditado != 'superuser' && this.usuarioAtual != 'superuser') {
            return true;
        }
        return false;
    }


    

   

   
    validarForm(): boolean {
        
        const { nome, cpf, meusDados } = this.novoItemEditado;  
                   
        if (!nome || !cpf || !meusDados || typeof meusDados === 'object') {
            this.MensagemErro = "Preencha todos os campos obrigatórios (*) corretamente.";
            
            return false;
        }
    
       
        this.MensagemErro = ""; // Limpa a mensagem de erro se o formulário for válido
        return true;
    }

    isPerfilValid(novoItemEditado: any): boolean {        
        if(typeof novoItemEditado.meusDados === 'object' || !novoItemEditado.meusDados){
            return true
        }
        return false
    }
    
    editarUsuarioIDTS(novoItemEditado: any) {
        var listaPostoUsuario: any = [];
        this.newPosto.forEach((posto: any) => {
            var idPosto = new Number(posto.codigoCentralizado);
            listaPostoUsuario.push(idPosto)
        });
        var listaRegiaoUsuario: any = [];
        this.newRegiao.forEach((regiao: any) => {
            var codigoRegiao = new Number(regiao.codigo)
            listaRegiaoUsuario.push(codigoRegiao);
        });
        this.novoUsuario.id = novoItemEditado.id;
        this.novoUsuario.nome = novoItemEditado.nome;
        this.novoUsuario.cpf = novoItemEditado.cpf;
        this.novoUsuario.senha = novoItemEditado.senha;
        this.novoUsuario.email = novoItemEditado.email;
        this.novoUsuario.perfil = novoItemEditado.meusDados;
        this.novoUsuario.postos = listaPostoUsuario;
        this.novoUsuario.regiao = listaRegiaoUsuario;
       
        if (this.validarForm()) {
            this.userPerfilService.editarUsuarioID(this.novoUsuario.id, this.novoUsuario).pipe().subscribe((response) => {
                this.router.navigate(['/permissao-users'], {
                    state: { 'edicao': true }
                });
            }, (error) => {
                this.router.navigate(['/permissao-users'], {
                    state: { 'edicao': false }
                });
            });


        }
        
    }

    salvarUsuario(novoItemEditado: any) {
        this.botaoSalvarClicado=true;
               
        if
            //(true){
            (!GenericValidator.cpf(novoItemEditado.cpf)) {
            this.MensagemErro = "Digite um CPF valido!";
        }
        else {
            var listaPostoUsuario: any = [];
            this.newPosto.forEach((posto: any) => {
                var idPosto = new Number(posto.codigoCentralizado);
                listaPostoUsuario.push(idPosto)
            });
            var listaRegiaoUsuario: any = [];
            this.newRegiao.forEach((regiao: any) => {
                var codigoRegiao = new Number(regiao.codigo)
                listaRegiaoUsuario.push(codigoRegiao);
            });
            this.novoUsuario.id = novoItemEditado.id;
            this.novoUsuario.nome = novoItemEditado.nome;
            this.novoUsuario.cpf = novoItemEditado.cpf;
            this.novoUsuario.senha = novoItemEditado.senha;
            this.novoUsuario.email = novoItemEditado.email;
            this.novoUsuario.perfil = novoItemEditado.meusDados;
            this.novoUsuario.postos = listaPostoUsuario;
            this.novoUsuario.regiao = listaRegiaoUsuario;

           
           
            if (this.validarForm()) {
                                
                this.MensagemErro = "";
                this.botaoSalvarClicado = true; 
            this.userPerfilService.saveUsuario(this.novoUsuario).pipe().subscribe((response) => {
                this.router.navigate(['/permissao-users'], {
                    state: { 'salvar': true }
                });
            }, (error) => {
                this.router.navigate(['/permissao-users'], {
                    state: { 'salvar': false }
                });
            });

        } else {
            // Exibe a mensagem de erro e interrompe o processo de salvamento
            this.MensagemErro = "Preencha todos os campos obrigatórios (*) corretamente.";
        }
        }
    }

    resetForm() {
        this.novoItemEditado = {
            id: "",
            nome: "",
            cpf: "",
            senha: "",
            email: "",
            meusDados: {
                dataCriacao: "",
                perfil: "",
                roles: {
                    SALADESITUACAO: {
                        funcionalidades: [],
                        perfil: "",
                        postos: []
                    },
                    relacionamento: [],
                    contingencia: [],
                    eventosCommons: []
                }
            }
        };
        this.removerTodosPostos();
        this.removerTodosRegiao();
    }

    ajustaMeuDados(role: any) {
        this.novoItemEditado.meusDados = role
        this.validarForm();
    }

    listarPostos() {
        let sala: any = localStorage.getItem('salaSituacao');
        sala = JSON.parse(sala);
        this.postoService.getPostoUsuario(this.usuario).pipe().subscribe((result: any) => {
            result.Posto.forEach((posto: any) => {
                this.listaPostos.push(posto);
            });

            this.listaPostos.sort(function (a: any, b: any) {
                if (a.descricao > b.descricao) {
                    return 1;
                }
                if (a.descricao < b.descricao) {
                    return -1;
                }
                return 0;
            })

            this.oldPosto = this.listaPostos;
            if (this.listaPostoUsuario != null) {
                this.listaPostoUsuario.forEach((codigoCentralizado: any) => {
                    this.addPostoExistenteUsuario(codigoCentralizado);
                });
            }
            this.qtdOldPosto = this.oldPosto.length;
            this.qtdNewPosto = this.newPosto.length;
        })
    }

    listarRegiao() {
        this.regiaoService.getRegiaoUsuario(this.usuario).pipe().subscribe((element: any) => {
            console.log(element);
            element.Regiao.forEach((regiao: any) => {
                this.listaRegiao.push(regiao);
            });
            this.listaRegiao.sort(function (a: any, b: any) {
                if (a.descricao > b.descricao) {
                    return 1;
                }
                if (a.descricao < b.descricao) {
                    return -1;
                }
                return 0;
            })
            this.oldRegiao = this.listaRegiao;
            console.log(this.listaRegiaoUsuario);
            this.listaRegiaoUsuario.forEach((codigoRegiao: any) => {
                this.addRegiaoExistenteUsuario(codigoRegiao);
            });
            this.qtdOldRegiao = this.oldRegiao.length;
        })
    }

    listarUsuarios() {
        if (this.usuarioAtual == 'superuser') {
            this.listaPerfil.push({ perfil: 'superuser', desc: 'Administrador' });
            this.listaPerfil.push({ perfil: 'superintendencia', desc: 'Superintendencia' });
            this.listaPerfil.push({ perfil: 'gestorregional', desc: 'Gestor Regional' });
            this.listaPerfil.push({ perfil: 'posto', desc: 'Posto' });
        }
        if (this.usuarioAtual == 'superintendencia') {
            this.listaPerfil.push({ perfil: 'superintendencia', desc: 'Superintendencia' });
            this.listaPerfil.push({ perfil: 'gestorregional', desc: 'Gestor Regional' });
            this.listaPerfil.push({ perfil: 'posto', desc: 'Posto' });
        }
        if (this.usuarioAtual == 'gestorregional') {
            this.listaPerfil.push({ perfil: 'superintendencia', desc: 'Superintendencia' });
            this.listaPerfil.push({ perfil: 'gestorregional', desc: 'Gestor Regional' });
            this.listaPerfil.push({ perfil: 'posto', desc: 'Posto' });
        }
    }

    formataUsuario(perfil: any) {
        if (perfil == 'superuser') {
            return 'Administrador'
        }
        if (perfil == 'superintendencia') {
            return 'Superintendencia'
        }
        if (perfil == 'gestorregional') {
            return 'Gestor Regional'
        }
        if (perfil == 'posto') {
            return 'Posto'
        }
        return ''
    }

    selecionarPosto(posto: any) {
        this.postoSelecionado.push(posto);
    }

    addPostoSelecionado() {
        if (this.postoSelecionado != undefined && this.postoSelecionado != null && this.postoSelecionado.length != 0) {
            this.postoSelecionado.forEach((elementp: any, indexp: number) => {
                this.newPosto.push(elementp);
                this.addRegiaoExistenteUsuario(elementp.regiao.codigo);
                this.ordenarNewPosto();

                this.oldPosto.forEach((element: any, index: number) => {
                    if (elementp.codigoCentralizado == element.codigoCentralizado) {
                        this.oldPosto.splice(index, 1);
                    }
                });
            });
            this.ordenarOldPosto();
            this.postoSelecionado = [];
        }
    }

    addPostoExistenteUsuario(codigoCentralizado: any) {

        const postosParaRemover: any = [];
        this.oldPosto.forEach((postos: any, index: number) => {
            if (codigoCentralizado == postos.codigoCentralizado) {
                this.newPosto.push(postos);
                postosParaRemover.push(index);
            }
        });

        // Remove os postos da lista de novos postos após iterar
        for (let i = postosParaRemover.length - 1; i >= 0; i--) {
            this.oldPosto.splice(postosParaRemover[i], 1);
        }

        this.ordenarNewPosto();
        this.ordenarOldPosto();
    }

    addTodosPostos() {
        this.oldRegiao.forEach((reg: any, index: number) => {
            this.newRegiao.push(reg);
        });
        this.oldRegiao = [];
        this.oldPosto.forEach((postos: any, index: number) => {
            this.newPosto.push(postos);
        });
        this.oldPosto = [];
        this.ordenarNewPosto();
        this.ordenarNewRegiao();
    }

    removerSelecioando() {
        if (this.postoSelecionado != undefined && this.postoSelecionado != null && this.postoSelecionado.length != 0) {
            this.postoSelecionado.forEach((elementp: any, indexp: number) => {
                this.oldPosto.push(elementp);
            });
            this.ordenarOldPosto();
            this.postoSelecionado.forEach((elementp: any, indexp: number) => {
                if (this.newPosto.length == 1) {
                    this.removeRegiaoExistenteUsuario(elementp.regiao.codigo);
                }
            });
            this.postoSelecionado.forEach((elementp: any, indexp: number) => {
                this.newPosto.forEach((element: any, index: number) => {
                    if (elementp.codigoCentralizado == element.codigoCentralizado) {
                        this.newPosto.splice(index, 1);
                    }
                });
            });
            this.ordenarNewPosto();
            this.postoSelecionado = [];
        }
    }

    removerTodosPostos() {
        if (this.newRegiao.length != 0) {
            if (this.oldRegiao.length != 0) {
                this.newRegiao.forEach((re: any, index: number) => {
                    this.oldRegiao.push(re);
                });
            }
            else {
                this.oldRegiao = this.newRegiao;
            }
        }
        this.newRegiao = [];
        if (this.newPosto.length != 0) {
            if (this.oldPosto.length != 0) {
                this.newPosto.forEach((postos: any, index: number) => {
                    this.oldPosto.push(postos);
                });
            }
            else {
                this.oldPosto = this.newPosto;
            }
        }
        this.newPosto = [];
        this.ordenarOldPosto();
        this.ordenarOldRegiao();
    }

    ordenarOldPosto() {
        this.oldPosto.sort(function (a: any, b: any) {
            if (a.descricao > b.descricao) {
                return 1;
            }
            if (a.descricao < b.descricao) {
                return -1;
            }
            return 0;
        })
        this.qtdOldPosto = this.oldPosto.length;
        this.qtdNewPosto = this.newPosto.length;
    }

    ordenarNewPosto() {
        this.newPosto.sort(function (a: any, b: any) {
            if (a.descricao > b.descricao) {
                return 1;
            }
            if (
                a.descricao < b.descrica
            ) {
                return -1;
            }
            return 0;
        })
        this.qtdNewPosto = this.newPosto.length;
        this.qtdOldPosto = this.oldPosto.length;
    }

    selecionarRegiao(regiao: any) {
        this.regiaoSelecionado.push(regiao);
        console.log(this.regiaoSelecionado);
    }

    addRegiaoSelecionado() {
        if (this.regiaoSelecionado != undefined && this.regiaoSelecionado != null && this.regiaoSelecionado.length != 0) {
            this.regiaoSelecionado.forEach((r: any, index: number) => {
                this.newRegiao.push(r);
            });
            this.ordenarNewRegiao();
            this.regiaoSelecionado.forEach((element2: any, index2: number) => {
                this.oldRegiao.forEach((element: any, index: number) => {

                    if (element2.id == element.id) {
                        this.oldRegiao.splice(index, 1);
                    }
                });
            });
            this.regiaoSelecionado.forEach((element2: any) => {
                var listaOldPosto: any = [];
                var listaNewPosto:any = [];
                this.listaPostos.forEach((postos: any) => {
                    if (postos.regiao) {
                        if (element2.codigo == postos.regiao.codigo) {
                            listaNewPosto.push(postos);
                        }else{
                            listaOldPosto.push(postos);
                        }
                    }
                });
                this.oldPosto = listaOldPosto;
                this.ordenarOldPosto();
                this.newPosto = listaNewPosto;
                this.ordenarNewPosto();
            });

            this.ordenarOldRegiao();
            this.regiaoSelecionado = [];
        }
    }

    addTodosRegiao() {
        this.oldRegiao.forEach((reg: any, index: number) => {
            this.newRegiao.push(reg);
        });
        this.oldRegiao = [];
        this.oldPosto.forEach((postos: any, index: number) => {
            this.newPosto.push(postos);
        });
        this.oldPosto = [];
        this.ordenarNewPosto();
        this.ordenarNewRegiao();
    }
    isSelectedRegiao(item: regiao): boolean {
        return this.regiaoSelecionado.includes(item);
    }
    isSelectedPosto(item: posto): boolean {
        return this.postoSelecionado.includes(item);
    }

    removerRegiaoSelecioando() {
        if (this.regiaoSelecionado != undefined && this.regiaoSelecionado != null && this.regiaoSelecionado.length != 0) {
            this.regiaoSelecionado.forEach((r: any, index: number) => {
                this.oldRegiao.push(r);
            });
            this.ordenarOldRegiao();


            this.regiaoSelecionado.forEach((regiao: any, index: number) => {
                this.newRegiao = this.newRegiao.filter((element: any) => element.id !== regiao.id);
            });
            this.ordenarNewRegiao();

            const postosParaRemover: any[] = [];
            this.regiaoSelecionado.forEach((r: any, indexr: number) => {
                this.newPosto.forEach((postos: any, index: number) => {
                    if (r.id === postos.regiao.id) {
                        this.oldPosto.push(postos);
                        postosParaRemover.push(index);
                    }
                });
            });

            for (let i = postosParaRemover.length - 1; i >= 0; i--) {
                this.newPosto.splice(postosParaRemover[i], 1);
            }
            this.ordenarNewPosto();
            this.regiaoSelecionado = [];
        }
    }

    removerTodosRegiao() {

        if (this.newRegiao.length != 0) {
            if (this.oldRegiao.length != 0) {
                this.newRegiao.forEach((re: any, index: number) => {
                    this.oldRegiao.push(re);
                });
            }
            else {
                this.oldRegiao = this.newRegiao;
            }
        }
        this.newRegiao = [];
        if (this.newPosto.length != 0) {
            if (this.oldPosto.length != 0) {
                this.newPosto.forEach((postos: any, index: number) => {
                    this.oldPosto.push(postos);
                });
            }
            else {
                this.oldPosto = this.newPosto;
            }
        }
        this.newPosto = [];
        this.ordenarOldRegiao();
        this.ordenarOldPosto();
    }

    ordenarOldRegiao() {
        this.oldRegiao.sort(function (a: any, b: any) {
            if (a.descricao > b.descricao) {
                return 1;
            }
            if (a.descricao < b.descricao) {
                return -1;
            }
            return 0;
        })
        this.qtdOldRegiao = this.oldRegiao.length;
        this.qtdNewRegiao = this.newRegiao.length;
    }

    ordenarNewRegiao() {
        this.newRegiao.sort(function (a: any, b: any) {
            if (a.descricao > b.descricao) {
                return 1;
            }
            if (a.descricao < b.descricao) {
                return -1;
            }
            return 0;
        })
        this.qtdNewRegiao = this.newRegiao.length;
        this.qtdOldRegiao = this.oldRegiao.length;
    }

    addRegiaoExistenteUsuario(codigoRegiao: any) {
        this.oldRegiao.forEach((regiao: any, index: number) => {
            if (codigoRegiao == regiao.codigo) {
                this.newRegiao.push(regiao);
                this.oldRegiao.splice(index, 1);
            }
        });
        this.ordenarNewRegiao();
        this.ordenarOldRegiao();
    }

    removeRegiaoExistenteUsuario(codigoRegiao: any) {
        this.newRegiao.forEach((regiao: any, index: number) => {
            if (codigoRegiao == regiao.codigo) {
                this.oldRegiao.push(regiao);
                this.newRegiao.splice(index, 1);
            }
        });
        this.ordenarNewRegiao();
        this.ordenarOldRegiao();
    }

    validarFormulario(novoItemEditado: any): boolean {
        return !(novoItemEditado.id == null
            || novoItemEditado.nome == null
            || novoItemEditado.cpf == null
            || novoItemEditado.senha == null
            || novoItemEditado.meusDados == null
            || this.newPosto.length <= 0
            || this.newRegiao.length <= 0);
    }

}

import { VagasDisponiveisModel } from "../VagasDisponiveisModel";

export class historicoVagasDTO {
    nome: string;
    tipoTela: string;
    vaga: VagasDisponiveisModel | undefined;
    constructor(nome: string="", tipoTela: string ="", vaga: VagasDisponiveisModel | undefined) {
        this.nome = nome;
        this.tipoTela = tipoTela;
        this.vaga = vaga;
    }
}
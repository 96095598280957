<section>
  <section style="height: auto;">
    <nav>
        <ol class="cd-breadcrumb custom-separator">
            <table>
                <tbody>
                    <tr>
                        <td class="nomeTela">
                            Listagem de Região
                        </td>
                    </tr>
                </tbody>
            </table>
        </ol>
    </nav>
</section>
  <main>

    <!-- Alerta de sucesso -->
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
    </div>

    <!-- Alerta de erro -->
    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <!--Tabela -->
    @if(validarRole() == true ){
        <div style="position: fixed; bottom: 58px; right: 22px;">
            <a [routerLink]="['/regiao-cadastro']"><button id="class-button" style="border-radius: 50%;width: 45px;height: 45px;"
                class="btn btn-success"><i class="fas fa-plus"></i></button></a>
          </div>
    }
    <section class="main-section-table">
      <table class="table">
        <!--toJsonBody-->
        <thead>
          <tr>
            <th scope="col"  class="col"  style="width: 50%;" (click)="MudaOrdencacao()">
              @if(OrdemOrdenacao == "crescente")
                {
                  <i class="fas fa-chevron-circle-down"></i>
                }
                @else
                {
                  <i class="fas fa-chevron-circle-up"></i>
                }
              Descrição</th>
              @if(validarRole() == true ){
                <th scope="col"  class="col"  >Funções</th>
              }
          </tr>
        </thead>
        <tbody>
          @for (p of lista; track $index) {
          <tr>
            <td>

              {{p.descricao}}</td>
              @if(validarRole() == true ){
                <td>
                    <a class="btn btn-primary" style="margin-right: 10px;"
                    [routerLink]="['/regiao-cadastro']" [queryParams]="{IdEditar: p.id}">Editar</a>
                  <button class="btn btn-danger" (click)="excluirItem(p.id)">Excluir</button>
                </td>
              }

          </tr>
          }
        </tbody>
      </table>

      <mat-paginator #paginator
      class="demo-paginator"
      (page)="handlePageEvent($event)"
      [length]="totalPages"
      [pageSize]="pageSize"
      [disabled]="disabled"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [hidePageSize]="true"
      [pageIndex]="pageIndex"
      aria-label="Select page">
    </mat-paginator>
    </section>


  </main>
</section>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { servico } from '../modal/regional/servico';
import { MotorConfig } from '../modal/motorConfig';

@Injectable({
  providedIn: 'root',
})
export class MotorConfigService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.motor";
  headers = new HttpHeaders().set('Content-Type', 'application/json');


  adicionarItem(item: MotorConfig): Observable<MotorConfig> {
    return this.http.post<MotorConfig>(this.apiUrl+this.map+'/cadastro', item, {withCredentials: true});
  }

  getMotorPaginacao(pageNumber: number, pageSize: number,filtroNome: string,filtroStatus: string): Observable<any> {
    
    return this.http.get(this.apiUrl+this.map+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&filtroNome='+filtroNome+'&filtroStatus='+filtroStatus, {withCredentials: true});
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findbyId/${id}`);
  }


  deleteItem(id: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.map}/deletar/${id}`);
  }

 
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';
import { indicadorAlertaPermanencia } from '../modal/indicadorAlertaPermanencia';

@Injectable({
  providedIn: 'root',
})
export class indicadorAlertaPermanenciaService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.indicadoralertapermanencia";
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  get(): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
  }

  getAllSemAbertas(postos: number[] | undefined, DataInit: number | null, DataFim: number | null, filtroPostoNome: string): Observable<any> {
    var url = this.apiUrl+this.map+'/findAllSemAbertos?filtroPostoNome='+filtroPostoNome;
    if(DataInit != null)
      url = url + "&DataInit="+DataInit;
    if(DataFim != null)
      url = url + "&DataFim="+DataFim;
    return this.http.post(url, {params: postos}, {withCredentials: true});
  }

  getPaginacao(pageNumber: number, pageSize: number, order: string, CampoOrder: string, filtroPostoNome: string, filtroFilaDesc: string, DataInit: number | null, DataFim: number | null, filtroStatus: number | null,postos: number[] | undefined): Observable<any> {
    //return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});+'&DataInit='+DataInit+'&filtroStatus='+filtroStatus
    var url = this.apiUrl+this.map+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&order='+order+'&CampoOrder='+CampoOrder+'&filtroFilaDesc='+filtroFilaDesc+'&filtroPostoNome='+filtroPostoNome;
    if(DataInit != null)
      url = url + "&DataInit="+DataInit;
    if(DataFim != null)
      url = url + "&DataFim="+DataFim;
    if(filtroStatus != null)
      url = url + "&filtroStatus="+filtroStatus;

    return this.http.post(url, {params: postos}, {withCredentials: true});
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findbyId/${id}`);
  }
  salvarItem(item: indicadorAlertaPermanencia): Observable<indicadorAlertaPermanencia> {
    return this.http.post<indicadorAlertaPermanencia>(this.apiUrl+this.map+'/cadastro', item, {withCredentials: true});
  }
}

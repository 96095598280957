import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../service/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);
    if (authService.isTokenValid()) {
        let t = authService.getNewToken();
        localStorage.setItem('token', t);
    }

    const token = localStorage.getItem('token');
    const auth = req.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
          }
    })
    return next(auth);
};

<section>
  <section style="height: auto;">
    <nav>
        <ol class="cd-breadcrumb custom-separator">
            <table>
                <tbody>
                    <tr>
                        <td class="nomeTela">
                            Listagem de Indicadores
                        </td>
                    </tr>
                </tbody>
            </table>
        </ol>
    </nav>
</section>
  <main>
    <section class="pesq-section">
      
      <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
        
        <table><tbody>
          <tr>
            <td>
              <div class="form-div p-3">
                <label for="pesquisaCodigo" class="form-label">Codigo:</label>
                <input type="text" class="form-control" placeholder="Codigo" id="pesquisaCodigo" name="pesquisaCodigo" [(ngModel)]="pesquisaCodigo" required>
              </div>
            </td><td>
            <td>
        <div class="form-div p-3">
          <label for="pesquisaDesc" class="form-label">Nome:</label>
          <input type="text" class="form-control" placeholder="Descrição" id="pesquisaDesc" name="pesquisaDesc" [(ngModel)]="pesquisaDesc" required>
        </div>
      </td><td>
        <td>
          <div class="form-div p-3">
            <label for="pesquisaEtapa" class="form-label">Etapa:</label>
            <input type="number" class="form-control" placeholder="Etapa" id="pesquisaEtapa" name="pesquisaEtapa" [(ngModel)]="pesquisaEtapa" required>
          </div>
        </td>
        <td>
          <div class="form-div p-3">
            <label for="pesquisaGrafico" class="form-label">Tipo do Gráfico</label>
            <select class="form-select" [(ngModel)]="pesquisaGrafico" name="pesquisaGrafico">
              <option *ngFor="let tipoGI of optionsTipoGraficoIndicador" [value]="tipoGI.value">{{ tipoGI.label }}
              </option>
            </select>
          </div>
        </td>
        <td>
        <div class="div-button mb-3 p-3" style="margin-top: 47px;">
          <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
        </div></td>
        <td style="position: fixed; bottom: 13px; right: 22px;">
          <a [routerLink]="['/indicador-cadastro']"><button id="class-button" style="border-radius: 50%;width: 45px;height: 45px;"
              class="btn btn-success"><i class="fas fa-plus"></i></button></a>
        </td>
        <td style="padding-top:31px; position: absolute; right: 45px;">
          <a  [routerLink]="['/trocar-posicao-indicador']"><button id="class-button" class="btn btn-success">Trocar Posições Dashboard</button></a>
        </td>
      </tr>
      </tbody></table>
      </form>
    </section>
    <!--Tabela -->
    <section class="main-section-table">
      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
      <table class="table">
        <!--toJsonBody-->
        <thead>
          
          <tr>
            <th scope="col">#</th>
            <th scope="col">Codigo</th>
            <th scope="col">Etapa</th>
            <th scope="col">Nome</th>
            <th scope="col">Descrição</th>
            <th scope="col">Consulta</th>
            <th scope="col">Colecao</th>
            <th scope="col">Abreviatura</th>
            <th scope="col">Tipo Resultado</th>
            <th scope="col">Funções</th>
          </tr>
        </thead>
        <tbody>
          @for (p of listaInd; track $index) {
          <tr>
            <td style="font-weight: bold;">{{$index+1}}</td>
            <td style="font-weight: bold;">{{p.codigo}}</td>
            <td>{{p.etapa}}</td>
            <td>{{p.nome}}</td>
            <td>{{p.descricao}}</td>
            <td>{{p.consulta}}</td>
            <td>{{p.colecao}}</td>
            <td>{{p.abreviatura}}</td>
            <td>{{p.tipoResultado}}</td>
            <td><a class="btn btn-primary" style="margin-right: 10px;width: 94%;"
              [routerLink]="['/indicador-cadastro']" [queryParams]="{IdEditar: p.id}">Editar</a>
                <button *ngIf="p.inativo != true" class="btn btn-danger" (click)="InativarItem(p.id)">Inativar</button>
                <button *ngIf="p.inativo == true" style="width: 94%;" class="btn btn-success" (click)="AtivarItem(p.id)">Ativar</button></td>
          </tr>
          }
        </tbody>
      </table>

      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </section>


  </main>
</section>